import React from 'react';
import PropTypes from 'prop-types';
import { Card, Drawer } from 'antd';

import CategoryDelete from '../category-delete';
import TableColumns from '../category-table';
import CategoryForm from '../category-form';
import CategoryDetail from '../category-detail';

import DataTable from '../../../../components/DataTable';

const CategoryClasses = ({
  loading,
  data,
  onEditClick,
  onDeleteClick,
  handlerStatusChange,
  onRowClick,
  limit,
  total,
  page,
  handleTableChange,
  modalOpened,
  selectedRow,
  onCloseModal,
  formData,
  uiState,
  onSubmit,
  onInputChange,
  performDelete,
}) => {
  return (
    <div>
      <Card>
        <DataTable
          rowKey={record => record.id || 'id'}
          loading={loading}
          data={data}
          columns={TableColumns(
            onEditClick,
            onDeleteClick,
            handlerStatusChange
          )}
          onClickRow={(e, item) => onRowClick(item)}
          pagination={{
            limit,
            total,
            page,
            data,
            // onChange: onPageChange,
          }}
          handleTableChange={handleTableChange}
        />
      </Card>
      <Drawer
        closable={false}
        placement="right"
        width={450}
        visible={modalOpened === 1}
        onClose={onCloseModal}
      >
        {selectedRow ? (
          <CategoryDetail
            selectedRow={selectedRow}
            onCloseModal={onCloseModal}
            onEditClick={onEditClick}
          />
        ) : (
          formData && (
            <CategoryForm
              formData={formData}
              formErrors={uiState.formErrors}
              onSubmit={onSubmit}
              onInputChange={onInputChange}
              onDeleteClick={onDeleteClick}
              onCloseModal={onCloseModal}
              loading={loading}
            />
          )
        )}
      </Drawer>
      {selectedRow && (
        <CategoryDelete
          selectedRow={selectedRow}
          onCloseModal={onCloseModal}
          modalOpened={modalOpened}
          performDelete={performDelete}
        />
      )}
    </div>
  );
};

CategoryClasses.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  handlerStatusChange: PropTypes.func,
  onRowClick: PropTypes.func,
  limit: PropTypes.string,
  total: PropTypes.string,
  page: PropTypes.string,
  handleTableChange: PropTypes.func,
  modalOpened: PropTypes.bool,
  selectedRow: PropTypes.string,
  onCloseModal: PropTypes.bool,
  formData: PropTypes.array,
  uiState: PropTypes.object,
  onSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  performDelete: PropTypes.func,
};

export default React.memo(CategoryClasses);
