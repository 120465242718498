import { Card, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';

import DataTable from '../../../components/DataTable';
import { getJobsFeedback } from '../reducers';
import { capitalize } from '../../../utils/apisauce';

const statusLabels = {
  pending_schedule: {
    color: '#F1B13B',
    label: 'Unassigned',
  },
  scheduled: {
    color: '#34A6BD',
    label: 'In Scheduled',
  },
  in_progress: {
    color: '#6ABB98',
    label: 'In Progress',
  },
  completed: {
    color: '#828282',
    label: 'Completed',
  },
  cancelled: {
    color: '#DF5E67',
    label: 'Cancelled',
  },
  skipped: {
    color: '#DF5E67',
    label: 'Skipped',
  },
};

class AllJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageQuery: {
        page: 1,
        per: 20,
        query: null,
      },
    };
  }

  componentDidUpdate() {
    // const { jobsfeedback, queryString } = this.props;
    // const { pageQuery } = this.state;
    // if (pageQuery && prevState['pageQuery'] !== pageQuery) {
    //   if (queryString) {
    //     getJobs({ ...pageQuery, query: queryString });
    //   } else {
    //     getJobs(pageQuery);
    //   }
    // }
  }

  componentDidMount() {
    const { getJobsFeedback } = this.props;
    getJobsFeedback({ per: 20, page: 1 });
    // if (queryString) {
    //   getJobs({ ...pageQuery, query: queryString });
    // } else {
    //   getJobs(pageQuery);
    // }
  }

  onClickRow = (e, { id }) => {
    const { history, jobDetailsModalOpen } = this.props;
    history.push('#' + id);
    jobDetailsModalOpen();
  };

  closeModal = () => {
    const { history } = this.props;
    history.goBack();
  };

  tableColumns = () => [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      fixed: 'left',
      width: 70,
      // defaultSortOrder: 'ascend',
      // sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Service Date',
      key: 'service_date',
      dataIndex: 'service_date',
      defaultSortOrder: 'descend',
      fixed: 'left',
      width: 120,
      sorter: (a, b) => {
        const dateA = new Date(a.service_date).getTime();
        const dateB = new Date(b.service_date).getTime();
        return dateA - dateB;
      },
      render: (_, record) =>
        record.service_date
          ? moment(record.service_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '-',
    },
    {
      title: 'Title',
      key: 'title',
      width: 180,
      render: (_, record) => capitalize(record.title),
    },
    {
      title: 'Client Name',
      key: 'client_name',
      width: 180,
      render: (_, record) =>
        record.client_type === 'PersonalClient'
          ? capitalize(record.client.title)
          : capitalize(record.client.business_name),
    },
    {
      title: 'Client Address',
      key: 'client_address',
      width: 200,
      render: (_, record) =>
        record.address
          ? `${record.address.address_title}, ${record.address.address}, ${record.address.unit_number}, ${record.address.postal_code}`
          : '-',
    },
    {
      title: 'Vendor Name',
      key: 'vendor_id',
      width: 120,
      render: (_, record) =>
        record?.vendor?.vendor_name
          ? capitalize(record.vendor.vendor_name)
          : '-',
    },
    {
      title: 'Assigned Worker',
      key: 'worker',
      width: 150,
      render: (_, record) =>
        record['assigned_worker']?.first_name
          ? `${capitalize(record['assigned_worker']?.first_name)} ${capitalize(
              record['assigned_worker']?.last_name
            )}`
          : '-',
    },
    {
      title: 'Preferred Time',
      key: 'preferred_time',
      width: 120,
      render: (_, record) =>
        record['preferred_time'] ? record['preferred_time'] : '-',
    },
    {
      title: 'Assigned Time',
      key: 'assigned_time',
      width: 120,
      render: (_, record) =>
        record['assigned_time'] ? record['assigned_time'] : '-',
    },
    {
      title: 'Service Duration',
      key: 'job_duration_in_minutes',
      width: 120,
      render: (_, record) =>
        record['job_duration_in_minutes']
          ? record['job_duration_in_minutes'] + ' min'
          : '-',
    },
    {
      title: 'Check In',
      key: 'checkin_time',
      width: 120,
      render: (_, record) => (record.checkin_time ? record.checkin_time : '-'),
    },
    {
      title: 'Check Out',
      key: 'checkout_time',
      width: 120,
      render: (_, record) =>
        record.checkout_time ? record.checkout_time : '-',
    },
    {
      title: 'Active Job Duration',
      width: 160,
      render: (_, record) => {
        if (record?.checkin_time && record?.checkout_time) {
          return record['total_job_time'];
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Job Notes',
      key: 'job_notes',

      render: (_, record) =>
        record['job_notes']
          ? `${
              record['job_notes']['length'] > 25
                ? record['job_notes'].slice(0, 25) + '...'
                : record['job_notes']
            }`
          : '-',
    },
    {
      title: 'Customer Notes',
      key: 'customer_notes',
      width: 120,
      render: (_, record) =>
        record['customer_notes']
          ? `${
              record['customer_notes']['length'] > 25
                ? record['customer_notes'].slice(0, 25) + '...'
                : record['customer_notes']
            }`
          : '-',
    },
    {
      title: 'Rating',
      key: 'rating',
      width: 120,
      dataIndex: 'rating',
      align: 'center',
      render: (_, record) =>
        record['rating'] !== null ? (
          <div style={{ textAlign: 'center' }}>
            {this.generateRateStar(record['rating'])}
          </div>
        ) : (
          ''
        ),
    },
    {
      title: 'Rating Feedback',
      key: 'rating_feedback',
      width: 120,
      render: (_, record) =>
        record['rating_feedback'] !== null ? (
          <Tooltip title={`${record.rating_feedback}`} placement="top">
            {capitalize(`${record.rating_feedback}`).substring(0, 20)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
              ...Read more
            </span>
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      title: 'Status',
      key: 'job_status',
      width: 120,
      align: 'center',
      render: (_, record) => (
        <span style={{ color: statusLabels[record.job_status].color }}>
          {statusLabels[record.job_status].label}
        </span>
      ),
    },
    {
      title: 'Repeating',
      key: 'job_repeating',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (_, record) => (record['repeating_job_id'] ? 'Yes' : 'No'),
    },
  ];

  generateRateStar = star => {
    let stars = new Array(star).fill('');
    return stars.map((val, index) => {
      return (
        <StarFilled style={{ fontSize: '12px', color: 'gold' }} key={index} />
      );
    });
  };

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { pageQuery } = this.state;
    const { order } = sorter;

    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    updatedPageQuery['order'] = order;
    this.setState({ pageQuery: updatedPageQuery });
  };

  render() {
    const { loading, jobsfeedback } = this.props;
    const { limit, total, page, data } = jobsfeedback;
    return (
      <Card
        title="Jobs Feedback"
        className="overflow-x-auto"
        style={{ marginTop: 15 }}
      >
        <DataTable
          loading={loading}
          pagination={{
            limit,
            total,
            page,
            data,
            onChange: this.onPageChange,
          }}
          columns={this.tableColumns()}
          onClickRow={this.onClickRow}
          handleTableChange={this.handleTableChange}
        />
      </Card>
    );
  }
}

AllJobs.propTypes = {
  loading: PropTypes.bool,
  jobsfeedback: PropTypes.object,
  getJobsFeedback: PropTypes.func,
  history: PropTypes.object,
  queryString: PropTypes.string,
  jobDetailsModalOpen: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.jobsFeedback.loading,
    jobsfeedback: state.jobsFeedback.jobsfeedback,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJobsFeedback: payload => dispatch(getJobsFeedback(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(AllJobs)));
