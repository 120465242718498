import React from 'react';
import { Card, Modal } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableSearch from '../../../components/TableSearch';
import { withRouter } from 'react-router-dom';

import DataTable from '../../../components/DataTable';
import { getConcierge } from '../reducers';
import {
  orderTableColumns,
  conciergeorderItemsTableColumns,
} from '../util/tableColumn';

class ConciergeHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageQuery: {
        page: 1,
        per: 20,
        query: null,
      },
      isModalOpen: false,
      orderItemsModal: {
        status: true,
        formattedAmount: '',
        records: [],
        orderId: '',
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { getConcierge } = this.props;
    const { pageQuery } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      // if (queryString) {
      getConcierge({ ...pageQuery });
      // } else {
      // getJobs(pageQuery);
    }
  }

  componentDidMount() {
    // const { getConcierge, queryString } = this.props;
    const { getConcierge } = this.props;
    const { pageQuery } = this.state;
    // if (queryString) {
    getConcierge({ ...pageQuery });
    // } else {
    //   getJobs(pageQuery);
    // }
  }

  //   onClickRow = (e, { id }) => {
  //     const { history, jobDetailsModalOpen } = this.props;
  //     history.push('#' + id);
  //     jobDetailsModalOpen();
  //   };

  //   closeModal = () => {
  //     const { history } = this.props;
  //     history.goBack();
  //   };

  //   onPageChange = newPage => {
  //     const { pageQuery } = this.state;
  //     let updatedPageQuery = { ...pageQuery };
  //     updatedPageQuery['page'] = newPage;
  //     this.setState({ pageQuery: updatedPageQuery });
  //   };

  handleTableChange = (pagination, filters, sorter) => {
    const { pageQuery } = this.state;
    const { order } = sorter;

    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    updatedPageQuery['order'] = order;
    this.setState({ pageQuery: updatedPageQuery });
  };

  showOrderItemsModal = data => {
    this.setState({
      isModalOpen: true,
      orderItemsModal: {
        status: true,
        formattedAmount: data['formatted_amount'],
        records: data['concierge_order_items'],
        orderId: data['id'],
      },
    });
  };

  render() {
    const { loading, concierge } = this.props;
    const { limit, total, page, data } = concierge;
    return (
      <>
        <TableSearch
          placeholder="Search Client by name or ID"
          btnText="Search"
          // onBtnClick={this.onSearch}
          // value={queryString}
          // onInputChange={ev =>
          //   this.setState({ queryString: ev['target']['value'] })
          // }
        />

        <Card
          title="Concierge Order History"
          className="overflow-x-auto"
          style={{ marginTop: 15 }}
        >
          <DataTable
            loading={loading}
            pagination={{
              limit,
              total,
              page,
              data,
              onChange: this.onPageChange,
            }}
            data={data}
            columns={orderTableColumns(this.showOrderItemsModal)}
            //   onClickRow={this.onClickRow}
            handleTableChange={this.handleTableChange}
          />
        </Card>

        <Modal
          title="Order Details"
          visible={this.state.isModalOpen}
          onOk={() =>
            this.setState(state => ({ ...state, isModalOpen: false }))
          }
          onCancel={() =>
            this.setState(state => ({ ...state, isModalOpen: false }))
          }
          width="80%"
        >
          <DataTable
            rowKey={record => record['id'] || 'id'}
            loading={loading}
            data={this?.state?.orderItemsModal['records']}
            columns={conciergeorderItemsTableColumns()}
            // onClickRow={() => {}}
            // pagination={pagination}
            // footer={footer}
          />
        </Modal>
      </>
    );
  }
}

ConciergeHistory.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  concierge: PropTypes.object,
  getConcierge: PropTypes.func,
  // queryString: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    loading: state.concierge.loading,
    error: state.concierge.error,
    concierge: state.concierge.concierge,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getConcierge: payload => dispatch(getConcierge({ ...payload })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(ConciergeHistory)));
