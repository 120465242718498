import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Card, Row, Col, Button, DatePicker } from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  getJobFeedCount,
  getJobStatisticsCount,
  getWorkStatus,
  getJobFeed,
  getWorkerLocation,
  getJobs,
  getActiveClientsCount,
} from './reducers';

const StatsCard = lazy(() => import('./components/StatsCard'));
const JobFeed = lazy(() => import('./components/JobFeed'));
const MapContainer = lazy(() => import('./components/MapContainer'));
import tableColumns from './columns';
const DataTable = lazy(() => import('../../components/DataTable'));
const BriefcaseIcon = lazy(() =>
  import('../../assets/icons/ic_card_travel.svg')
);
const CityIcon = lazy(() => import('../../assets/icons/ic_domain.svg'));
const CalendarIcon = lazy(() =>
  import('../../assets/icons/ic_canceled_calendar.svg')
);
const JobDetailModal = lazy(() =>
  import('../JobPage/components/jobDetailModal')
);

import './style.css';

const CURRENT_TIME_FORMAT = 'dddd MMMM D, YYYY HH:mm A';

function Dashboard(props) {
  const {
    // func
    getJobStatisticsCount,
    getWorkStatus,
    workStatus,
    getWorkStatusLoading,
    getJobFeedCount,
    getJobFeed,
    getWorkerLocation,
    getJobs,
    getActiveClientsCount,
    getActiveClientsCountLoading,
    clientStatisticsCount,
    // state

    // getjobFeedCountLoading,
    // jobFeedCount,
    getJobStatisticsCountLoading,
    jobStatisticsCount,
    // getJobFeedLoading,
    jobFeeds,
    // getWorkerLocationLoading,
    // workerLocation,
    // getJobsLoading,
    // jobs,
  } = props;

  const [time, setTime] = useState(moment());
  const [currentDate, setCurrentDate] = useState(moment());
  const [pickerOpened, setPickerOpened] = useState(false);
  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    page: 1,
  });

  const handleDateChange = date => {
    if (date === 'prev') {
      setCurrentDate(moment(currentDate.format()).subtract(1, 'days'));
    } else if (date === 'next') {
      setCurrentDate(moment(currentDate.format()).add(1, 'days'));
    } else {
      setCurrentDate(moment(date));
    }
  };

  useEffect(() => {
    getJobStatisticsCount(currentDate.format('YYYY-MM-DD'));
    getWorkStatus(currentDate.format('YYYY-MM-DD'));
    getJobFeedCount(currentDate.format('YYYY-MM-DD'));
    getJobFeed(currentDate.format('YYYY-MM-DD'));
    getWorkerLocation(currentDate.format('YYYY-MM-DD'));
    getActiveClientsCount();
    // getJobs(currentDate.format('YYYY-MM-DD'));
  }, [
    currentDate,
    getJobStatisticsCount,
    getWorkStatus,
    getJobFeedCount,
    getJobFeed,
    getWorkerLocation,
    getActiveClientsCount,
    getJobs,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = moment();
      if (newTime.minutes() !== time.minutes()) {
        setTime(newTime);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const handleTableChange = pagination => {
    setTablePagination({
      limit: pagination['pageSize'],
      page: pagination['current'],
    });
  };

  return (
    <div className="dashboard-container">
      {/* <div className="header-container">
        <p className="header-title font-bold">Dashboard</p>
      </div> */}
      <Row className="stats_job_feed_container">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={16}
          className="statistics-container"
        >
          <div className="stats-cards-container">
            <p className="header-timestamp font-bold">
              <p className="dashboard-time">
                {time.format(CURRENT_TIME_FORMAT)}
              </p>
              <Row className="dashboard-datepicker">
                <Col flex={1}>
                  <Button
                    type="link"
                    icon={<LeftOutlined />}
                    onClick={() => handleDateChange('prev')}
                  />
                </Col>
                <Col flex={2} onClick={() => setPickerOpened(true)}>
                  <DatePicker
                    bordered={false}
                    allowClear={false}
                    suffixIcon={null}
                    showToday={true}
                    format="YYYY-MM-DD"
                    open={pickerOpened}
                    onOpenChange={status => setPickerOpened(status)}
                    value={currentDate}
                    className="date-picker-dashboard"
                    onChange={handleDateChange}
                  />
                </Col>
                <Col flex={1}>
                  <Button
                    type="link"
                    icon={<RightOutlined />}
                    onClick={() => handleDateChange('next')}
                  />
                </Col>
              </Row>
            </p>
            <div
              className="stats-card-container"
              style={{ display: 'flex', flex: 1, width: '100%' }}
            >
              <Suspense
                fallback={<LoadingOutlined className="fallback-loader" />}
              >
                <StatsCard
                  num={clientStatisticsCount?.activity_since_yesterday_count}
                  title={'Active Mobile Clients Yesterday'}
                  icon={CalendarIcon}
                  loading={getActiveClientsCountLoading}
                />
              </Suspense>
              <Suspense
                fallback={<LoadingOutlined className="fallback-loader" />}
              >
                <StatsCard
                  num={clientStatisticsCount?.activity_since_seven_days_count}
                  title={'Active Mobile Clients Past 7 Days '}
                  icon={CalendarIcon}
                  loading={getActiveClientsCountLoading}
                />
              </Suspense>
              <Suspense
                fallback={<LoadingOutlined className="fallback-loader" />}
              >
                <StatsCard
                  num={clientStatisticsCount?.total_active_users_count}
                  title={'Total Mobile Clients'}
                  icon={CalendarIcon}
                  loading={getActiveClientsCountLoading}
                />
              </Suspense>
            </div>
            <div
              className="stats-card-container"
              style={{
                display: 'flex',
                flex: 1,
                width: '100%',
                marginTop: '2%',
              }}
            >
              <Suspense
                fallback={<LoadingOutlined className="fallback-loader" />}
              >
                <StatsCard
                  num={jobStatisticsCount['unassigned_jobs_count']}
                  title={'Unassigned Jobs'}
                  icon={BriefcaseIcon}
                  loading={getJobStatisticsCountLoading}
                />
              </Suspense>
              <Suspense
                fallback={<LoadingOutlined className="fallback-loader" />}
              >
                <StatsCard
                  num={jobStatisticsCount['assigned_jobs_count']}
                  title={'Assigned Jobs'}
                  icon={CityIcon}
                  loading={getJobStatisticsCountLoading}
                />
              </Suspense>
              <Suspense
                fallback={<LoadingOutlined className="fallback-loader" />}
              >
                <StatsCard
                  num={jobStatisticsCount['completed_jobs_count']}
                  title={'Completed Jobs'}
                  icon={CalendarIcon}
                  loading={getJobStatisticsCountLoading}
                />
              </Suspense>
              <Suspense
                fallback={<LoadingOutlined className="fallback-loader" />}
              >
                <StatsCard
                  num={jobStatisticsCount['cancelled_and_skipped_jobs_count']}
                  title={'Canceled / Skipped Job'}
                  icon={CalendarIcon}
                  loading={getJobStatisticsCountLoading}
                />
              </Suspense>
            </div>
          </div>
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <MapContainer />
          </Suspense>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={8}
          className="job-feed-container"
        >
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <JobFeed jobFeeds={jobFeeds} />
          </Suspense>
        </Col>
      </Row>
      <Card
        title={<span className="work-status-table-title">Work Status</span>}
        className="main-table"
      >
        {/* work status */}
        <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
          <DataTable
            rowKey={record =>
              record['worker_image'] ? record['worker_image']['id'] : ''
            }
            loading={getWorkStatusLoading}
            data={workStatus}
            columns={tableColumns()}
            pagination={{
              limit: tablePagination['limit'],
              page: tablePagination['page'],
              total: workStatus.length,
              data: workStatus,
            }}
            handleTableChange={handleTableChange}
          />
        </Suspense>
      </Card>
      <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
        <JobDetailModal />
      </Suspense>
    </div>
  );
}

Dashboard.propTypes = {
  getJobStatisticsCount: PropTypes.func,
  getJobStatisticsCountLoading: PropTypes.bool,
  jobStatisticsCount: PropTypes.object,
  getWorkStatus: PropTypes.func,
  workStatus: PropTypes.array,
  getWorkStatusLoading: PropTypes.bool,
  getJobFeedCount: PropTypes.func,
  getJobFeed: PropTypes.func,
  getWorkerLocation: PropTypes.func,
  getJobs: PropTypes.func,
  getjobFeedCountLoading: PropTypes.bool,
  jobFeedCount: PropTypes.object,
  getJobFeedLoading: PropTypes.bool,
  jobFeeds: PropTypes.array,
  getWorkerLocationLoading: PropTypes.bool,
  workerLocation: PropTypes.array,
  getJobsLoading: PropTypes.bool,
  jobs: PropTypes.array,
  getActiveClientsCount: PropTypes.func,
  clientStatisticsCount: PropTypes.object,
  getActiveClientsCountLoading: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    getjobFeedCountLoading: state.dashboard.getjobFeedCountLoading,
    getActiveClientsCountLoading: state.dashboard.getActiveClientsCountLoading,
    jobFeedCount: state.dashboard.jobFeedCount,
    getJobStatisticsCountLoading: state.dashboard.getJobStatisticsCountLoading,
    jobStatisticsCount: state.dashboard.jobStatisticsCount,
    getWorkStatusLoading: state.dashboard.getWorkStatusLoading,
    workStatus: state.dashboard.workStatus,
    getJobFeedLoading: state.dashboard.getJobFeedLoading,
    jobFeeds: state.dashboard.jobFeeds,
    getWorkerLocationLoading: state.dashboard.getWorkerLocationLoading,
    workerLocation: state.dashboard.workerLocation,
    getJobsLoading: state.dashboard.getJobsLoading,
    jobs: state.dashboard.jobs,
    clientStatisticsCount: state.dashboard.clientStatisticsCount,
    // getJobFeedCount: state.dashboard.getJobFeedCount,
    // getWorkStatus: state.dashboard.getJobStatisticsCountLoading,
    // getJobFeed: state.dashboard.getJobFeed,
    // getJobs: state.dashboard.getJobs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getActiveClientsCount: () => dispatch(getActiveClientsCount()),
    getJobFeedCount: payload => dispatch(getJobFeedCount(payload)),
    getJobStatisticsCount: payload => dispatch(getJobStatisticsCount(payload)),
    getWorkStatus: payload => dispatch(getWorkStatus(payload)),
    getJobFeed: payload => dispatch(getJobFeed(payload)),
    getWorkerLocation: payload => dispatch(getWorkerLocation(payload)),
    getJobs: payload => dispatch(getJobs(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
