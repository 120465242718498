import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  useHistory,
  Redirect,
} from 'react-router-dom';

import { checkImpVendorToken } from './utils/apisauce';
import { connect } from 'react-redux';
import { Layout, Typography, Avatar, Dropdown, Menu } from 'antd';
import PropTypes from 'prop-types';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DownOutlined,
  LogoutOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import ProLayout, {
  PageHeaderWrapper,
  RouteContext,
} from '@ant-design/pro-layout';
import styled from 'styled-components';
import { dig } from 'digdata';
import moment from 'moment';
import QRCode from 'qrcode.react';

import Routes from './routes';
import {
  getImageURI,
  getWorkerApk,
  getClientApk,
  logout,
} from './utils/apisauce';
import { getProfile } from './pages/AuthPage/reducers';
import { showTabForCurrentUser } from './utils/helpers';
import 'antd/dist/antd.css';
import './assets/css/override.css';
import 'sendbird-uikit/dist/index.css';
import './app.css';
// import { useEffect } from 'react/cjs/react.development';

const MenuCloseIcon = styled(MenuFoldOutlined)`
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 10px;

  :hover {
    color: #1890ff;
  }
`;

const MenuOpenIcon = styled(MenuUnfoldOutlined)`
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 10px;

  :hover {
    color: #1890ff;
  }
`;

const LogoImage = styled.svg`
  margin-right: 5px;
`;

const { Footer } = Layout;
const { Title } = Typography;

const Header = props => {
  const {
    propsData,
    handleCollpase,
    data,
    renderClientQrCode,
    renderWorkerQrCode,
    renderSideMenu,
    userProfile,
    getProfile,
  } = props;

  React.useEffect(() => {
    getProfile();
  }, [getProfile]);

  let userData = {};

  if (userProfile && userProfile['admin_user']) {
    userData = userProfile['admin_user'];
  }

  const history = useHistory();
  const { location } = history;

  return (
    <div
      className="main-header-container"
      style={{ display: 'flex', alignItems: 'baseline' }}
    >
      <div
        className="header-drawer-icon"
        onClick={() => handleCollpase(!data.isCollapsed)}
      >
        {propsData.collapsedButtonRender(data.isCollapsed)}
      </div>
      <h3 className="route-name">
        {location.pathname === '/'
          ? 'Dashboard'
          : location.pathname.split('/')[1].replace(/-/g, ' ')}
      </h3>
      <div className="antd-header-dropdown main-header">
        <Dropdown overlay={renderClientQrCode}>
          <div className="qr-one-container">
            <QrcodeOutlined
              className="qr-one qr"
              style={{
                fontSize: 25,
                margin: 'auto',
                marginRight: 8,
                marginTop: 20,
              }}
            />
            <p className="qr-text" style={{ marginRight: 30 }}>
              Client App
            </p>
          </div>
        </Dropdown>
        <Dropdown overlay={renderWorkerQrCode}>
          <div className="qr-one-container">
            <QrcodeOutlined
              className="qr-one qr"
              style={{
                fontSize: 25,
                margin: 'auto',
                marginRight: 8,
                marginTop: 20,
              }}
            />
            <p className="qr-text" style={{ marginRight: 50 }}>
              Worker App
            </p>
          </div>
        </Dropdown>
        <Dropdown overlay={renderSideMenu(userProfile)} trigger={['click']}>
          <div
            className="qr-one-container logout"
            onClick={e => e.preventDefault()}
          >
            <Avatar
              style={{
                fontSize: 25,
                margin: 'auto',
                marginRight: 8,
                marginTop: 15,
              }}
              src={getImageURI(dig(userData, 'picture_path.url'))}
              icon={<UserOutlined />}
              className="qr-one"
            />
            {userData && (
              <p className="qr-text">
                {userData.email} <DownOutlined />
              </p>
            )}
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

Header.propTypes = {
  propsData: PropTypes.object,
  handleCollpase: PropTypes.func,
  data: PropTypes.object,
  userProfile: PropTypes.object,
  renderClientQrCode: PropTypes.func,
  renderWorkerQrCode: PropTypes.func,
  collapsedButtonRender: PropTypes.func,
  renderSideMenu: PropTypes.func,
};

class Application extends React.PureComponent {
  state = {
    workerApp: null,
    clientApp: null,
    navTheme: 'light',
    theme: 'light',
    primaryColor: '#1890ff',
    layout: 'sidemenu',
    contentWidth: 'Fluid',
    fixSiderbar: false,
    fixedHeader: false,
    colorWeak: true,
    isCollapsed: false,
  };

  componentDidMount() {
    this.fetchWorkerApk();
    this.fetchClientApk();
  }

  fetchWorkerApk = async () => {
    const workerApp = await getWorkerApk();
    this.setState({ workerApp });
  };

  fetchClientApk = async () => {
    const clientApp = await getClientApk();
    this.setState({ clientApp });
  };

  renderMenuHeader = (/* logo, title, props */) => {
    return (
      <RouteContext.Consumer>
        {value => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 64,
            }}
          >
            <LogoImage
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
            >
              <path
                d="M20.407 6.29862L18.4602 4.9116L18.3065 4.80154L18.0769 4.63836L16.4622 3.48472L13.0316 1.03512L12.8419 0.894712C12.7824 0.850439 12.7236 0.808695 12.6654 0.769481C12.0641 0.369861 11.3471 0.181176 10.627 0.233031C9.90688 0.284886 9.22434 0.574346 8.68648 1.05599V1.03323L5.27109 3.48472L3.82334 4.52262L3.77401 4.55867L3.2655 4.92108L1.46482 6.20565C1.0194 6.50336 0.654368 6.90645 0.402142 7.37912C0.149917 7.8518 0.0182995 8.37944 0.0189744 8.9152L0 16.5163C0.0010057 17.3797 0.344692 18.2074 0.955559 18.8176C1.56643 19.4277 2.39451 19.7705 3.25791 19.7705H5.43048C6.29271 19.7705 7.11974 19.4285 7.73014 18.8195C8.34054 18.2105 8.68447 17.3843 8.68648 16.522H3.25791V8.9152L5.91432 7.01776L8.20074 5.38216L8.68079 5.03872L10.8439 3.49231H10.8628L13.0259 5.03872L13.506 5.38216L15.8114 7.01965L18.4678 8.91709V16.5163H13.0316C13.0319 16.9439 13.1163 17.3673 13.2802 17.7622C13.4441 18.1572 13.6841 18.516 13.9866 18.8181C14.2892 19.1203 14.6482 19.3599 15.0434 19.5233C15.4385 19.6867 15.8619 19.7707 16.2895 19.7705H18.4602C19.3243 19.7705 20.1529 19.4272 20.7639 18.8162C21.3749 18.2053 21.7181 17.3766 21.7181 16.5125V8.9152C21.7195 8.40689 21.6016 7.90534 21.3739 7.45089C21.1462 6.99644 20.815 6.60177 20.407 6.29862Z"
                fill="#2B2B2B"
              />
            </LogoImage>

            {!value.collapsed && (
              <Title style={{ margin: 0 }} level={4}>
                Butler Admin
              </Title>
            )}
          </div>
        )}
      </RouteContext.Consumer>
    );
  };

  renderMenuItem = (route, defaultDom) => {
    return <Link to={route.path}>{defaultDom}</Link>;
  };

  renderFooter = props => {
    if (props.location.pathname === '/scheduler') {
      return null;
    }
    return (
      <Footer className={props.classname} style={{ textAlign: 'center' }}>
        Copyright Butler © 2020
      </Footer>
    );
  };

  renderClientQrCode = () => {
    const apk = dig(this.state, 'clientApp.apk');
    const version = dig(this.state, 'clientApp.version');
    return (
      <Menu style={{ padding: 10, textAlign: 'center' }}>
        {apk === null && version === null ? (
          <Menu.Item>Not Available</Menu.Item>
        ) : (
          <Fragment>
            <Menu.Item>
              <p>Version: {moment(version).format('YYYYMMDD')}</p>
            </Menu.Item>
            <Menu.Item onClick={() => window.open(apk)}>
              <QRCode value={apk} />
            </Menu.Item>
          </Fragment>
        )}
      </Menu>
    );
  };

  renderWorkerQrCode = () => {
    const apk = dig(this.state, 'workerApp.apk');
    const version = dig(this.state, 'workerApp.version');
    return (
      <Menu style={{ padding: 10, textAlign: 'center' }}>
        {apk === null && version === null ? (
          <Menu.Item>Not Available</Menu.Item>
        ) : (
          <Fragment>
            <Menu.Item>
              <p>Version : {moment(version).format('YYYYMMDD')}</p>
            </Menu.Item>
            <Menu.Item onClick={() => window.open(apk)}>
              <QRCode value={apk} />
            </Menu.Item>
          </Fragment>
        )}
      </Menu>
    );
  };

  renderSideMenu = userProfile => {
    return (
      <Menu>
        {userProfile &&
          userProfile['admin_user'] &&
          (userProfile.admin_user?.first_name ||
            userProfile.admin_user?.last_name) && (
            <Menu.Item key="2">
              <UserOutlined />{' '}
              {userProfile.admin_user?.first_name
                ? userProfile.admin_user.first_name
                : ''}{' '}
              {userProfile.admin_user?.last_name
                ? userProfile.admin_user.last_name
                : ''}
            </Menu.Item>
          )}
        <Menu.Item onClick={logout} key="1">
          <LogoutOutlined /> {' Logout.'}
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    const impVendorToken = checkImpVendorToken();
    if (impVendorToken) {
      return <Redirect to="/vendor" />;
    }

    if (!this.props.authenticated) {
      return <Redirect to="/admin/login" />;
    }

    const menus = [];
    const routes = [];
    for (const route of Routes) {
      if (route.menu) {
        const { Icon, iconProps } = route.menu;
        const showTab = showTabForCurrentUser(
          route.visibilityDependency,
          route.name
        );
        if (showTab) {
          menus.push({
            exact: true,
            path: route.path,
            name: route.menu.name,
            icon: <Icon {...iconProps} />,
            routes: route?.children,
          });
        }
      }

      const Component = route.component;
      const component = route.disableWrapper
        ? Component
        : props => {
            if (!Component) {
              return null;
            }
            return (
              <PageHeaderWrapper style={{ marginLeft: 24, marginRight: 24 }}>
                <Component {...props} />
              </PageHeaderWrapper>
            );
          };
      if (route.isStandalone) {
        for (const path of route.path) {
          const showTab = showTabForCurrentUser(
            route.visibilityDependency,
            route.name
          );
          if (showTab) {
            routes.push(
              <Route
                exact={true}
                key={path}
                path={path}
                component={component}
              />
            );
          }
        }
      } else {
        const showTab = showTabForCurrentUser(
          route.visibilityDependency,
          route.name
        );
        if (showTab) {
          routes.push(
            <Route
              exact={true}
              key={route.path}
              path={route.path}
              component={component}
            />
          );
        }
      }
    }

    // const { userProfile, authenticated } = this.props;
    routes.push(<Redirect to={routes[0]['key']} />);
    return (
      <Router>
        <Switch>
          <ProLayout
            {...this.state}
            title="Butler Admin"
            route={{ routes: menus }}
            // menu={{
            //   request: async () => menus,
            // }}
            headerRender={props => (
              <Header
                propsData={props}
                handleCollpase={status =>
                  this.setState({ isCollapsed: status })
                }
                data={this.state}
                userProfile={this.props.profile}
                getProfile={this.props.getProfile}
                renderClientQrCode={this.renderClientQrCode}
                renderWorkerQrCode={this.renderWorkerQrCode}
                renderSideMenu={this.renderSideMenu}
              />
            )}
            footerRender={this.renderFooter}
            menuItemRender={this.renderMenuItem}
            menuHeaderRender={this.renderMenuHeader}
            collapsed={this.state.isCollapsed}
            // onPageChange={(e) => console.log(e,'test')}
            onCollapse={isCollapsed => this.setState({ isCollapsed })}
            collapsedButtonRender={collapsed =>
              collapsed ? <MenuOpenIcon /> : <MenuCloseIcon />
            }
          >
            <Switch>{routes}</Switch>
            {/* <SettingDrawer collapse={true} settings={this.state} onSettingChange={this.setState} /> */}
          </ProLayout>
        </Switch>
      </Router>
    );
  }
}

Application.propTypes = {
  authenticated: PropTypes.bool,
  profile: PropTypes.object,
  getProfile: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    profile: state.auth.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProfile: () => dispatch(getProfile()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Application));
