import React, { useState, useEffect, lazy, Suspense } from 'react';
import moment from 'moment';
import {
  Modal,
  Card,
  Drawer,
  Button,
  Avatar,
  Row,
  Col,
  DatePicker,
} from 'antd';
import {
  PlusOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
const OrderTable = lazy(() => import('../../components/OrderTable'));
const OrderItemsTable = lazy(() =>
  import('../../components/OrderTable/OrderItemsTable')
);
const JobDetailTable = lazy(() =>
  import('../../components/OrderTable/JobDetailTable')
);
const OrderTransactionsTable = lazy(() =>
  import('../../components/OrderTable/OrderTransactionsTable.js')
);
const TransactionTable = lazy(() =>
  import('../../components/TransactionTable')
);
import TableSearch from '../../components/TableSearch';
const CreateOrder = lazy(() => import('./components/create-order'));
const EditableTagGroup = lazy(() =>
  import('../../components/OrderTable/EditableTagGroup')
);
import {
  getJob,
  getOrders,
  createOrder,
  getAccounts,
  orderShipped,
  orderDelivered,
  cancelOrderRequest,
} from './reducers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.css';

const defaultOrder = {
  title: '',
  description: '',
  adjustment_amount: 1,
  adjustment_type: '',
  account_id: '',
};

const OrderTableHeading = ({ title, orderId, itemCount, subStart }) => {
  return (
    <div className="order-item-table-heading-container">
      {title && <h2 className="order-item-table-heading">{title}</h2>}
      {orderId !== undefined && itemCount !== undefined && (
        <p className="order-item-table-text">{`Order ID: ${orderId} - ${itemCount} items`}</p>
      )}
      {subStart !== undefined && (
        <p className="order-item-table-text">{`Subscription start date : ${subStart}`}</p>
      )}
    </div>
  );
};

OrderTableHeading.propTypes = {
  orderId: PropTypes.string,
  itemCount: PropTypes.number,
  title: PropTypes.string,
  subStart: PropTypes.string,
};

const OrderItemTableFooter = ({ formattedAmount }) => {
  return (
    <div className="order-item-table-footer-container">
      <h2 className="order-item-table-footer-text oi-table-footer-heading">
        Total Amount
      </h2>
      <h2 className="order-item-table-footer-text oi-table-footer-text">
        {formattedAmount}
      </h2>
    </div>
  );
};

OrderItemTableFooter.propTypes = {
  formattedAmount: PropTypes.string,
};

function Order(props) {
  const {
    orders,
    getOrders,
    loading,
    orderShipped,
    orderDelivered,
    cancelOrderRequest,
    getJob,
    jobs,
  } = props;
  const [modalState, setModalState] = useState(0);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchText, updateSearchText] = useState('');
  const [ordersList, updateOrdersList] = useState({ meta: null, orders: [] });
  const [activeTab, setActiveTab] = useState('all');
  const [orderItemsModal, updateOrderItemsModal] = useState({
    status: false,
    records: [],
    orderId: '',
    formattedAmount: '',
  });
  const [jobDetailModal, updateJobDetailModal] = useState({
    status: false,
    records: jobs,
    orderId: '',
  });
  const [orderTransactionsModal, updateOrderTransactionsModal] = useState({
    status: false,
    records: [],
    formattedAmount: '',
    subStart: '',
  });

  const [isTrModalOpen, setIsTrModalOpen] = useState(false);
  const [trackingNumebrId, setTrackingNumberId] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState({
    openModal: false,
    selectedRow: null,
    cancelOrderDate: moment().format('YYYY-MM-DD'),
  });

  const onSearch = () => {
    if (searchText.trim() !== '') {
      getOrders({ query: searchText, per: ordersList['meta']['per_page'] });
    } else {
      getOrders({
        per: ordersList['meta']['per_page'],
        query: null,
      });
    }
  };

  const handleTableChange = pagination => {
    getOrders({
      page: pagination['current'],
      per: pagination['pageSize'],
      query: searchText ? searchText : null,
      activeTab,
    });
  };

  const handleCancelOrder = record => {
    setSelectedRecord({
      ...selectedRecord,
      openModal: true,
      selectedRow: record,
    });
  };

  const handleCancelRequestModalClose = () => {
    setSelectedRecord({
      openModal: false,
      selectedRow: null,
      cancelOrderDate: moment().format('YYYY-MM-DD'),
    });
  };

  const submitCancelRequest = () => {
    const params = {
      id: selectedRecord?.selectedRow?.id,
      order: {
        canceled_at: selectedRecord.cancelOrderDate,
      },
    };

    cancelOrderRequest(params);
    handleCancelRequestModalClose();
  };

  const showjobDetailModal = data => {
    getJob({
      id: data?.job_id ? data?.job_id : data?.repeating_job_id,
      type: data?.job_id ? 'single' : 'repeating',
    });
    updateJobDetailModal({ ...jobDetailModal, orderId: data?.id });
  };

  const showOrderItemsModal = (data, activeTab) => {
    updateOrderItemsModal({
      status: true,
      formattedAmount: data['formatted_amount'],
      records:
        activeTab === 'concierge'
          ? data['concierge_order_items']
          : data['order_items'],
      orderId: data['id'],
    });
  };
  const showClientAddress = record => {
    console.log(record);
    const id = record['buyer']['personal_client_id'];
    const {
      history: { push },
    } = props;

    push({
      pathname: `/admin/clients/personal/${id}`,
    });
  };
  const showOrderTransactionsModal = data => {
    updateOrderTransactionsModal({
      status: true,
      formattedAmount:
        '$' +
        data['order_transactions']
          .reduce(
            (accumulator, current) => accumulator + parseFloat(current.amount),
            0
          )
          .toFixed(2),
      records: data['order_transactions'],
      subStart: new Date(data.start_subscription_at).toLocaleString(),
      orderType: data.order_type,
    });
  };

  const onCreate = () => {
    setModalState(1);
    // setSelectedRow(null);
    setFormData(defaultOrder);
    const { getAccounts } = props;
    getAccounts();
  };

  const onDelete = () => {
    setModalState(2);
    // setSelectedRow(record);
    setFormData(null);
  };

  const closeModal = () => {
    setModalState(0);
    // setSelectedRow(null);
    setFormData(null);
  };

  const handleDateChange = async date => {
    setSelectedRecord({
      ...selectedRecord,
      cancelOrderDate: moment(date).format('YYYY-MM-DD'),
    });
  };
  const disabledDate = current => {
    let now = moment();
    return now > current;
  };

  const handleOrderDetails = record => {
    const {
      history: { push },
    } = props;

    push(`/admin/orders/${record.id}`);
  };

  const onSubmit = values => {
    // const { formData } = this.state;
    // console.log('vale ', values, formData)
    // if (formData.id) {
    //   const { updateOrder } = this.props;
    //   updateOrder({ ...formData, ...values, picture: formData['picture'] });
    // } else {
    const { createOrder } = props;
    createOrder({
      ...values,
    });
    // }
    closeModal();
  };

  useEffect(() => {
    if (jobs?.length && jobs !== ['undefined']) {
      updateJobDetailModal({
        ...jobDetailModal,
        status: true,
        records: jobs,
      });
    }
  }, [jobs]);

  useEffect(() => {
    updateOrdersList(orders);
  }, [orders]);

  useEffect(() => {
    getOrders({
      page: 1,
      per: 20,
      query: null,
      activeTab: 'all',
    });
  }, [getOrders]);

  return (
    <div className="transaction-container">
      <div className="page-header">
        <div className="page-title">
          <h4>Orders</h4>
        </div>
        <div className="page-header-actions">
          <Button type="primary" icon={<PlusOutlined />} onClick={onCreate}>
            Add New Order
          </Button>
        </div>
      </div>
      <Drawer
        closable={false}
        placement="right"
        width={450}
        visible={modalState === 1}
        onClose={closeModal}
      >
        {formData && (
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <CreateOrder
              formData={formData}
              onDelete={onDelete}
              closeModal={closeModal}
              onSubmit={onSubmit}
              accounts={props.accounts}
              // onInputChange={onInputChange}
              // loading={loading || submitting}
            />
          </Suspense>
        )}
      </Drawer>

      <div className="transaction-content-container">
        <TableSearch
          placeholder="Search Order"
          btnText="Search"
          onBtnClick={onSearch}
          value={searchText}
          onInputChange={ev => updateSearchText(ev['target']['value'])}
        />

        <Card>
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <OrderTable
              dataSource={ordersList['orders']}
              loading={loading}
              getOrders={getOrders}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              setModalOpen={setModalOpen}
              showjobDetailModal={showjobDetailModal}
              showOrderItemsModal={showOrderItemsModal}
              showClientAddress={showClientAddress}
              showOrderTransactionsModal={showOrderTransactionsModal}
              orderDelivered={orderDelivered}
              setTrackingIdModalOpen={setIsTrModalOpen}
              setTrackingNumberId={setTrackingNumberId}
              pagination={{
                limit: ordersList['meta'] ? ordersList['meta']['per_page'] : 20,
                total: ordersList['meta']
                  ? ordersList['meta']['total_count']
                  : 0,
                page: ordersList['meta'] ? ordersList['meta']['page'] : 1,
                data: ordersList['orders'],
              }}
              handleTableChange={handleTableChange}
              cancelOrder={handleCancelOrder}
              handleOrderDetails={handleOrderDetails}
            />
          </Suspense>
        </Card>
      </div>
      <Modal
        title={<OrderTableHeading title={'Job'} />}
        visible={jobDetailModal['status']}
        onOk={() => updateJobDetailModal({ status: false, records: [] })}
        onCancel={() => updateJobDetailModal({ status: false, records: [] })}
        width="80%"
      >
        <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
          <JobDetailTable dataSource={jobDetailModal['records']} />
        </Suspense>
      </Modal>
      <Modal
        title={
          <OrderTableHeading
            title={'Order Items'}
            orderId={orderItemsModal['orderId']}
            itemCount={orderItemsModal['records']['length']}
          />
        }
        visible={orderItemsModal['status']}
        onOk={() => updateOrderItemsModal({ status: false, records: [] })}
        onCancel={() => updateOrderItemsModal({ status: false, records: [] })}
        width="80%"
      >
        <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
          <OrderItemsTable
            dataSource={orderItemsModal['records']}
            footer={() =>
              orderItemsModal['records']['length'] ? (
                <OrderItemTableFooter
                  formattedAmount={orderItemsModal['formattedAmount']}
                />
              ) : null
            }
          />
        </Suspense>
      </Modal>
      <Modal
        title={
          <OrderTableHeading
            title={'Order Transactions Details'}
            subStart={
              orderTransactionsModal.orderType === 'subscription'
                ? orderTransactionsModal.subStart
                : undefined
            }
          />
        }
        visible={orderTransactionsModal['status']}
        onOk={() =>
          updateOrderTransactionsModal({ status: false, records: [] })
        }
        onCancel={() =>
          updateOrderTransactionsModal({ status: false, records: [] })
        }
        width="80%"
      >
        <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
          <OrderTransactionsTable
            dataSource={orderTransactionsModal['records']}
            footer={() =>
              orderTransactionsModal['records']['length'] ? (
                <OrderItemTableFooter
                  formattedAmount={orderTransactionsModal['formattedAmount']}
                />
              ) : null
            }
          />
        </Suspense>
      </Modal>
      <Modal
        title="Transaction Details"
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        width="80%"
      >
        <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
          <TransactionTable
            records={[
              {
                key: 0,
                timeStamp: '28/06/2020',
                transactionId: 'TX121',
                invoiceID: '121313',
                transactionType: { type: 'DEBIT', text: 'Debit' },
                transactionFrom: 'Stripe',
                transactionTo: 'Wallet',
                orderId: '1029',
                amountPrice: '190',
                clientType: 'Personal',
                clientName: 'Rebeca Chander',
                message: '',
                status: { type: 'SUCCESS', text: 'Successful' },
              },
            ]}
            pagination={false}
          />
        </Suspense>
      </Modal>
      <Modal
        onCancel={() => {
          setIsTrModalOpen(false);
        }}
        footer={null}
        title="Tracking Id"
        visible={isTrModalOpen}
        destroyOnClose
      >
        <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
          <EditableTagGroup
            trackingNumebrId={trackingNumebrId}
            orderShipped={orderShipped}
            setIsTrModalOpen={setIsTrModalOpen}
          />
        </Suspense>
      </Modal>
      <Modal
        className="delete-user-modal"
        onCancel={() => {
          handleCancelRequestModalClose();
        }}
        // footer={null}
        header={null}
        // title="Cancel Request"
        visible={selectedRecord.openModal}
        onOk={submitCancelRequest}
        okText="Yes"
        destroyOnClose
      >
        {selectedRecord?.selectedRow && (
          <>
            <div className="user-info">
              <Avatar icon={<InboxOutlined />} size={72} />
              <p>Order ID:&nbsp;{selectedRecord?.selectedRow?.id}</p>
              {selectedRecord.selectedRow.order_type === 'subscription' && (
                <>
                  <p>
                    Please choose the date from where you want to cancel the
                    order.
                  </p>
                  <Row gutter={[10, 8]} style={{ padding: '10px 0' }}>
                    <Col span={24}>
                      <DatePicker
                        value={
                          selectedRecord?.cancelOrderDate
                            ? moment(
                                selectedRecord.cancelOrderDate,
                                'YYYY-MM-DD'
                              )
                            : null
                        }
                        disabledDate={disabledDate}
                        onChange={handleDateChange}
                        allowClear={false}
                        style={{
                          width: '100%',
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>

            <p className="main-message">
              Are you sure want to cancel this order? Once Done, It cannot be
              reversed.
            </p>
          </>
        )}
      </Modal>
    </div>
  );
}

Order.propTypes = {
  jobs: PropTypes.object,
  getJob: PropTypes.func,
  orders: PropTypes.object,
  getOrders: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  createOrder: PropTypes.func,
  getAccounts: PropTypes.func,
  orderShipped: PropTypes.func,
  orderDelivered: PropTypes.func,
  updateTrackingNo: PropTypes.func,
  cancelOrderRequest: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.orders.loading,
    jobs: state.orders.jobs,
    orders: state.orders.orders,
    accounts: state.orders.accounts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJob: payload => dispatch(getJob(payload)),
    getOrders: payload => dispatch(getOrders(payload)),
    createOrder: payload => dispatch(createOrder(payload)),
    getAccounts: () => dispatch(getAccounts()),
    orderShipped: id => dispatch(orderShipped(id)),
    orderDelivered: id => dispatch(orderDelivered(id)),
    cancelOrderRequest: payload => dispatch(cancelOrderRequest(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
