import {
  CloseOutlined,
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Col, Drawer, Row } from 'antd';
import moment from 'moment';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableSearch from '../../components/TableSearch';
const JobForm = lazy(() => import('./components/form'));
const SingleJobs = lazy(() => import('./components/single'));
const RepeatingJobs = lazy(() => import('./components/repeating'));
const HistoryJobs = lazy(() => import('./components/history'));
import {
  setTab,
  createJob,
  getJobs,
  setModalJob,
  getJobById,
} from './reducers';
const AllJobs = lazy(() => import('./components/all'));
const JobDetailModal = lazy(() => import('./components/jobDetailModal'));
import { withRouter } from 'react-router-dom';

import './styles.css';

class JobPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      modalOpened: false,
      query: '',
      isJobDetailsModalOpen: false,
      jobId: null,
      repeatingJobActiveTab: 'active',
    };
  }
  componentDidUpdate(prevProps) {
    const { submitting, error } = this.props;

    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }
  onSearch = () => {
    const { activeTab, getJobs, jobs } = this.props;
    const { query, repeatingJobActiveTab } = this.state;
    const { limit } = jobs;

    if (activeTab === 'single') {
      getJobs({
        query,
        type: 'single',
        per: limit,
        page: 1,
        activeTab: repeatingJobActiveTab,
      });
    } else if (activeTab === 'repeating') {
      getJobs({
        query,
        type: 'repeating',
        per: limit,
        page: 1,
        activeTab: repeatingJobActiveTab,
      });
    } else if (activeTab === 'history') {
      getJobs({
        query,
        type: 'history',
        per: limit,
        page: 1,
        activeTab: repeatingJobActiveTab,
      });
    } else {
      getJobs({ query, per: limit, page: 1, activeTab: repeatingJobActiveTab });
    }
  };

  openModal = () => {
    const { activeTab } = this.props;

    if (activeTab === 'single') {
      this.setState({
        modalOpened: true,
        formData: {
          title: '',
          description: '',
          job_duration_in_minutes: '',
          client_type: 'personal',
          vendor_id: undefined,
          client_id: undefined,
          address_id: undefined,
          service_date: moment().format('YYYY-MM-DD'),
          preferred_time: moment().format('hh:mm A'),
          job_notes: '',
        },
      });
    } else if (activeTab === 'repeating') {
      this.setState({
        modalOpened: true,
        formData: {
          frequency_value: '',
          frequency_type: 'weekly',
          days: [],
          term_type: 'week',
          term_value: '',
          start_date: moment().format('YYYY-MM-DD'),
          title: '',
          description: '',
          job_duration_in_minutes: '',
          client_type: 'personal',
          vendor_id: undefined,
          client_id: undefined,
          address_id: undefined,
          preferred_time: moment().format('hh:mm A'),
          job_notes: '',
        },
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
      formData: null,
    });
  };

  jobDetailsModalOpen = () => {
    this.setState(state => ({
      ...state,
      isJobDetailsModalOpen: true,
    }));
  };

  jobDetailsModalClose = () => {
    this.setState(state => ({
      ...state,
      isJobDetailsModalOpen: false,
    }));
    this.removeJobId();
  };
  setJobId = id => {
    this.setState(state => ({
      ...state,
      jobId: id,
    }));
  };
  removeJobId = () => {
    this.setState(state => ({
      ...state,
      jobId: null,
    }));
  };

  onSubmit = data => {
    const { activeTab, createJob } = this.props;
    const { repeatingJobActiveTab } = this.state;
    let body;
    if (activeTab === 'repeating') {
      body = {
        frequency_value: data.frequency_value,
        frequency_type: data.frequency_type,
        days: data.days,
        days_quantity_limit: data.days_quantity_limit,
        status: data?.job_status,
        perpetual: data['perpetual'],
        term_type: data['perpetual'] ? undefined : data.term_type,
        term_value: data['perpetual'] ? undefined : data.term_value,
        start_date: data.start_date,
        client_id: data.client_id,
        client_type:
          data.client_type === 'personal' ? 'PersonalClient' : 'BusinessClient',
        jobs_attributes: {
          title: data.title,
          description: data.description,
          job_duration_in_minutes: data.job_duration_in_minutes,
          vendor_id: data.vendor_id,
          client_type:
            data.client_type === 'personal'
              ? 'PersonalClient'
              : 'BusinessClient',
          client_id: data.client_id,
          address_id: data.address_id,
          preferred_time: data.preferred_time,
          job_notes: data.job_notes,
          job_status: data?.job_status,
        },
      };
    } else {
      body = {
        job: {
          title: data.title,
          description: data.description,
          job_duration_in_minutes: data.job_duration_in_minutes,
          vendor_id: data.vendor_id,
          client_type:
            data.client_type === 'personal'
              ? 'PersonalClient'
              : 'BusinessClient',
          client_id: data.client_id,
          address_id: data.address_id,
          service_date: data.service_date,
          preferred_time: data.preferred_time,
          job_notes: data.job_notes,
          job_status: data?.job_status,
          fixed_service_time: data.fixed_service_time,
        },
      };
    }
    createJob(activeTab, body, repeatingJobActiveTab);
    // this.closeModal();
  };

  repeatingJobTabStateSet = ({ value }) => {
    this.setState({ repeatingJobActiveTab: value });
  };

  render() {
    const { activeTab, setTab, submitting } = this.props;
    const { modalOpened, formData, query } = this.state;
    return (
      <div className="jobs-page">
        <div className="page-header">
          <Row>
            <Col>
              <div className="page-title">
                <h4>Jobs</h4>
              </div>
              <div className="page-header-actions">
                {activeTab !== 'history' && activeTab !== 'all' && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={this.openModal}
                  >
                    Add New Job
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="ant-tabs-nav ant-tabs-nav-animated">
                <div
                  className={`ant-tabs-tab${
                    activeTab === 'all' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setTab('all')}
                >
                  ALL
                </div>
                <div
                  className={`ant-tabs-tab${
                    activeTab === 'single' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setTab('single')}
                >
                  One Time
                </div>
                <div
                  className={`ant-tabs-tab${
                    activeTab === 'repeating' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setTab('repeating')}
                >
                  Subscription
                </div>
                <div
                  className={`ant-tabs-tab${
                    activeTab === 'history' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setTab('history')}
                >
                  History
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="page-content">
          <TableSearch
            placeholder="Search Job"
            btnText="Search"
            onBtnClick={this.onSearch}
            value={query}
            onInputChange={ev =>
              this.setState({ query: ev['target']['value'] })
            }
          />
          {activeTab === 'all' ? (
            <Suspense
              fallback={<LoadingOutlined className="fallback-loader" />}
            >
              <AllJobs
                queryString={query}
                jobDetailsModalOpen={this.jobDetailsModalOpen}
                setJobId={this.setJobId}
              />
            </Suspense>
          ) : activeTab === 'single' ? (
            <Suspense
              fallback={<LoadingOutlined className="fallback-loader" />}
            >
              <SingleJobs
                queryString={query}
                jobDetailsModalOpen={this.jobDetailsModalOpen}
                setJobId={this.setJobId}
              />
            </Suspense>
          ) : activeTab === 'repeating' ? (
            <Suspense
              fallback={<LoadingOutlined className="fallback-loader" />}
            >
              <RepeatingJobs
                queryString={query}
                setJobId={this.setJobId}
                repeatingJobTabStateSet={this.repeatingJobTabStateSet}
              />
            </Suspense>
          ) : (
            <Suspense
              fallback={<LoadingOutlined className="fallback-loader" />}
            >
              <HistoryJobs
                queryString={query}
                jobDetailsModalOpen={this.jobDetailsModalOpen}
                setJobId={this.setJobId}
              />
            </Suspense>
          )}
        </div>
        <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
          <JobDetailModal
            onClose={this.jobDetailsModalClose}
            isOpended={this.state.isJobDetailsModalOpen}
            jobId={this.state.jobId}
          />
        </Suspense>
        <Drawer
          closable={false}
          placement="right"
          width={450}
          className="job-create-drawer"
          visible={modalOpened}
          onClose={this.closeModal}
          destroyOnClose
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>
                  Add New Job -{' '}
                  {activeTab === 'single' ? 'One Time' : 'Subscription'}
                </h3>
                <div className="header-actions">
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <Suspense
                  fallback={<LoadingOutlined className="fallback-loader" />}
                >
                  <JobForm
                    data={formData}
                    type={activeTab}
                    onSubmit={this.onSubmit}
                    submitting={submitting}
                  />
                </Suspense>
              </div>
            </>
          )}
        </Drawer>
      </div>
    );
  }
}

JobPage.propTypes = {
  modalLoading: PropTypes.bool,
  modalJob: PropTypes.object,
  setModalJob: PropTypes.func,
  getJobById: PropTypes.func,
  setTab: PropTypes.func,
  getJobs: PropTypes.func,
  createJob: PropTypes.func,
  activeTab: PropTypes.string,
  jobs: PropTypes.object,
  history: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    activeTab: state.jobs.activeTab,
    jobs: state.jobs.jobs,
    modalLoading: state.jobs.modalLoading,
    modalJob: state.jobs.modalJob,
    submitting: state.jobs.submitting,
    error: state.jobs.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setModalJob: job => dispatch(setModalJob(job)),
    getJobById: jobId => dispatch(getJobById(jobId)),
    setTab: tab => dispatch(setTab(tab)),
    getJobs: payload => dispatch(getJobs(payload)),
    createJob: (type, data, repeatingJobActiveTab) =>
      dispatch(createJob({ type, data, repeatingJobActiveTab })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JobPage));
