import React, { useState, useEffect } from 'react';
import StyledItemForm from '../styled-components/form-split';
import ImageUpload from '../../../components/ImageUpload';
import StyledFormItem from '../styled-components/form-item';
import {
  Input,
  InputNumber,
  Select,
  Spin,
  Form,
  Row,
  Col,
  DatePicker,
  Radio,
  // TimePicker,
  Button,
  Card,
  Image,
} from 'antd';
import ReactTimePicker from '../../../components/TimePicker';

import { capitalize, getImageURI } from '../../../utils/apisauce';
import PropTypes from 'prop-types';
import ProductCreateFieldTitle from '../../../components/ProductCreateFieldTitle';
import {
  DeleteFilled,
  PlusOutlined,
  DeleteOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
// const timeFormat = 'HH:00';

const defaultFixedDateTime = {
  schedule_date: null,
  from: null,
};
const defaultSelectDaysTime = {
  day_name: null,
  from: null,
  to: null,
};

const ClassItemForm = React.forwardRef((props, ref) => {
  const [selectType, setSelectType] = useState('');
  const [calendar_settings_attributes, setCalendar_settings_attributes] =
    useState([]);
  const [flag, setFlag] = useState(false);
  const [renderFlag, setRenderFlag] = useState(false);
  const calSetLastInd = calendar_settings_attributes.length - 1;
  const {
    index,
    initialState: itemState,
    errors,
    hasOptions,
    fetchingTags,
    tagsDataOption,
    setTagSearchKeyword,
    setFormStatus,
    updateCreateTagForm,
    handleRemoveItem,
    onInputChange,
    selected,
    setFieldValue,
    values,
    pageTitle,
    handleCheckbox2,
    libraryImage,
  } = props;

  useEffect(() => {
    const calendar_settings =
      'calendar_settings' in itemState ? itemState.calendar_settings : [];
    setCalendar_settings_attributes(calendar_settings);
  }, [itemState]);

  const handleAddtype = e => {
    setSelectType(e.target.value);

    onInputChange({ target: { name: e.target.name, value: e.target.value } });
    setRenderFlag(true);

    if (e.target.value === 'fixed_date_time') {
      const newFixedItem = Object.assign({}, defaultFixedDateTime);
      if (
        pageTitle === 'Edit Class' &&
        calendar_settings_attributes.length > 0
      ) {
        let calendar_settings = [...calendar_settings_attributes];
        calendar_settings = calendar_settings.map(item => {
          return {
            ...item,
            _destroy: item.schedule_date || item.to ? 1 : 2,
          };
        });
        if (
          calendar_settings[calendar_settings.length - 1].schedule_date ||
          calendar_settings[calendar_settings.length - 1].to
        ) {
          calendar_settings.push(newFixedItem);
        }

        setCalendar_settings_attributes(calendar_settings);
      } else {
        setCalendar_settings_attributes([newFixedItem]);
      }
    } else if (e.target.value === 'select_days_time') {
      const newItem = Object.assign({}, defaultSelectDaysTime);
      if (
        pageTitle === 'Edit Class' &&
        calendar_settings_attributes.length > 0
      ) {
        let calendar_settings = [...calendar_settings_attributes];
        calendar_settings = calendar_settings.map(item => {
          return {
            ...item,
            _destroy: item.schedule_date || item.to ? 1 : 2,
          };
        });
        if (
          calendar_settings[calendar_settings.length - 1].schedule_date ||
          calendar_settings[calendar_settings.length - 1].to
        ) {
          calendar_settings.push(newItem);
        }

        setCalendar_settings_attributes(calendar_settings);
      } else {
        setCalendar_settings_attributes([newItem]);
      }
    }
    onInputChange({
      target: {
        name: 'calendar_settings_attributes',
        value: calendar_settings_attributes,
      },
    });
  };

  const handleAddPlus = (value, addType) => {
    let arr = [...calendar_settings_attributes];
    let l = arr.length;
    let ind = null;
    if ('_destroy' in calendar_settings_attributes[calSetLastInd]) {
      while (l--) {
        if (arr[l]._destroy === 2) {
          ind = l;
          break;
        }
      }
    }

    if (value === 'fixed_date_time') {
      const newFixedItem = Object.assign(
        {},
        addType === 'clone'
          ? calendar_settings_attributes[ind !== null ? ind : calSetLastInd]
          : defaultFixedDateTime
      );
      setCalendar_settings_attributes([
        ...calendar_settings_attributes,
        newFixedItem,
      ]);
    } else if (value === 'select_days_time') {
      const newItem = Object.assign(
        {},
        addType === 'clone'
          ? calendar_settings_attributes[ind !== null ? ind : calSetLastInd]
          : defaultSelectDaysTime
      );
      setCalendar_settings_attributes([
        ...calendar_settings_attributes,
        newItem,
      ]);
    }
    onInputChange({
      target: {
        name: 'calendar_settings_attributes',
        value: calendar_settings_attributes,
      },
    });
  };

  const typeScheduleDateHandler = (item, dateString) => {
    item.schedule_date = dateString;
    setFlag(flag => !flag);
    onInputChange({
      target: {
        name: 'calendar_settings_attributes',
        value: calendar_settings_attributes,
      },
    });
  };
  const typeFixedFromHandler = (item, dateString) => {
    item.from = dateString;
    setFlag(flag => !flag);
    onInputChange({
      target: {
        name: 'calendar_settings_attributes',
        value: calendar_settings_attributes,
      },
    });
  };
  const typeSelectFromHandler = (item, dateString) => {
    item.from = dateString;
    setFlag(flag => !flag);
    onInputChange({
      target: {
        name: 'calendar_settings_attributes',
        value: calendar_settings_attributes,
      },
    });
  };
  const typeSelectToHandler = (item, dateString) => {
    item.to = dateString;
    setFlag(flag => !flag);
    onInputChange({
      target: {
        name: 'calendar_settings_attributes',
        value: calendar_settings_attributes,
      },
    });
  };
  const typeDaysHandler = (item, value) => {
    item.day_name = value;
    setFlag(flag => !flag);
    onInputChange({
      target: {
        name: 'calendar_settings_attributes',
        value: calendar_settings_attributes,
      },
    });
  };

  function disabledDate(current) {
    return current && current < moment().startOf('day');
  }
  const handleRemoveTypeItem = i => {
    if (pageTitle === 'Edit Class' && calendar_settings_attributes.length > 0) {
      let calendar_settings = [...calendar_settings_attributes];
      calendar_settings = calendar_settings.map((item, index) => {
        if ('_destroy' in item && item._destroy === 1) {
          return item;
        }
        return {
          ...item,
          _destroy: index === i ? 1 : 2,
        };
      });
      setCalendar_settings_attributes(calendar_settings);
    } else {
      let calendar_settings = [...calendar_settings_attributes];
      calendar_settings = calendar_settings.filter(
        (item, index) => index !== i
      );
      setCalendar_settings_attributes(calendar_settings);
    }
  };

  useEffect(() => {
    onInputChange({
      target: {
        name: 'calendar_settings_attributes',
        value: calendar_settings_attributes,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar_settings_attributes]);

  return (
    <StyledItemForm ref={ref}>
      {index !== 0 ? (
        <DeleteFilled
          style={{ float: 'right', padding: 5 }}
          onClick={() => handleRemoveItem(itemState)}
        />
      ) : (
        ''
      )}
      <div style={{ padding: 24 }}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={18}>
            <StyledFormItem
              label={'Item'}
              hasFeedback
              validateStatus={errors && errors['title'] ? 'error' : null}
              help={
                errors && errors['title']
                  ? capitalize(errors['title'].split('.')[1])
                  : null
              }
            >
              <Input
                value={itemState.title}
                name="title"
                onChange={onInputChange}
                placeholder="Enter item name"
              />
            </StyledFormItem>
            <StyledFormItem
              label={'Description'}
              hasFeedback
              validateStatus={errors && errors['description'] ? 'error' : null}
              help={
                errors && errors['description'] ? errors['description'] : null
              }
            >
              <Input.TextArea
                value={itemState.description}
                name="description"
                onChange={onInputChange}
                placeholder="Enter the message here"
              />
            </StyledFormItem>
          </Col>
          <Col xs={24} sm={24} md={6} style={{ height: '230px' }}>
            <Radio.Group
              defaultValue={itemState.image_type}
              onChange={value => onInputChange(value)}
              name="image_type"
              buttonStyle="solid"
              style={{ marginBottom: '10px' }}
            >
              <Radio.Button name="image_type" value={'attached'}>
                Upload Image
              </Radio.Button>
              <Radio.Button name="image_type" value={'library'}>
                Choose From Library
              </Radio.Button>
            </Radio.Group>
            {itemState.image_type === 'attached' ? (
              <ImageUpload
                value={
                  itemState['image']?.data
                    ? itemState['image']?.data
                    : itemState['images']
                    ? getImageURI(itemState['images'].url)
                    : null
                }
                onChange={value =>
                  onInputChange({
                    target: { name: 'image', value: { data: value } },
                  })
                }
                accept=".png, .jpg, .jpeg"
                selected={selected}
                error={errors}
              />
            ) : (
              <div
                style={{
                  height: '200px',
                  overflowY: 'scroll',
                  textAlign: 'center',
                }}
              >
                <Radio.Group
                  onChange={value => onInputChange(value)}
                  size="large"
                  defaultValue={itemState['images']?.library_image_id}
                  name="library_image_id"
                >
                  {libraryImage?.map(item => (
                    <Radio
                      key={item?.id}
                      className="radioImage"
                      name="library_image_id"
                      value={item?.id}
                    >
                      <Card>
                        <Image
                          src={getImageURI(item?.image_path?.url)}
                          width={120}
                          preview={false}
                        />
                      </Card>
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            )}
          </Col>
          <Col xs={24} sm={24} md={20}>
            <StyledFormItem>
              <Checkbox
                defaultValue={false}
                checked={itemState.is_request_for_quote}
                onChange={value =>
                  handleCheckbox2(value.target.name, value.target.checked)
                }
                name="is_request_for_quote"
              >
                Request For Quote
              </Checkbox>
            </StyledFormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={20}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24 / 4}>
                <StyledFormItem
                  marginRight="10px"
                  label={'Unit'}
                  hasFeedback
                  validateStatus={errors && errors['unit'] ? 'error' : null}
                  help={errors && errors['unit'] ? errors['unit'] : null}
                >
                  <Input
                    value={itemState.unit}
                    disabled={itemState.is_request_for_quote}
                    onChange={value => onInputChange(value)}
                    name="unit"
                    placeholder="Enter unit name"
                  />
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={24} md={24 / 4}>
                <StyledFormItem
                  marginRight="10px"
                  label={'Duration in mins'}
                  hasFeedback
                  validateStatus={
                    errors && errors['duration_in_minutes'] ? 'error' : null
                  }
                  help={
                    errors && errors['duration_in_minutes']
                      ? errors['duration_in_minutes']
                      : null
                  }
                >
                  <InputNumber
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={itemState.duration_in_minutes}
                    disabled={itemState.is_request_for_quote}
                    onChange={value =>
                      onInputChange({
                        target: { name: 'duration_in_minutes', value },
                      })
                    }
                    name="duration_in_minutes"
                    placeholder="Enter Duration (in mins)"
                    style={{ width: '100%' }}
                    min={0}
                  />
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={24} md={24 / 4}>
                <StyledFormItem
                  label={'Amount Price'}
                  marginRight="10px"
                  hasFeedback
                  validateStatus={errors && errors['amount'] ? 'error' : null}
                  help={errors && errors['amount'] ? errors['amount'] : null}
                >
                  <InputNumber
                    formatter={value =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={itemState.amount}
                    onChange={value =>
                      onInputChange({ target: { name: 'amount', value } })
                    }
                    disabled={itemState.is_request_for_quote}
                    name="amount"
                    placeholder="Enter price"
                    style={{ width: '100%' }}
                  />
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={24} md={24 / 4}>
                <StyledFormItem
                  label={'Commission %'}
                  hasFeedback
                  validateStatus={
                    errors && errors['commission_percentage'] ? 'error' : null
                  }
                  help={
                    errors && errors['commission_percentage']
                      ? errors['commission_percentage']
                      : null
                  }
                >
                  <InputNumber
                    defaultValue={0}
                    min={0}
                    max={100}
                    disabled={itemState.is_request_for_quote}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                    value={itemState.commission_percentage}
                    onChange={value =>
                      onInputChange({
                        target: { name: 'commission_percentage', value },
                      })
                    }
                    name="commission_percentage"
                    placeholder="Enter commission percentage"
                    style={{ width: '100%' }}
                    prefix={'%'}
                  />
                </StyledFormItem>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} sm={24} md={20}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={6}>
                <StyledFormItem
                  label={'Class Type'}
                  hasFeedback
                  validateStatus={
                    errors && errors['classes_type'] ? 'error' : null
                  }
                  help={
                    errors && errors['classes_type']
                      ? errors['classes_type']
                      : null
                  }
                >
                  <Select
                    name="classes_type"
                    placeholder="Select class type"
                    style={{ width: '100%' }}
                    value={itemState.classes_type}
                    disabled={itemState.is_request_for_quote}
                    onChange={value =>
                      onInputChange({
                        target: { name: 'classes_type', value },
                      })
                    }
                  >
                    <Select.Option value="online">Online</Select.Option>
                    <Select.Option value="onsite">Onsite</Select.Option>
                  </Select>
                </StyledFormItem>
              </Col>
              {itemState.classes_type === 'online' ? (
                <Col xs={24} sm={24} md={18}>
                  <StyledFormItem
                    label={'Link'}
                    hasFeedback
                    validateStatus={
                      errors && errors['link_or_location'] ? 'error' : null
                    }
                    help={
                      errors && errors['link_or_location']
                        ? capitalize(errors['link_or_location'].split('.')[1])
                        : null
                    }
                  >
                    <Input
                      value={itemState.link_or_location}
                      name="link_or_location"
                      disabled={itemState.is_request_for_quote}
                      onChange={onInputChange}
                      placeholder="Enter the link here"
                    />
                  </StyledFormItem>
                </Col>
              ) : itemState.classes_type === 'onsite' ? (
                <Col xs={24} sm={24} md={18}>
                  <StyledFormItem
                    label={'Location'}
                    hasFeedback
                    validateStatus={
                      errors && errors['link_or_location'] ? 'error' : null
                    }
                    help={
                      errors && errors['link_or_location']
                        ? capitalize(errors['link_or_location'].split('.')[1])
                        : null
                    }
                  >
                    <Input
                      value={itemState.link_or_location}
                      name="link_or_location"
                      onChange={onInputChange}
                      disabled={itemState.is_request_for_quote}
                      placeholder="Enter the location here"
                    />
                  </StyledFormItem>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={20}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={6}>
                <StyledFormItem
                  style={{ paddingTop: '22px' }}
                  hasFeedback
                  validateStatus={errors && errors['promoted'] ? 'error' : null}
                  help={
                    errors && errors['promoted'] ? errors['promoted'] : null
                  }
                >
                  <Checkbox
                    defaultValue={false}
                    style={{ marginBottom: 0 }}
                    checked={itemState.promoted}
                    disabled={itemState.is_request_for_quote}
                    onChange={value =>
                      handleCheckbox2(value.target.name, value.target.checked)
                    }
                    name="promoted"
                  >
                    Promoted
                  </Checkbox>
                </StyledFormItem>
              </Col>
              {itemState.promoted ? (
                <Col xs={24} sm={24} md={6}>
                  <StyledFormItem
                    label={'Promoted Discount %'}
                    hasFeedback
                    validateStatus={
                      errors && errors['promoted_discount_percentage']
                        ? 'error'
                        : null
                    }
                    help={
                      errors && errors['promoted_discount_percentage']
                        ? errors['promoted_discount_percentage']
                        : null
                    }
                  >
                    <InputNumber
                      defaultValue={0}
                      min={0}
                      max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                      disabled={itemState.is_request_for_quote}
                      value={itemState.promoted_discount_percentage}
                      onChange={value =>
                        onInputChange({
                          target: {
                            name: 'promoted_discount_percentage',
                            value,
                          },
                        })
                      }
                      name="promoted_discount_percentage"
                      placeholder="Enter promoted discount %"
                      style={{ width: '100%' }}
                      prefix={'%'}
                    />
                  </StyledFormItem>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Col>
        </Row>
        <div style={{ padding: '16px', border: '1px solid #f0f0f0' }}>
          <Row gutter={24}>
            <Col span={24}>
              <div
                style={{
                  color: flag || selectType ? '' : '',
                }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={6}>
                    <StyledFormItem
                      marginRight="10px"
                      label={'Campaign'}
                      hasFeedback
                      validateStatus={
                        errors && errors['campaign_start_at'] ? 'error' : null
                      }
                      help={
                        errors && errors['campaign_start_at']
                          ? errors['campaign_start_at']
                          : null
                      }
                    >
                      <DatePicker
                        name="campaign_start_at"
                        placeholder="Start date"
                        disabled={itemState.is_request_for_quote}
                        disabledDate={disabledDate}
                        onChange={(date, dateString) =>
                          onInputChange({
                            target: {
                              name: 'campaign_start_at',
                              value: dateString,
                            },
                          })
                        }
                        value={
                          itemState.campaign_start_at
                            ? moment(itemState.campaign_start_at, dateFormat)
                            : null
                        }
                        style={{ width: '100%', display: 'inline-block' }}
                      />
                    </StyledFormItem>
                  </Col>
                  <Col xs={24} sm={24} md={6}>
                    <StyledFormItem
                      marginRight="10px"
                      label={''}
                      hasFeedback
                      // validateStatus={
                      // errors && errors['campaign_start_at']
                      //     ? 'error'
                      //     : null
                      // }
                      // help={errors && errors['campaign_start_at'] ? errors['campaign_start_at'] : null}
                    >
                      <DatePicker
                        name="campaign_end_at"
                        label={` `}
                        placeholder="End date (optional)"
                        disabledDate={disabledDate}
                        disabled={itemState.is_request_for_quote}
                        onChange={(date, dateString) =>
                          onInputChange({
                            target: {
                              name: 'campaign_end_at',
                              value: dateString,
                            },
                          })
                        }
                        value={
                          itemState.campaign_end_at
                            ? moment(itemState.campaign_end_at, dateFormat)
                            : null
                        }
                        style={{
                          width: '100%',
                          marginTop: '22px',
                          display: 'inline-block',
                        }}
                      />
                    </StyledFormItem>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <StyledFormItem
                      // marginRight="10px"
                      label={'Type'}
                      hasFeedback
                      validateStatus={
                        errors && errors['campaign_type'] ? 'error' : null
                      }
                      help={
                        errors && errors['campaign_type']
                          ? errors['campaign_type']
                          : null
                      }
                      name={'campaign_type'}
                    >
                      <div className="form-group ">
                        <Radio.Group
                          onChange={e =>
                            handleAddtype(e, setFieldValue, values)
                          }
                          value={itemState.campaign_type}
                          disabled={itemState.is_request_for_quote}
                          name={'campaign_type'}
                        >
                          <Radio value="fixed_date_time">Fixed Date Time</Radio>
                          <Radio value="select_days_time">
                            Select Days Time
                          </Radio>
                        </Radio.Group>
                      </div>
                    </StyledFormItem>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {calendar_settings_attributes &&
          itemState?.campaign_type === 'fixed_date_time' &&
          !itemState.is_request_for_quote
            ? calendar_settings_attributes.map((item, index) => {
                return (item.schedule_date ||
                  renderFlag ||
                  pageTitle === 'Edit Class') &&
                  ('_destroy' in item && item._destroy === 1 ? false : true) ? (
                  <Row gutter={24} key={index}>
                    {/* {last_index === index ? (
                      <Col span={1} style={{ marginTop: '7px' }}>
                        <PlusOutlined
                          onClick={() => handleAddPlus('fixed_date_time')}
                        />
                      </Col>
                    ) : (
                      <Col span={1} style={{ marginTop: '7px' }}>
                        {' '}
                      </Col>
                    )} */}
                    <Col span={10}>
                      <StyledFormItem
                        marginRight="10px"
                        label={''}
                        hasFeedback
                        validateStatus={
                          errors &&
                          errors?.calendar_settings_attributes?.length > 0 &&
                          errors?.calendar_settings_attributes[index]
                            ?.schedule_date
                            ? 'error'
                            : null
                        }
                        help={
                          errors &&
                          errors?.calendar_settings_attributes?.length > 0 &&
                          errors?.calendar_settings_attributes[index]
                            ?.schedule_date
                            ? errors?.calendar_settings_attributes[index]
                                ?.schedule_date
                            : null
                        }
                      >
                        <DatePicker
                          name=""
                          label={` `}
                          disabledDate={disabledDate}
                          placeholder="Schedule date"
                          onChange={(date, dateString) =>
                            typeScheduleDateHandler(item, dateString)
                          }
                          value={
                            item.schedule_date
                              ? moment(item.schedule_date, dateFormat)
                              : null
                          }
                          style={{
                            width: '100%',
                            display: 'inline-block',
                          }}
                        />
                      </StyledFormItem>
                    </Col>
                    <Col span={10}>
                      <StyledFormItem
                        marginRight="10px"
                        label={''}
                        hasFeedback
                        validateStatus={
                          errors &&
                          errors?.calendar_settings_attributes?.length > 0 &&
                          errors?.calendar_settings_attributes[index]?.from
                            ? 'error'
                            : null
                        }
                        help={
                          errors &&
                          errors?.calendar_settings_attributes?.length > 0 &&
                          errors?.calendar_settings_attributes[index]?.from
                            ? errors?.calendar_settings_attributes[index]?.from
                            : null
                        }
                      >
                        {/* <TimePicker
                          name="from"
                          format={timeFormat}
                          placeholder="Select start time"
                          onChange={(date, timeString) =>
                            typeFixedFromHandler(item, timeString)
                          }
                          onSelect={date => {
                            typeFixedFromHandler(
                              item,
                              moment(date, 'h:mm A').format('HH:mm')
                            );
                          }}
                          value={
                            item.from ? moment(item.from, timeFormat) : null
                          }
                          style={{ width: '100%' }}
                        /> */}

                        <ReactTimePicker
                          name="from"
                          item={item}
                          cahngeFormTime={typeFixedFromHandler}
                          placeholder="Select start time"
                        />
                      </StyledFormItem>
                    </Col>
                    <Col span={2}>
                      <DeleteOutlined
                        style={{ marginTop: '7px' }}
                        onClick={() => handleRemoveTypeItem(index)}
                      />
                    </Col>
                  </Row>
                ) : (
                  ''
                );
              })
            : itemState.campaign_type === 'select_days_time' &&
              !itemState.is_request_for_quote
            ? calendar_settings_attributes.map((item, index) => {
                return (item.to || renderFlag || pageTitle === 'Edit Class') &&
                  ('_destroy' in item && item._destroy === 1 ? false : true) ? (
                  <Row gutter={24} key={index}>
                    {/* {last_index === index ? (
                      <Col span={1} style={{ marginTop: '7px' }}>
                        <PlusOutlined
                          onClick={() => handleAddPlus('select_days_time')}
                        />
                      </Col>
                    ) : (
                      <Col span={1} style={{ marginTop: '7px' }}>
                        {' '}
                      </Col>
                    )} */}
                    <Col span={6}>
                      <StyledFormItem
                        marginRight="10px"
                        label={''}
                        hasFeedback
                        validateStatus={
                          errors &&
                          errors?.calendar_settings_attributes?.length > 0 &&
                          errors?.calendar_settings_attributes[index]?.day_name
                            ? 'error'
                            : null
                        }
                        help={
                          errors &&
                          errors?.calendar_settings_attributes?.length > 0 &&
                          errors?.calendar_settings_attributes[index]?.day_name
                            ? errors?.calendar_settings_attributes[index]
                                ?.day_name
                            : null
                        }
                      >
                        <Select
                          // mode="multiple"
                          name="day_name"
                          value={item.day_name}
                          style={{ width: '100%' }}
                          placeholder="Select day"
                          onChange={value => {
                            return typeDaysHandler(item, value);
                          }}
                        >
                          <Option value="monday">Monday</Option>
                          <Option value="tuesday">Tuesday</Option>
                          <Option value="wednesday">Wednesday</Option>
                          <Option value="thursday">Thursday</Option>
                          <Option value="friday">Friday</Option>
                          <Option value="saturday">Saturday</Option>
                          <Option value="sunday">Sunday</Option>
                        </Select>
                      </StyledFormItem>
                    </Col>
                    <Col span={7}>
                      <StyledFormItem
                        marginRight="10px"
                        label={''}
                        hasFeedback
                        validateStatus={
                          errors &&
                          errors?.calendar_settings_attributes?.length > 0 &&
                          errors?.calendar_settings_attributes[index]?.from
                            ? 'error'
                            : null
                        }
                        help={
                          errors &&
                          errors?.calendar_settings_attributes?.length > 0 &&
                          errors?.calendar_settings_attributes[index]?.from
                            ? errors?.calendar_settings_attributes[index]?.from
                            : null
                        }
                      >
                        {/* <TimePicker
                          name="from"
                          format={timeFormat}
                          placeholder="Select start time"
                          onChange={(date, timeString) =>
                            typeSelectFromHandler(item, timeString)
                          }
                          value={
                            item.from ? moment(item.from, timeFormat) : null
                          }
                          style={{ width: '100%' }}
                        /> */}
                        <ReactTimePicker
                          name="from"
                          item={item}
                          cahngeFormTime={typeSelectFromHandler}
                          placeholder="Select start time"
                        />
                      </StyledFormItem>
                    </Col>
                    <Col span={7}>
                      <StyledFormItem
                        marginRight="10px"
                        label={''}
                        hasFeedback
                        validateStatus={
                          errors &&
                          errors?.calendar_settings_attributes?.length > 0 &&
                          errors?.calendar_settings_attributes[index]?.to
                            ? 'error'
                            : null
                        }
                        help={
                          errors &&
                          errors?.calendar_settings_attributes?.length > 0 &&
                          errors?.calendar_settings_attributes[index]?.to
                            ? errors?.calendar_settings_attributes[index]?.to
                            : null
                        }
                      >
                        {/* <TimePicker
                          name="to"
                          format={timeFormat}
                          placeholder="Select end time"
                          onChange={(date, timeString) =>
                            typeSelectToHandler(item, timeString)
                          }
                          value={item.to ? moment(item.to, timeFormat) : null}
                          style={{ width: '100%' }}
                        /> */}
                        <ReactTimePicker
                          name="to"
                          item={item}
                          cahngeFormTime={typeSelectToHandler}
                          placeholder="Select end time"
                        />
                      </StyledFormItem>
                    </Col>
                    <Col span={2}>
                      <DeleteOutlined
                        style={{ marginTop: '7px' }}
                        onClick={() => handleRemoveTypeItem(index)}
                      />
                    </Col>
                  </Row>
                ) : (
                  ''
                );
              })
            : ''}
          {itemState.campaign_type && (
            <>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => handleAddPlus(itemState.campaign_type, 'empty')}
              >
                Add
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: '7px', marginTop: '0px' }}
                icon={<CopyOutlined />}
                onClick={() => handleAddPlus(itemState.campaign_type, 'clone')}
              >
                Clone
              </Button>
            </>
          )}
        </div>
        {hasOptions && (
          <div className="form-group" style={{ marginTop: 16 }}>
            <ProductCreateFieldTitle
              label="Options"
              btnText="Add New Option"
              btnOnClick={() => {
                setFormStatus({
                  showCreateCategoryForm: false,
                  showCreateVendorForm: false,
                  showCreateTagForm: true,
                });
                updateCreateTagForm({ title: '' });
              }}
            />
            <Form.Item
              hasFeedback
              validateStatus={errors && errors['property_ids'] ? 'error' : null}
              help={
                errors && errors['property_ids'] ? errors['property_ids'] : null
              }
              style={{ margin: 0 }}
            >
              <Select
                value={itemState['property_ids']}
                mode="multiple"
                onChange={value =>
                  onInputChange({ target: { name: 'property_ids', value } })
                }
                placeholder="Select Options"
                notFoundContent={fetchingTags ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={setTagSearchKeyword}
                showSearch
              >
                {tagsDataOption &&
                  tagsDataOption.map(d => (
                    <Option key={d.id} value={d.id}>
                      {d.title}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        )}
        {/* <StyledFormItem label="Assign Vendor" style={{ marginTop: 20 }}>
            <Select
              value={itemState.vendor_id}
              onChange={(value) =>
                onInputChange({
                  target: { name: "vendor_id", value },
                })
              }
              placeholder="select vendor"
              notFoundContent={vendorsloading ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={setVendorSearchKeyword}
              showSearch
            >
              {vendors &&
              vendors.map((vendor) => (
                <Select.Option key={vendor.id} value={vendor.id}>
                  {vendor.id + " : " + vendor.vendor_name}
                </Select.Option>
              ))}
            </Select>
          </StyledFormItem> */}
      </div>
    </StyledItemForm>
  );
});

ClassItemForm.displayName = 'ClassItemForm';

ClassItemForm.propTypes = {
  initialState: PropTypes.object,
  hasOptions: PropTypes.bool,
  setTagSearchKeyword: PropTypes.func,
  fetchingTags: PropTypes.bool,
  tagsDataOption: PropTypes.array,
  setFormStatus: PropTypes.func,
  updateCreateTagForm: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  onInputChange: PropTypes.func,
  selected: PropTypes.string,
  index: PropTypes.number,
  handleRemoveItem: PropTypes.func,
};

export default React.memo(ClassItemForm);
