import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Select, DatePicker, Form, Spin, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DataTable from '../../../components/DataTable';
import CustomerInfo from '../components/CustomerInfo';
import { ReactComponent as VendorIcon } from '../../../assets/icons/ic_domain_uni_color.svg';
import { ReactComponent as TableIcon } from '../../../assets/icons/ic_table.svg';
import {
  customerTableColumns,
  workerTableColumns,
  groupedWorkerTable,
  workerDailyTableColumns,
} from './tableColumns';
import {
  getReportCustomers,
  // getReportWorkers,
  getReportGroupedWorkers,
  resetReportsData,
  getReportWorkerDaily,
} from '../reducers';
import { Formik } from 'formik';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';
import SelectClient from '../../../components/SelectClient';
import SelectVendor from '../../../components/SelectVendor';
import SelectVendorWorker from '../../../components/SelectVendorWorker';
import { generateRateStar, statusLabels } from './tableColumns';
import './style.css';

const { Option } = Select;
const clientType = 'personal';

const ReportTable = () => {
  const dispatch = useDispatch();
  const {
    activeTab,
    meta,
    loading,
    customerDetails,
    // workerDetails,
    groupedWorkerDetails,
    workerDailyDetails,
    clients,
  } = useSelector(store => store['reports']);

  const [selectedClient, updateSelectedClient] = useState(null);
  const [selectedVendor, updateSelectedVendor] = useState(null);
  const [selectedWorker, updateSelectedWorker] = useState(null);
  const [clientAddressList, updateClientAddressList] = useState([]);
  let formRef = null;

  useEffect(() => {
    updateClientAddressList([]);
    dispatch(resetReportsData());
  }, [dispatch]);

  const tableColumns = () => {
    if (activeTab === 'customer') {
      return customerTableColumns();
    } else if (activeTab === 'worker') {
      // return workerTableColumns();
      return groupedWorkerTable();
    } else {
      return workerDailyTableColumns();
    }
  };

  useEffect(() => {
    formRef.resetForm();
    dispatch(resetReportsData());
    updateClientAddressList([]);
  }, [dispatch, formRef, activeTab]);

  let dataSource = null;
  let jobsOrWorkers = [];

  if (customerDetails) {
    dataSource = customerDetails;
    jobsOrWorkers = customerDetails['jobs'];
  }
  // else if (workerDetails) {
  //   dataSource = workerDetails;
  //   jobsOrWorkers = workerDetails['jobs'];
  // }
  else if (groupedWorkerDetails) {
    dataSource = groupedWorkerDetails;
    jobsOrWorkers = groupedWorkerDetails['jobs']['grouped_worker_details'];
  } else if (workerDailyDetails && activeTab === 'department-daily') {
    dataSource = workerDailyDetails;
    jobsOrWorkers = workerDailyDetails['workers'];
  }
  const _getInitailFilterValues = () => {
    if (activeTab === 'customer') {
      return { ...formInitialValues['reportCustomerForm'] };
    } else if (activeTab === 'worker') {
      return { ...formInitialValues['reportWorkerForm'] };
    } else {
      return { ...formInitialValues['reportWorkerDailyForm'] };
    }
  };

  const _getValidationSchema = () => {
    if (activeTab === 'customer') {
      return formValidations['ReportCustomerFormValidation'];
    } else if (activeTab === 'worker') {
      return formValidations['ReportWorkerFormValidation'];
    } else {
      return formValidations['ReportWorkerDailyFormValidation'];
    }
  };

  const handleOnSubmit = (values, pageQuery) => {
    if (activeTab === 'customer') {
      dispatch(
        getReportCustomers({
          ...values,
          client_type: clientType,
          selectedClient,
          per: pageQuery ? pageQuery['per'] : 20,
          page: pageQuery ? pageQuery['page'] : 1,
        })
      );
    } else if (activeTab === 'worker') {
      // dispatch(
      //   getReportWorkers({
      //     ...values,
      //     selectedVendor,
      //     selectedWorker,
      //     per: pageQuery ? pageQuery['per'] : 20,
      //     page: pageQuery ? pageQuery['page'] : 1,
      //   })
      // );
      dispatch(
        getReportGroupedWorkers({
          ...values,
          selectedVendor,
          selectedWorker,
          per: pageQuery ? pageQuery['per'] : 20,
          page: pageQuery ? pageQuery['page'] : 1,
        })
      );
    } else if (activeTab === 'department-daily') {
      dispatch(
        getReportWorkerDaily({
          ...values,
          selectedVendor,
          per: pageQuery ? pageQuery['per'] : 20,
          page: pageQuery ? pageQuery['page'] : 1,
        })
      );
    }
  };

  const handleUpdateClientAddresses = (value, setFieldValue) => {
    const selClient = clients.filter(client => client['id'] === value);
    updateClientAddressList([]);
    if (
      selClient['length'] &&
      selClient[0]['address_books'] &&
      selClient[0]['address_books']['length']
    ) {
      updateClientAddressList(selClient[0]['address_books']);
    }
    setFieldValue('address_ids', []);
    updateSelectedClient(selClient);
  };

  const handleTableChange = pagination => {
    handleOnSubmit(formRef['values'], {
      page: pagination['current'],
      per: pagination['pageSize'],
    });
  };

  const expandedRowRender = record => {
    const columns = [
      {
        title: 'ID',
        key: 'id',
        render: function jobId(text, record) {
          return <span>{record['id']}</span>;
        },
      },
      {
        title: 'Status',
        key: 'job_status',
        render: function Status(_, record) {
          return (
            <span style={{ color: statusLabels[record.job_status].color }}>
              {statusLabels[record.job_status].label}
            </span>
          );
        },
      },
      {
        title: 'Job Title',
        key: 'title',
        dataIndex: 'title',
      },
      {
        title: 'Customer Name',
        key: 'customer_name',
        render: (text, record) =>
          record['customer_name'] ? record['customer_name'] : '-',
      },
      {
        title: 'Customer Address',
        key: 'address',
        render: (text, record) => (record['address'] ? record['address'] : '-'),
      },
      {
        title: 'Unit',
        key: 'unit',
        render: (text, record) => (record['unit'] ? record['unit'] : '-'),
      },
      {
        title: 'Postal Code',
        key: 'postal_code',
        render: (text, record) =>
          record['postal_code'] ? record['postal_code'] : '-',
      },
      {
        title: 'Service Duration',
        key: 'job_duration_in_minutes',
        width: 120,
        render: (_, record) =>
          record['job_duration_in_minutes']
            ? record['job_duration_in_minutes'] + ' min'
            : '-',
      },
      {
        title: 'Check In',
        key: 'checkin_time',
        render: (_, record) =>
          record.check_in_time ? record.check_in_time : '-',
      },
      {
        title: 'Check Out',
        key: 'checkout_time',
        render: (_, record) =>
          record.check_out_time ? record.check_out_time : '-',
      },
      {
        title: 'Active Job Duration',
        width: 130,
        render: (_, record) => {
          if (record?.check_in_time && record?.check_out_time) {
            return record['active_duration_in_minutes'] + ' min';
          } else {
            return '-';
          }
        },
      },
      {
        title: 'Job Notes',
        key: 'job_notes',

        render: (_, record) =>
          record['job_notes']
            ? `${
                record['job_notes']['length'] > 25
                  ? record['job_notes'].slice(0, 25) + '...'
                  : record['job_notes']
              }`
            : '-',
      },
      {
        title: 'Customer Notes',
        key: 'customer_notes',
        width: 120,
        render: (_, record) =>
          record['customer_notes']
            ? `${
                record['customer_notes']['length'] > 25
                  ? record['customer_notes'].slice(0, 25) + '...'
                  : record['customer_notes']
              }`
            : '-',
      },
      {
        title: 'Rating',
        key: 'rating',
        dataIndex: 'rating',
        width: 60,
        render: function Rating(_, record) {
          return record['job_rating'] !== null ? (
            <div style={{ width: '60px', textAlign: 'center' }}>
              {generateRateStar(record['job_rating'])}
            </div>
          ) : (
            ''
          );
        },
      },
      {
        title: 'Rating Feedback',
        key: 'rating_feedback',
        render: (_, record) =>
          record['rating_feedback'] !== null ? record['rating_feedback'] : '',
      },
      {
        title: 'Repeating',
        key: 'job_repeating',
        render: (_, record) => {
          if (record['repeating_job_id']) {
            return <span style={{ color: '#059c5a' }}>Yes</span>;
          } else {
            return <span style={{ color: '#DF5E67' }}>No</span>;
          }
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={record.jobs}
        pagination={false}
        bordered
      />
    );
  };
  const expandedGroupedWorker = record => {
    return (
      <Table
        columns={workerTableColumns()}
        dataSource={record.jobs}
        pagination={false}
        bordered
      />
    );
  };

  return (
    <>
      <Formik
        initialValues={_getInitailFilterValues()}
        validationSchema={_getValidationSchema()}
        onSubmit={handleOnSubmit}
        innerRef={formikRef => {
          formRef = formikRef;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form className="filters-container" onSubmit={handleSubmit}>
              {activeTab === 'worker' || activeTab === 'department-daily' ? (
                <Form.Item
                  hasFeedback
                  validateStatus={
                    touched['client_id'] && errors['vendor_id'] ? 'error' : null
                  }
                  help={touched['client_id'] && errors['vendor_id']}
                  style={{ margin: 0, minWidth: 300 }}
                >
                  <SelectVendor
                    customProps={{
                      className: 'filter-search vendor-select',
                      style:
                        activeTab === 'department-daily'
                          ? { marginRight: 0 }
                          : {},
                      placeholder: 'Select Vendor',
                      suffixIcon: <VendorIcon />,
                      disabled: loading,
                    }}
                    value={values['vendor_id']}
                    loading={loading}
                    handleOnChange={vendor => {
                      handleChange({
                        target: { name: 'vendor_id', value: vendor['id'] },
                      });
                      handleChange({
                        target: { name: 'worker_id', value: undefined },
                      });
                      updateSelectedVendor(vendor);
                    }}
                  />
                </Form.Item>
              ) : null}
              {activeTab === 'worker' && (
                <Form.Item
                  hasFeedback
                  validateStatus={
                    touched['client_type'] && errors['worker_id']
                      ? 'error'
                      : null
                  }
                  help={touched['client_type'] && errors['worker_id']}
                  style={{ margin: 0, minWidth: 250, marginLeft: '1em' }}
                >
                  <SelectVendorWorker
                    treeStructure
                    customProps={{
                      className: 'filter-search',
                      placeholder: 'Search Worker Name',
                      suffixIcon: <SearchOutlined />,
                      disabled: loading,
                    }}
                    vendorId={selectedVendor ? selectedVendor['id'] : null}
                    loading={loading}
                    value={values['worker_id']}
                    handleOnChange={worker => {
                      handleChange({
                        target: { name: 'worker_id', value: worker['id'] },
                      });
                      updateSelectedWorker(worker);
                    }}
                  />
                </Form.Item>
              )}
              {activeTab === 'customer' && (
                <>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['client_id'] && errors['client_id']
                        ? 'error'
                        : null
                    }
                    help={touched['client_id'] && errors['client_id']}
                    style={{ margin: 0, minWidth: 300 }}
                  >
                    <SelectClient
                      customProps={{
                        className: 'filter-search',
                        placeholder: 'Search Customer Name',
                        suffixIcon: <SearchOutlined />,
                        disabled: loading,
                      }}
                      clientType={clientType}
                      value={values['client_id']}
                      handleOnChange={client => {
                        handleUpdateClientAddresses(
                          client['id'],
                          setFieldValue
                        );
                        handleChange({
                          target: { name: 'client_id', value: client['id'] },
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['address_ids'] && errors['address_ids']
                        ? 'error'
                        : null
                    }
                    help={touched['address_ids'] && errors['address_ids']}
                    style={{ margin: 0, minWidth: 300 }}
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      id="reportable-customer"
                      placeholder="Search Customer Address"
                      optionFilterProp="children"
                      className="filter-search client-address-select"
                      value={values['address_ids']}
                      onChange={value =>
                        handleChange({ target: { name: 'address_ids', value } })
                      }
                      suffixIcon={<SearchOutlined />}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      loading={loading}
                      disabled={loading}
                    >
                      {clientAddressList.map(address => (
                        <Option key={address['id']} value={address['id']}>
                          {address['address']}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              )}
              {activeTab === 'department-daily' ? (
                <Form.Item
                  hasFeedback
                  validateStatus={
                    touched['date'] && errors['date'] ? 'error' : null
                  }
                  help={touched['date'] && errors['date']}
                  style={{ margin: 0, padding: '14px 15px !important' }}
                >
                  <DatePicker
                    className="date-picker "
                    id="dept-daily"
                    // size="large"
                    // style={{padding:'14px 15px !important'}}
                    placeholder="Date"
                    value={values['date']}
                    format="DD-MM-YYYY"
                    onChange={value =>
                      handleChange({ target: { name: 'date', value } })
                    }
                    loading={loading}
                    disabled={loading}
                  />
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['start_date'] && errors['start_date']
                        ? 'error'
                        : null
                    }
                    help={touched['start_date'] && errors['start_date']}
                    style={{
                      margin: 0,
                      marginLeft: activeTab === 'worker' ? 0 : '1em',
                    }}
                  >
                    <DatePicker
                      className="date-picker"
                      id="reportable-startdate"
                      placeholder="Start Date"
                      value={values['start_date']}
                      format="DD-MM-YYYY"
                      onChange={value =>
                        handleChange({ target: { name: 'start_date', value } })
                      }
                      loading={loading}
                      disabled={loading}
                    />
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['end_date'] && errors['end_date'] ? 'error' : null
                    }
                    help={touched['end_date'] && errors['end_date']}
                    style={{ margin: 0 }}
                  >
                    <DatePicker
                      className="date-picker"
                      id="reportable-enddate"
                      placeholder="End Date"
                      value={values['end_date']}
                      format="DD-MM-YYYY"
                      onChange={value =>
                        handleChange({ target: { name: 'end_date', value } })
                      }
                      loading={loading}
                      disabled={loading}
                    />
                  </Form.Item>
                </>
              )}
              <Button
                className="filter-search-btn"
                id="reportTable-search"
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Search
              </Button>
            </form>
          );
        }}
      </Formik>
      {dataSource ? (
        <Card
          className="overflow-x-auto"
          title={
            <CustomerInfo
              data={dataSource}
              activeTab={activeTab}
              handleOnSubmit={handleOnSubmit}
            />
          }
        >
          <DataTable
            rowKey={record =>
              record['id'] || record['worker_id'] || record['service_date']
            }
            loading={loading}
            data={jobsOrWorkers}
            columns={tableColumns()}
            pagination={{
              limit: meta ? meta['per_page'] : 20,
              total: meta ? meta['total_count'] : 0,
              page: meta ? meta['page'] : 1,
              data: jobsOrWorkers,
            }}
            handleTableChange={handleTableChange}
            activeTab={activeTab}
            expandedRowRender={
              activeTab === 'department-daily'
                ? expandedRowRender
                : activeTab === 'worker'
                ? expandedGroupedWorker
                : ''
            }
          />
        </Card>
      ) : (
        <div className="table-no-data-container">
          <div className="no-data-icon-container">
            {loading ? (
              <Spin className="no-data-icon" />
            ) : (
              <TableIcon className="no-data-icon" />
            )}
          </div>
          <div className="no-data-message-container">
            {loading ? (
              <p className="no-data-message">...fetching data</p>
            ) : (
              <>
                <p className="no-data-message">
                  There’s no data to be shown right now.
                </p>
                <p className="no-data-message">Start Searching</p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

ReportTable.propTypes = {
  activeTab: PropTypes.string,
  history: PropTypes.object,
  push: PropTypes.func,
};

export default withRouter(React.memo(ReportTable));
