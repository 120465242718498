import {
  CloseOutlined,
  LeftOutlined,
  PlusOutlined,
  ProfileOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  LoadingOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Drawer,
  Input,
  Modal,
  Select,
  // TimePicker,
  Checkbox,
  Radio,
  Form,
  TreeSelect,
  Tag,
  message,
  Row,
  Col,
} from 'antd';
import ReactTimePicker from '../../components/TimePicker';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dig } from 'digdata';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import PropTypes from 'prop-types';

import RolePermissions from './rolerPermissions';
const DataTable = lazy(() => import('../../components/DataTable'));
import { getImageURI, capitalize } from '../../utils/apisauce';
import {
  getVendor,
  getMedia,
  createUser,
  updateUser,
  deleteUser,
  getVendorUsers,
} from './reducers';
import { impersonateLogin } from '../../Vendor/pages/AuthPage/reducers';
import './styles.css';
import AvatarUpload from '../../components/AvatarUpload';
import { Formik } from 'formik';
import { formInitialValues } from '../../utils/formsInitialValues';
import { formValidations } from '../../utils/formValidations';
import {
  EditFilledIcon,
  DeleteFilledIcon,
  EyeFilledIcon,
} from '../../components/Icons/Icons';
import SideBarMenuList from '../../vendor-routes';

const { SHOW_PARENT } = TreeSelect;
let treeData = [
  {
    title: 'All',
    value: 'all',
    key: 'all',
    children: [],
  },
];

const defaultTime = () => {
  return moment().startOf('day').format('HH:mm');
};

class VendorProfilePage extends React.Component {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: null,
    showPassword: false,
    activeTab: 'active',
    pageQuery: {
      page: 1,
      per: 50,
      query: null,
    },
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getVendor,
      getVendorUsers,
      getMedia,
    } = this.props;

    getMedia();
    getVendor(id);
    getVendorUsers({ id, activeTab: 'active' });
  }

  componentDidUpdate(prevProps) {
    const { submitting, error } = this.props;

    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  onClickBack = () => {
    const {
      history: { push },
    } = this.props;

    push('/admin/vendors');
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
    });
  };

  onClickRow = (e, record) => {
    this.setState({
      modalOpened: 1,
      selectedRow: record,
      formData: null,
    });
  };

  onCreate = () => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: {
        picture: null,
        first_name: '',
        last_name: '',
        nric: '',
        mobile: '',
        email: '',
        country: 'singapore',
        postal_code: '',
        role: 'worker',
        status: 'active',
        start_time: defaultTime(),
        end_time: defaultTime(),
        password: '',
      },
    });
  };

  onEdit = record => {
    let role_name, selected_tabs;
    if (record && record['role_permission']) {
      role_name = record['role_permission']['name'];

      if (record['role_permission']['permissions']) {
        selected_tabs = Object.keys(record['role_permission']['permissions']);
      }
    }
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: {
        id: record.id,
        first_name: record.first_name,
        last_name: record.last_name,
        nric: record.nric,
        mobile: record.mobile,
        email: record.email,
        country: record.country,
        postal_code: record.postal_code,
        status: record.status,
        role: record.role,
        role_name: role_name,
        selected_tabs: selected_tabs,
        start_time: record.start_time,
        end_time: record.end_time,
        picture_path: record.picture_path,
        password: '',
        image_type: record.image_type,
        skill_certificate: record.skill_certificate,
      },
    });
  };

  onEditSkill = record => {
    this.setState({
      modalOpened: 3,
      selectedRow: null,
      formData: {
        id: record.id,
        skill_certificate: record.skill_certificate,
      },
    });
  };

  onDelete = record => {
    this.setState({
      modalOpened: 2,
      selectedRow: record,
      formData: null,
    });
  };

  onSubmit = values => {
    const val = { ...values };
    if (val?.role) val.role = val.role.toString();
    values = val;
    const {
      match: {
        params: { id },
      },
      updateUser,
      createUser,
    } = this.props;
    const { formData, activeTab } = this.state;

    let payload_ = {};
    let permissions = {};
    values['selected_tabs']?.length > 0 &&
      values['selected_tabs'].map(selectedTab => {
        permissions[selectedTab] = {
          actions: ['view'],
        };
        return null;
      });
    payload_ = {
      name: values['role_name'],
      permissions,
    };
    const { image_type } = values;
    const { picture } = formData;
    const userData = { ...values };
    const { mediaImageId, ...user } = userData;

    if (image_type === 'attached') {
      user['picture'] = picture;
    } else if (mediaImageId === undefined) {
      user['attach_library_image_attributes'] = {
        library_image_id: formData?.picture_path?.library_image_id,
      };
    } else {
      user['attach_library_image_attributes'] = {
        library_image_id: mediaImageId,
      };
    }
    if (formData.id) {
      updateUser({
        vendor_id: id,
        vendor_user: user,
        role_permission: values.role === 'worker' ? null : payload_,
        activeTab,
      });
    } else {
      createUser({
        vendor_id: id,
        vendor_user: user,
        role_permission: values.role === 'worker' ? null : payload_,
        activeTab,
      });
    }
  };

  performDelete = () => {
    const { selectedRow, activeTab } = this.state;
    const {
      match: {
        params: { id },
      },
      deleteUser,
    } = this.props;

    deleteUser(id, selectedRow.id, activeTab);
    this.closeModal();
  };

  updateSkill = () => {
    const {
      match: {
        params: { id },
      },
      updateUser,
    } = this.props;
    const { formData, activeTab } = this.state;
    updateUser({
      vendor_id: id,
      vendor_user: {
        id: formData.id,
        skill_certificate: formData.skill_certificate,
      },
      activeTab,
    });
    this.closeModal();
  };

  skillChange = value => {
    this.setState({
      formData: {
        ...this.state.formData,
        skill_certificate: value,
      },
    });
  };

  onInputChange = ({ target: { name, value } }) => {
    const formData = { ...this.state.formData };

    formData[name] = value;
    this.setState({ formData });
  };

  onImpersonate = record => {
    const {
      match: {
        params: { id },
      },
      impersonateLogin,
    } = this.props;
    impersonateLogin({
      vendorId: id,
      userId: record.id,
    });
    setTimeout(() => {
      window.location.replace('/vendor/login');
    }, 1200);
  };

  tableColumns = () => [
    {
      title: 'Employee ID',
      dataIndex: 'id',
      key: 'id',
      width: 110,
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Image',
      key: 'image',
      fixed: 'left',
      width: 100,
      render: (text, record) => (
        <Avatar
          src={getImageURI(dig(record, 'picture_path.url'))}
          icon={<UserOutlined />}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 130,
      render: (text, record) =>
        `${capitalize(record.first_name)} ${capitalize(record.last_name)}`,
    },
    {
      title: 'Phone',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Skill / Certificate',
      dataIndex: 'skillCertificate',
      key: 'skillCertificate',
      width: 300,
      render: (_, record) =>
        record?.skill_certificate?.length > 0
          ? record?.skill_certificate.map((skillTag, i) => (
              <Tag key={i} className="permission-tab">
                {skillTag}
              </Tag>
            ))
          : '-',
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '40',
      align: 'center',
      render: (text, record) =>
        `${
          record.role === 'admin'
            ? 'Admin'
            : record.role === 'worker'
            ? 'Worker'
            : 'Admin+Worker'
        }`,
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      render: (_, record) => {
        const colorVal = {
          active: '#6ABB98',
          inactive: '#F1B13B',
          suspended: '#DF5E67',
        };
        return (
          <span
            style={{
              color: colorVal[record.status],
              textTransform: 'capitalize',
            }}
          >
            {record.status}
          </span>
        );
      },
    },
    {
      title: 'Impersonate',
      key: 'impersonate',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            className="ant-table-action-cell"
            style={{ justifyContent: 'center' }}
          >
            {(record.role === 'admin' || record.role === 'admin_worker') &&
            record.status === 'active' ? (
              <EyeFilledIcon
                onClick={e =>
                  e.stopPropagation() ||
                  Object.keys(record.role_permission?.permissions).length > 0
                    ? this.onImpersonate(record)
                    : message.error('User does not have any tab permission')
                }
              />
            ) : (
              <EyeInvisibleOutlined />
            )}
          </div>
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      wdith: 100,
      render: (text, record) => (
        <div className="ant-table-action-cell">
          <EditFilledIcon
            onClick={e => e.stopPropagation() || this.onEdit(record)}
          />
          <SafetyCertificateOutlined
            onClick={e => e.stopPropagation() || this.onEditSkill(record)}
          />
          {record.status !== 'active' ? (
            ''
          ) : (
            <DeleteFilledIcon
              onClick={e => e.stopPropagation() || this.onDelete(record)}
            />
          )}
        </div>
      ),
    },
  ];

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };
  setActiveTab = value => {
    const {
      match: {
        params: { id },
      },
      getVendorUsers,
    } = this.props;
    this.setState(state => ({ ...state, activeTab: value }));
    getVendorUsers({ activeTab: value, id });
  };
  render() {
    const { vendor, loading, submitting, vendor_users, libraryImage } =
      this.props;
    const { modalOpened, selectedRow, formData, pageQuery, showPassword } =
      this.state;
    let permittedTabs = [];

    if (selectedRow && 'role_permission' in selectedRow) {
      const { role_permission } = selectedRow;
      if (role_permission && role_permission['permissions']) {
        const tabsList = Object.keys(role_permission['permissions']);
        permittedTabs = tabsList.map(tab => tab.replace(/_/g, ' '));
      }
    }

    const FilteredSideBarMenuList = SideBarMenuList.filter(el => el['menu']);

    if (FilteredSideBarMenuList && FilteredSideBarMenuList.length) {
      let childList = [];
      FilteredSideBarMenuList.map(item => {
        const value = `${item['menu']['name']
          .replace(/ /g, '_')
          .toLocaleLowerCase()}_tab`;
        const tab_name = item['menu']['name'];
        childList.push({
          title: tab_name,
          value: value,
          key: value,
        });
        return null;
      });
      treeData[0]['children'] = childList;
    }

    return (
      <div className="vendor-profile-page">
        <div className="page-header">
          <div className="page-title">
            <LeftOutlined onClick={this.onClickBack} />
            <h4>Vendor Details</h4>
          </div>

          <div className="page-header-actions">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.onCreate}
            >
              Add New Worker
            </Button>
          </div>
        </div>
        <div className="page-content">
          {vendor && (
            <div className="vendor-info">
              <div className="vendor-avatar">
                <div className="vendor-avatar-pic">
                  <Avatar
                    src={getImageURI(dig(vendor, 'picture_path.url'))}
                    icon={<ProfileOutlined />}
                    size={80}
                  />
                </div>
                <div className="vendor-avatar-info">
                  <div className="vendor-name">{vendor.vendor_name}</div>
                  <div className="vendor-id">Vendor ID: {vendor.id}</div>
                </div>
              </div>
              <div className="vendor-info-block">
                <label>Registration No.</label>
                <span>{vendor.company_reg}</span>
              </div>
              <div className="vendor-info-block">
                <label>Phone</label>
                <span>{vendor.contact}</span>
              </div>
              <div className="vendor-info-block">
                <label>URL</label>
                <span>{vendor.website}</span>
              </div>
              <div className="vendor-info-block">
                <label>Total Workers</label>
                <span style={{ textAlign: 'center' }}>
                  {vendor_users.length}
                </span>
              </div>
            </div>
          )}
          <div className="jobs-page">
            <div className="page-header">
              <Row>
                <Col>
                  <div className="ant-tabs-nav ant-tabs-nav-animated">
                    <div
                      className={`ant-tabs-tab${
                        this.state.activeTab === 'active'
                          ? ' ant-tabs-tab-active '
                          : ''
                      }`}
                      onClick={() => this.setActiveTab('active')}
                    >
                      Active
                    </div>
                    <div
                      className={`ant-tabs-tab${
                        this.state.activeTab === 'inactive'
                          ? ' ant-tabs-tab-active '
                          : ''
                      }`}
                      onClick={() => this.setActiveTab('inactive')}
                    >
                      Inactive
                    </div>
                    <div
                      className={`ant-tabs-tab${
                        this.state.activeTab === 'suspended'
                          ? ' ant-tabs-tab-active '
                          : ''
                      }`}
                      onClick={() => this.setActiveTab('suspended')}
                    >
                      Suspended
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Card>
              <Suspense
                fallback={<LoadingOutlined className="fallback-loader" />}
              >
                <DataTable
                  rowKey={record => record['id']}
                  loading={loading}
                  data={vendor_users}
                  columns={this.tableColumns()}
                  onClickRow={this.onClickRow}
                  pagination={{
                    limit: pageQuery['per'],
                    total: vendor_users['length'],
                    page: pageQuery['page'],
                    data: vendor_users,
                    onChange: this.onPageChange,
                  }}
                  handleTableChange={this.handleTableChange}
                />
              </Suspense>
            </Card>
          </div>
          <Drawer
            closable={false}
            placement="right"
            width={450}
            visible={modalOpened === 1}
            onClose={this.closeModal}
          >
            {selectedRow ? (
              <>
                <div className="drawer-header">
                  <h3>Worker Detail</h3>
                  <div className="header-actions">
                    <EditFilledIcon
                      className="action-primary"
                      onClick={() => this.onEdit(selectedRow)}
                    />
                    <CloseOutlined onClick={this.closeModal} />
                  </div>
                </div>
                <div className="drawer-body">
                  <div className="user-avatar">
                    <Avatar
                      src={getImageURI(dig(selectedRow, 'picture_path.url'))}
                      icon={<UserOutlined />}
                      size={100}
                    />
                    <div className="username">
                      {selectedRow.first_name}&nbsp;{selectedRow.last_name}
                    </div>
                    <div className="user-status">
                      <span className={`dot ${selectedRow.status}`} />
                      <span>{selectedRow.status}</span>
                    </div>
                  </div>
                  <div className="user-info-row">
                    <label>Employee ID</label>
                    <span>{selectedRow.id}</span>
                  </div>
                  <div className="user-info-row">
                    <label>Vendor</label>
                    <span>{vendor.vendor_name}</span>
                  </div>
                  <div className="user-info-row">
                    <label>NRIC /FIN</label>
                    <span>{selectedRow.nric}</span>
                  </div>
                  <div className="user-info-row">
                    <label>Phone Number</label>
                    <span>{selectedRow.mobile}</span>
                  </div>
                  <div className="user-info-row">
                    <label>Email Address</label>
                    <span>{selectedRow.email}</span>
                  </div>
                  <div className="user-info-row">
                    <label>Working Hours</label>
                    <span>{`${selectedRow['start_time']} to ${selectedRow['end_time']}`}</span>
                  </div>
                  <div className="user-info-row">
                    <label>Tab Permissions</label>
                    <div className="permission-tabs-container">
                      {permittedTabs.map((permittedTab, i) => (
                        <Tag key={i} className="permission-tab">
                          {permittedTab}
                        </Tag>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              formData && (
                <>
                  <div className="drawer-header">
                    <h3>{formData.id ? 'Edit Worker' : 'Add Worker'}</h3>
                    <div className="header-actions">
                      {formData.id && (
                        <DeleteFilledIcon
                          className="action-primary"
                          onClick={() => this.onDelete(formData)}
                        />
                      )}
                      <CloseOutlined onClick={this.closeModal} />
                    </div>
                  </div>
                  <div className="drawer-body">
                    <Formik
                      initialValues={{
                        ...formInitialValues['createVendorUserForm'],
                        ...formData,
                      }}
                      validationSchema={
                        formData.id
                          ? formValidations['UpdateVendorUserFormValidation']
                          : formValidations['CreateVendorUserFormValidation']
                      }
                      onSubmit={values => this.onSubmit(values)}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                      }) => {
                        const tProps = {
                          treeData,
                          value:
                            values.selected_tabs === null
                              ? undefined
                              : values.selected_tabs,
                          onChange: value => {
                            handleChange({
                              target: { name: 'selected_tabs', value },
                            });
                          },

                          treeCheckable: true,
                          showCheckedStrategy: SHOW_PARENT,
                          placeholder: 'Select Tab(s)',
                          style: {
                            width: '100%',
                          },
                        };
                        return (
                          <form onSubmit={handleSubmit}>
                            <Radio.Group
                              defaultValue={values.image_type}
                              onChange={handleChange}
                              name="image_type"
                              buttonStyle="solid"
                              style={{ marginBottom: '10px' }}
                            >
                              <Radio.Button
                                name="image_type"
                                value={'attached'}
                              >
                                Upload Image
                              </Radio.Button>
                              <Radio.Button name="image_type" value={'library'}>
                                Choose From Library
                              </Radio.Button>
                            </Radio.Group>
                            {values.image_type === 'attached' ? (
                              <div className="user-avatar">
                                <AvatarUpload
                                  value={
                                    formData['picture_path']
                                      ? getImageURI(
                                          dig(formData, 'picture_path.url')
                                        )
                                      : null
                                  }
                                  icon={<UserOutlined />}
                                  onChange={value =>
                                    this.onInputChange({
                                      target: {
                                        name: 'picture',
                                        value: { data: value },
                                      },
                                    })
                                  }
                                  accept=".png, .jpg, .jpeg"
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  height: '250px',
                                  overflowY: 'scroll',
                                  textAlign: 'center',
                                }}
                              >
                                <Radio.Group
                                  onChange={handleChange}
                                  size="large"
                                  defaultValue={
                                    formData['picture_path']?.library_image_id
                                  }
                                  name="mediaImageId"
                                >
                                  {libraryImage?.map(item => (
                                    <Radio
                                      key={item?.id}
                                      className="radioImage"
                                      name="mediaImageId"
                                      value={item?.id}
                                    >
                                      <Card>
                                        <Avatar
                                          src={getImageURI(
                                            item?.image_path?.url
                                          )}
                                          size={100}
                                        />
                                      </Card>
                                    </Radio>
                                  ))}
                                </Radio.Group>
                              </div>
                            )}
                            {formData.id && (
                              <div className="form-group">
                                <label>Employee ID</label>
                                <span>{formData.id}</span>
                              </div>
                            )}
                            <div className="form-group">
                              <label>First Name</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['first_name'] && errors['first_name']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['first_name'] && errors['first_name']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="first_name"
                                  value={values.first_name}
                                  placeholder="Enter first name"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Last Name</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['last_name'] && errors['last_name']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['last_name'] && errors['last_name']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="last_name"
                                  value={values.last_name}
                                  placeholder="Enter last name"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>NRIC/FIN</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['nric'] && errors['nric']
                                    ? 'error'
                                    : null
                                }
                                help={touched['nric'] && errors['nric']}
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="nric"
                                  value={values.nric}
                                  placeholder="Enter NRIC number"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Phone Number</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['mobile'] && errors['mobile']
                                    ? 'error'
                                    : null
                                }
                                help={touched['mobile'] && errors['mobile']}
                                style={{ margin: 0 }}
                              >
                                <PhoneInput
                                  defaultCountry={'sg'}
                                  inputStyle={{ width: '100%' }}
                                  value={values.mobile}
                                  onChange={value =>
                                    handleChange({
                                      target: { name: 'mobile', value },
                                    })
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Email</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['email'] && errors['email']
                                    ? 'error'
                                    : null
                                }
                                help={touched['email'] && errors['email']}
                                style={{ margin: 0 }}
                              >
                                <Input
                                  type="email"
                                  name="email"
                                  value={values.email}
                                  placeholder="Enter email"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Postal Code</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['postal_code'] &&
                                  errors['postal_code']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['postal_code'] &&
                                  errors['postal_code']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="postal_code"
                                  value={values.postal_code}
                                  placeholder="Enter postal code"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Status</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['status'] && errors['status']
                                    ? 'error'
                                    : null
                                }
                                help={touched['status'] && errors['status']}
                                style={{ margin: 0 }}
                              >
                                <Select
                                  style={{ width: '100%' }}
                                  value={values.status}
                                  onChange={value =>
                                    handleChange({
                                      target: { name: 'status', value },
                                    })
                                  }
                                >
                                  <Select.Option value="active">
                                    Active
                                  </Select.Option>
                                  <Select.Option value="inactive">
                                    Inactive
                                  </Select.Option>
                                  <Select.Option value="suspended">
                                    Suspended
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Skill / Certificate</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['skill_certificate'] &&
                                  errors['skill_certificate']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['skill_certificate'] &&
                                  errors['skill_certificate']
                                }
                                style={{ margin: 0 }}
                              >
                                <Select
                                  mode="tags"
                                  style={{
                                    width: '100%',
                                  }}
                                  onChange={value =>
                                    handleChange({
                                      target: {
                                        name: 'skill_certificate',
                                        value,
                                      },
                                    })
                                  }
                                  placeholder="Add Skill / Certificates"
                                  tokenSeparators={[',']}
                                  value={values.skill_certificate}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Role</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['role'] && errors['role']
                                    ? 'error'
                                    : null
                                }
                                help={touched['role'] && errors['role']}
                                style={{ margin: 0 }}
                              >
                                <Radio.Group
                                  style={{ width: '100%' }}
                                  defaultValue={'admin'}
                                  onChange={e => {
                                    handleChange({
                                      target: {
                                        name: 'role',
                                        value: e.target.value,
                                      },
                                    });
                                    handleChange({
                                      target: {
                                        name: 'selected_tabs',
                                        value: null,
                                      },
                                    });
                                  }}
                                  value={values.role}
                                >
                                  <Radio value={'admin'}>Admin</Radio>
                                  <Radio value={'worker'}>Worker</Radio>
                                  <Radio value={'admin_worker'}>Both</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Work Hours</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['start_time'] && errors['start_time']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['start_time'] && errors['start_time']
                                }
                                style={{ margin: 0 }}
                              >
                                {/* <TimePicker
                                format="HH:mm"
                                style={{ width: '100%' }}
                                value={
                                  values.start_time
                                    ? moment(values.start_time, 'HH:mm')
                                    : moment().startOf('day')
                                }
                                placeholder="Pick start time"
                                onChange={val => {
                                  handleChange({
                                    target: {
                                      name: 'start_time',
                                      value: val.format('HH:mm'),
                                    },
                                  });
                                }}
                              /> */}
                                <ReactTimePicker
                                  name="start_time"
                                  item={values}
                                  cahngeFormTime={(item, timeString) => {
                                    handleChange({
                                      target: {
                                        name: 'start_time',
                                        value: timeString,
                                      },
                                    });
                                  }}
                                  placeholder="Pick start time"
                                  showMinutes
                                />
                              </Form.Item>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['end_time'] && errors['end_time']
                                    ? 'error'
                                    : null
                                }
                                help={touched['end_time'] && errors['end_time']}
                                style={{ marginTop: '10px' }}
                              >
                                {/* <TimePicker
                                format="HH:mm"
                                style={{ width: '100%', marginTop: '15px' }}
                                value={
                                  values.end_time
                                    ? moment(values.end_time, 'HH:mm')
                                    : moment().startOf('day')
                                }
                                placeholder="Pick end time"
                                onChange={val =>
                                  handleChange({
                                    target: {
                                      name: 'end_time',
                                      value: val.format('HH:mm'),
                                    },
                                  })
                                }
                              /> */}
                                <ReactTimePicker
                                  name="end_time"
                                  item={values}
                                  cahngeFormTime={(item, timeString) => {
                                    handleChange({
                                      target: {
                                        name: 'end_time',
                                        value: timeString,
                                      },
                                    });
                                  }}
                                  placeholder="Pick end time"
                                  showMinutes
                                />
                              </Form.Item>
                            </div>
                            {formData.id && (
                              <Checkbox
                                style={{ margin: '1em 0' }}
                                checked={showPassword}
                                onChange={() =>
                                  this.setState({ showPassword: !showPassword })
                                }
                              >
                                Change Password
                              </Checkbox>
                            )}
                            {(formData.id && showPassword) || !formData.id ? (
                              <div className="form-group">
                                <label>New Password</label>
                                <Form.Item
                                  hasFeedback
                                  validateStatus={
                                    touched['password'] && errors['password']
                                      ? 'error'
                                      : null
                                  }
                                  help={
                                    touched['password'] && errors['password']
                                  }
                                  style={{ margin: 0 }}
                                >
                                  <Input.Password
                                    name="password"
                                    value={values.password}
                                    placeholder="Set New Password"
                                    onChange={handleChange}
                                  />
                                </Form.Item>
                              </div>
                            ) : null}
                            {(values.role === 'admin_worker' ||
                              values.role === 'admin') && (
                              <RolePermissions
                                values={values}
                                errors={errors}
                                touched={touched}
                                tProps={tProps}
                                handleChange={handleChange}
                                roleName={values.role}
                              />
                            )}

                            <Button
                              type="primary"
                              htmlType="submit"
                              block
                              disabled={submitting}
                              loading={submitting}
                            >
                              Save
                            </Button>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </>
              )
            )}
          </Drawer>
          <Modal
            className="delete-user-modal"
            visible={modalOpened === 2}
            onCancel={this.closeModal}
            onOk={this.performDelete}
            okText="Yes"
            destroyOnClose
          >
            {selectedRow && (
              <>
                <h1 className="main-message pop-up-heading">Are you sure?</h1>
                <p className="pop-up-body">
                  Inactivating this worker will unassign current and future
                  jobs. The process cannot be undone.
                </p>
              </>
            )}
          </Modal>
          <Modal
            className="delete-user-modal"
            visible={modalOpened === 3}
            onCancel={this.closeModal}
            onOk={this.updateSkill}
            okText="Update"
            destroyOnClose
          >
            <div style={{ marginTop: '31px', textAlign: 'left' }}>
              <label>Skill / Certificates</label>
              <Select
                mode="tags"
                style={{
                  width: '100%',
                }}
                onChange={this.skillChange}
                placeholder="Add Skill / Certificates"
                tokenSeparators={[',']}
                value={formData?.skill_certificate}
              />
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

VendorProfilePage.propTypes = {
  loading: PropTypes.bool,
  vendor: PropTypes.object,
  getVendor: PropTypes.func,
  getMedia: PropTypes.func,
  createUser: PropTypes.func,
  updateUser: PropTypes.func,
  deleteUser: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  vendor_users: PropTypes.object,
  getVendorUsers: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.vendor.loading,
    vendor: state.vendor.vendor,
    submitting: state.vendor.submitting,
    error: state.vendor.error,
    libraryImage: state.vendor.media_library_images?.data,
    vendor_users: state.vendor.vendor_users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getVendor: id => dispatch(getVendor(id)),
    getMedia: () => dispatch(getMedia()),
    getVendorUsers: payload => dispatch(getVendorUsers(payload)),
    createUser: data => dispatch(createUser(data)),
    updateUser: data => dispatch(updateUser(data)),
    deleteUser: (vendorId, userId, activeTab) =>
      dispatch(deleteUser({ vendorId, userId, activeTab })),
    impersonateLogin: payload => dispatch(impersonateLogin(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(VendorProfilePage)));
