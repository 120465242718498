import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Drawer, Input, Modal, Select, Form } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DataTable from '../../../components/DataTable';
import { updateUser, deleteUser } from '../reducers';
import AvatarUpload from '../../../components/AvatarUpload';
import PhoneInput from 'react-phone-input-2';
import { Formik } from 'formik';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';
import {
  EditFilledIcon,
  DeleteFilledIcon,
} from '../../../components/Icons/Icons';
import { capitalize } from '../../../utils/apisauce';

class BusinessUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      selectedRow: null,
      formData: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { submitting, error } = this.props;
    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
    });
  };

  onEdit = record => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: { ...record },
    });
  };

  onDelete = record => {
    this.setState({
      modalOpened: 2,
      selectedRow: record,
      formData: null,
    });
  };

  onSubmit = values => {
    const { formData } = this.state;
    const { updateUser, client } = this.props;
    updateUser(client.id, { ...formData, ...values });
    // this.closeModal();
  };

  performDelete = () => {
    const { selectedRow } = this.state;
    const { deleteUser, client } = this.props;

    deleteUser(client.id, selectedRow.id);
    this.closeModal();
  };

  onInputChange = ({ target: { name, value } }) => {
    const formData = { ...this.state.formData };

    formData[name] = value;
    this.setState({ formData });
  };

  tableColumns = () => [
    {
      title: 'User Name',
      key: 'name',
      render: (_, record) =>
        `${capitalize(record.first_name)} ${capitalize(record.last_name)}`,
    },
    {
      title: 'User ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Phone Number',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => {
        const colorVal = {
          active: '#6ABB98',
          inactive: '#F1B13B',
          suspended: '#DF5E67',
        };
        return (
          <span
            style={{
              color: colorVal[record.status],
              textTransform: 'capitalize',
            }}
          >
            {record.status}
          </span>
        );
      },
    },
    {
      title: 'Default Address',
      key: 'default',
      render: function adminStatus(_, record) {
        return (
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {record?.default ? 'yes' : 'no'}
          </span>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div className="ant-table-action-cell">
          <EditFilledIcon
            onClick={e => e.stopPropagation() || this.onEdit(record)}
          />
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || this.onDelete(record)}
          />
        </div>
      ),
    },
  ];

  render() {
    const { modalOpened, selectedRow, formData } = this.state;
    const { client, loading, submitting } = this.props;

    return (
      <>
        <div className="page-content">
          <Card title="Users">
            <DataTable
              rowKey={record => record.id}
              loading={loading}
              data={client['business_users']}
              columns={this.tableColumns()}
            />
          </Card>
        </div>
        <Drawer
          closable={false}
          placement="right"
          width={450}
          visible={modalOpened === 1}
          onClose={this.closeModal}
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>Edit User</h3>
                <div className="header-actions">
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <Formik
                  initialValues={{
                    ...formInitialValues['createPersonalClientForm'],
                    ...formData,
                  }}
                  validationSchema={
                    formValidations['UpdatePersonalClientFormValidation']
                  }
                  onSubmit={values => this.onSubmit(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="user-avatar">
                          <AvatarUpload
                            onChange={value =>
                              this.onInputChange({
                                target: {
                                  name: 'picture',
                                  value: { data: value },
                                },
                              })
                            }
                            accept=".png, .jpg, .jpeg"
                          />
                        </div>
                        <div className="form-group">
                          <label>First Name</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['first_name'] && errors['first_name']
                                ? 'error'
                                : null
                            }
                            help={touched['first_name'] && errors['first_name']}
                            style={{ margin: 0 }}
                          >
                            <Input
                              name="first_name"
                              value={values.first_name}
                              placeholder="Enter first name"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Last Name</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['last_name'] && errors['last_name']
                                ? 'error'
                                : null
                            }
                            help={touched['last_name'] && errors['last_name']}
                            style={{ margin: 0 }}
                          >
                            <Input
                              name="last_name"
                              value={values.last_name}
                              placeholder="Enter last name"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Phone Number</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['mobile'] && errors['mobile']
                                ? 'error'
                                : null
                            }
                            help={touched['mobile'] && errors['mobile']}
                            style={{ margin: 0 }}
                          >
                            <PhoneInput
                              defaultCountry={'sg'}
                              inputStyle={{ width: '100%' }}
                              value={values.mobile}
                              onChange={value =>
                                handleChange({
                                  target: { name: 'mobile', value },
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Email</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['email'] && errors['email']
                                ? 'error'
                                : null
                            }
                            help={touched['email'] && errors['email']}
                            style={{ margin: 0 }}
                          >
                            <Input
                              type="email"
                              name="email"
                              value={values.email}
                              placeholder="Enter email"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Status</label>
                          <Select
                            style={{ width: '100%' }}
                            value={formData.status}
                            onChange={value =>
                              this.onInputChange({
                                target: { name: 'status', value },
                              })
                            }
                          >
                            <Select.Option value="active">Active</Select.Option>
                            <Select.Option value="inactive">
                              Inactive
                            </Select.Option>
                            <Select.Option value="suspended">
                              Suspended
                            </Select.Option>
                          </Select>
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['password'] && errors['password']
                                ? 'error'
                                : null
                            }
                            help={touched['password'] && errors['password']}
                            style={{ margin: 0 }}
                          >
                            <Input.Password
                              name="password"
                              value={values.password}
                              placeholder="set new password"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          disabled={submitting}
                          loading={submitting}
                        >
                          Save
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </>
          )}
        </Drawer>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 2}
          onCancel={this.closeModal}
          onOk={this.performDelete}
          okText="Yes"
          destroyOnClose
        >
          {selectedRow && (
            <>
              <div className="user-info">
                <Avatar icon={<UserOutlined />} size={72} />
                <div className="username">
                  {selectedRow.first_name}&nbsp;{selectedRow.last_name}
                </div>
                <span>User ID:&nbsp;{selectedRow.id}</span>
              </div>
              <p className="main-message">
                Are you sure want to remove this user?
              </p>
              <p>This action can not be undone</p>
            </>
          )}
        </Modal>
      </>
    );
  }
}

BusinessUsers.propTypes = {
  loading: PropTypes.bool,
  client: PropTypes.object,
  deleteUser: PropTypes.func,
  updateUser: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    loading: state.client.loading,
    client: state.client.client,
    submitting: state.client.submitting,
    error: state.client.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (id, data) => dispatch(updateUser({ id, data })),
    deleteUser: (clientId, userId) =>
      dispatch(deleteUser({ clientId, userId })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(BusinessUsers));
