import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
const JobDetails = lazy(() => import('./components/detail'));
const UpcomingJobs = lazy(() => import('./components/jobs'));
import { getJob } from './reducers';
import './styles.css';
import request from '../../utils/apisauce';
import { renderLoadableComponent } from '../../components/Loader';

class JobProfilePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isJobDetailsModalOpen: false,
      activeTab: 'about',
      workers: [],
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getJob,
    } = this.props;
    getJob(id);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.job) {
  //     this.setState({ activeTab: 'about' });
  //   }
  // }

  componentDidUpdate(prevProps) {
    const { job } = this.props;
    const { job: prevJob } = prevProps;
    if (job.id !== prevJob.id && job.job_details) {
      request(
        'GET',
        `/api/v1/admin/vendors/users?vendor_id=${job.job_details?.vendor_id}`
      )
        .then(({ vendor_users }) => {
          this.setState({ workers: vendor_users });
        })
        .catch(console.error);
    }
  }

  onClickBack = () => {
    const {
      history: { push },
    } = this.props;

    push('/admin/jobs');
  };

  onClickTab = tab => {
    this.setState({
      activeTab: tab,
    });
  };

  component = () => {
    const { activeTab, workers } = this.state;
    const { job } = this.props;
    return (
      <div className="job-profile-page">
        <div className="page-header">
          <div className="page-title">
            <LeftOutlined onClick={this.onClickBack} />
            <h4>Subscription Details</h4>
          </div>
        </div>

        <div className="extra-header mob-tab">
          <div className="job-info">
            <div className="job-title">
              <div className="job-info-block">
                <label>
                  {job.jobs && job.jobs.length ? job.jobs[0].title : ''}
                </label>
                <span>Subscription ID: {job.id}</span>
              </div>
            </div>
            <div className="job-other-info">
              <div className="job-info-block" style={{ textAlign: 'center' }}>
                <label>Total Jobs</label>
                <span>{job.jobs_count ? job.jobs_count : 0}</span>
              </div>
              <div className="job-info-block" style={{ textAlign: 'center' }}>
                <label>Completed Job</label>
                <span>
                  {job.completed_jobs_count ? job.completed_jobs_count : 0}
                </span>
              </div>
              <div className="job-info-block">
                <label>Subscription Terms</label>
                <span>
                  {job.term_value ? job.term_value : ''}{' '}
                  {job.term_type ? job.term_type : ''}
                </span>
              </div>
              <div className="job-info-block">
                <label>Start Date</label>
                <span>
                  {job.start_date
                    ? moment(job.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    : ''}
                </span>
              </div>
              <div className="job-info-block">
                <label>Expiry Date</label>
                <span>
                  {job.end_date
                    ? moment(job['end_date'], 'YYYY-MM-DD').format('DD/MM/YYYY')
                    : '-'}
                </span>
              </div>
            </div>
          </div>

          <div className="ant-tabs-nav ant-tabs-nav-animated">
            <div
              className={`ant-tabs-tab${
                activeTab === 'about' ? ' ant-tabs-tab-active ' : ''
              }`}
              onClick={() => this.onClickTab('about')}
            >
              About
            </div>
            <div
              className={`ant-tabs-tab${
                activeTab === 'jobs' ? ' ant-tabs-tab-active ' : ''
              }`}
              onClick={() => this.onClickTab('jobs')}
            >
              Upcoming Jobs
            </div>
          </div>
        </div>
        <div className="page-content">
          {activeTab === 'about' ? (
            <Suspense
              fallback={<LoadingOutlined className="fallback-loader" />}
            >
              <JobDetails job={job} />
            </Suspense>
          ) : (
            <Suspense
              fallback={<LoadingOutlined className="fallback-loader" />}
            >
              <UpcomingJobs
                workers={workers}
                status={job?.status}
                jobDetailsModalOpen={this.jobDetailsModalOpen}
                onClickTab={this.onClickTab}
              />
            </Suspense>
          )}
        </div>
      </div>
    );
  };

  render() {
    // const { activeTab, workers } = this.state;
    // const { job } = this.props;

    return (
      <React.Fragment>
        {this.state.activeTab === '' ? (
          <div style={{ marginTop: 150 }}>
            {renderLoadableComponent(true, this.component())}
          </div>
        ) : (
          <div>{renderLoadableComponent(false, this.component())}</div>
        )}
      </React.Fragment>
    );
  }
}

JobProfilePage.propTypes = {
  job: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getJob: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.job.loading,
    job: state.job.job,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJob: id => dispatch(getJob(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobProfilePage);
