import React, { lazy, Suspense, useState } from 'react';
import { Row, Col } from 'antd';
// import OrderTable from '../../components/OrderTable';
import TableSearch from '../../components/TableSearch';
import {
  getTransactionsfortopup,
  getTransactionsforSubcription,
  gettransactionsforService,
  getTransactionsForClasses,
  gettransactionsforProduct,
  getTransactions,
  getTransactionsforTransfer,
  getTransactionsforAdmin,
  // getTransactionsforReferral,
} from './reducers';
import { useDispatch } from 'react-redux';
const All = lazy(() => import('./components/all'));
const Service = lazy(() => import('./components/service'));
const Classes = lazy(() => import('./components/class'));
const Topup = lazy(() => import('./components/topup'));
const Subscription = lazy(() => import('./components/subscription'));
const Product = lazy(() => import('./components/product'));
const Transfer = lazy(() => import('./components/transfer'));
const Admin = lazy(() => import('./components/admin'));
// import Referral from './components/referral';
import './style.css';
import { LoadingOutlined } from '@ant-design/icons';

function Transaction() {
  const dispatch = useDispatch();
  // const [setModalOpen] = useState(false);
  // const [transactionsList, updateTransactionsList] = useState('');
  const [searchQuery, SetSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('all');
  // const { transactions, loading } = useSelector((state) => state.transactions);

  // useEffect(() => {
  //   updateTransactionsList(transactions);
  // }, [transactions]);

  // useEffect(() => {
  //   getTransactions({ page: 1 });
  // }, [getTransactions]);

  const handleSearchQuery = () => {
    // getTransactions({ query: searchQuery });
    // SetqueryString(searchQuery);

    if (activeTab === 'all') {
      dispatch(getTransactions({ query: searchQuery }));
    } else if (activeTab === 'topup') {
      dispatch(getTransactionsfortopup({ query: searchQuery }));
    } else if (activeTab === 'product') {
      dispatch(gettransactionsforProduct({ query: searchQuery }));
    } else if (activeTab === 'service') {
      dispatch(gettransactionsforService({ query: searchQuery }));
    } else if (activeTab === 'classes') {
      dispatch(getTransactionsForClasses({ query: searchQuery }));
    } else if (activeTab === 'subscription') {
      dispatch(getTransactionsforSubcription({ query: searchQuery }));
    } else if (activeTab === 'transfer') {
      dispatch(getTransactionsforTransfer({ query: searchQuery }));
    } else if (activeTab === 'admin') {
      dispatch(getTransactionsforAdmin({ query: searchQuery }));
      // } else if (activeTab === 'referral') {
      //   dispatch(getTransactionsforReferral({ query: searchQuery }));
    } else {
      dispatch(getTransactions({ query: searchQuery }));
    }
  };

  return (
    <div className="jobs-page">
      <div className="page-header">
        <Row>
          <Col>
            <div className="page-title">
              <h4>Transactions</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ant-tabs-nav ant-tabs-nav-animated">
              <div
                className={`ant-tabs-tab${
                  activeTab === 'all' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('all')}
              >
                All
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'topup' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('topup')}
              >
                Topup
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'product' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('product')}
              >
                Product
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'service' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('service')}
              >
                Service
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'classes' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('classes')}
              >
                Classes
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'subscription' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('subscription')}
              >
                Subscription
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'transfer' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('transfer')}
              >
                Transfer
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'admin' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('admin')}
              >
                Admin
              </div>
              {/* <div
                className={`ant-tabs-tab${
                  activeTab === 'referral' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('referral')}
              >
                Referral
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
      <div className="order-content-container">
        <TableSearch
          placeholder="Search transaction (search by Stripe Charge ID, Client Name)"
          btnText="Search"
          onBtnClick={handleSearchQuery}
          value={searchQuery}
          onInputChange={ev => SetSearchQuery(ev['target']['value'])}
        />
        {activeTab === 'all' ? (
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <All />
          </Suspense>
        ) : activeTab === 'topup' ? (
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <Topup />
          </Suspense>
        ) : activeTab === 'product' ? (
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <Product />
          </Suspense>
        ) : activeTab === 'service' ? (
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <Service />
          </Suspense>
        ) : activeTab === 'classes' ? (
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <Classes />
          </Suspense>
        ) : activeTab === 'transfer' ? (
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <Transfer />
          </Suspense>
        ) : activeTab === 'admin' ? (
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <Admin />
          </Suspense>
        ) : (
          // ) : activeTab === 'referral' ? (
          //   <Referral />
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <Subscription />
          </Suspense>
        )}
        {/* <Card className="overflow-x-auto">
          <TransactionTable
            dataSource={transactionsList}
            loading={props['loading']}
            setModalOpen={setModalOpen}
          />
        </Card> */}
      </div>
      {/* <Modal
        title="Order Details"
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        width="80%"
      >
        <OrderTable
          records={[
            {
              key: 0,
              timeStamp: '28/06/2020',
              orderId: '1019',
              orderType: { type: 'PRODUCTS', text: 'Products' },
              clientType: 'Personal',
              clientName: 'Rebeca Chander',
              orderItem: 'View Items',
              amountPrice: '190',
              shoppingId: '',
              deliveryDate: { disabled: false, text: 'Set Date' },
              jobId: '',
              paymentStatus: { type: 'WAITING', text: 'Waiting' },
              transactionId: 'TX121',
              orderStatus: { type: 'PENDING_PAYMENT', text: 'Pending Payment' },
            },
          ]}
          pagination={false}
        />
      </Modal> */}
    </div>
  );
}

export default Transaction;
