import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login, getProfile } from './pages/AuthPage/reducers';

const PrivateRoute = props => {
  React.useEffect(() => {
    getProfile();
  }, []);
  const { component, path, exact, authenticated } = props;

  return authenticated ? (
    <Redirect to="/admin/login" />
  ) : (
    <Route path={path} exact={exact} component={component} />
  );
};

PrivateRoute.propTypes = {
  loading: PropTypes.bool,
  authenticated: PropTypes.bool,
  error: PropTypes.string,
  login: PropTypes.func,
  getProfile: PropTypes.func,
  component: PropTypes.any,
  path: PropTypes.string,
  exact: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    loading: state.auth.loading,
    error: state.auth.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: payload => dispatch(login(payload)),
    getProfile: () => dispatch(getProfile()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(PrivateRoute));
