import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Form, Button, Typography } from 'antd';
import { login, getProfile } from './reducers';
import { Redirect } from 'react-router-dom';
const butlerLogo = lazy(() => import('../../assets/icons/butlerLogo.png'));
import '../../auth.css';

const { Title } = Typography;

class LoginPage extends React.PureComponent {
  renderUsername = () => {
    return (
      <Form.Item
        name="username"
        label="Email Address"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}
      >
        <Input className="login-form-input" placeholder="Enter Your Email" />
      </Form.Item>
    );
  };

  renderPassword = () => {
    return (
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password placeholder="Enter Password" />
      </Form.Item>
    );
  };

  render() {
    if (this.props.authenticated) {
      return <Redirect key="dashboard" to="/" />;
    }
    if (!this.props.authenticated && this.props.verificationPage) {
      return <Redirect key="otp_page" to="/admin/login/otp-verification/" />;
    }

    if (this.props.authenticated && !this.loading && !this.props.error) {
      this.props.getProfile();
    }
    return (
      <div>
        <div className="login-main">
          <div className="login-wrap">
            <div className="img-side">
              <a href="#" id="logo">
                <img src={butlerLogo} />
              </a>
              <div className="img-content">
                <h1>Welcome To BUTLER Admin Portal !</h1>
              </div>
            </div>
            <div className="form-side">
              <Form
                name="normal-login"
                className="login-form"
                layout={'vertical'}
                onFinish={this.props.login}
              >
                <Form.Item>
                  <Title level={3}>Log In</Title>
                </Form.Item>
                {this.renderUsername()}
                {this.renderPassword()}
                <div className="checkbox-remember-me">
                  <input type="checkbox" id="remember-me" name="remember-me" />
                  <label htmlFor="remember-me"> Remember me</label>
                </div>
                <Form.Item>
                  <Button
                    loading={this.props.loading}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Login
                  </Button>
                </Form.Item>
                {this.props.error}
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  loading: PropTypes.bool,
  authenticated: PropTypes.bool,
  profile: PropTypes.object,
  error: PropTypes.string,
  login: PropTypes.func,
  getProfile: PropTypes.func,
  username: PropTypes.string,
  password: PropTypes.string,
  verificationPage: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    profile: state.auth.profile,
    loading: state.auth.loading,
    error: state.auth.error,
    username: state.auth.username,
    password: state.auth.password,
    verificationPage: state.auth.verificationPage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: payload => dispatch(login(payload)),
    getProfile: () => dispatch(getProfile()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(LoginPage));
