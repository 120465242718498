import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Button,
  notification,
  Checkbox,
  Modal,
  Select,
  Spin,
} from 'antd';
import CardHeading from './CardHeading';
import FormField from './FormField';
import RecipientsTable from '../../components/RecipientsTable';
import { getPersonalRecipientsList, sendNotification } from './reducers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const { Option } = Select;
const initialState = () => ({
  title: '',
  message: '',
  notification_types: ['push'],
});

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function CreateNewTab(props) {
  const {
    getPersonalRecipientsList,
    personalRecipientsList,
    loadingPersonalRecipientsList,
    sendNotification,
    allClientsLoad,
    searchClients,
  } = props;

  const prevProps = usePrevious({
    personalRecipientsList,
  });

  const [dataSource, updateDataSource] = useState([]);
  const [selectedClients, updateSelectedClients] = useState([]);
  const [exceptionClients, updateExceptionClients] = useState([]);
  const [onlyList, updateOnlyList] = useState([]);
  const [exceptionList, updateExceptionList] = useState([]);
  const [only, sentOnly] = useState(false);
  const [except, sentExcept] = useState(false);
  const [createMessageDetails, updateCreateMessageDetails] = useState(
    initialState()
  );
  const [modalOpened, setModalOpenend] = useState(null);
  const [sentAll, setSentAll] = useState(true);
  const [emojiPickerTitle, setEmojiPickerTitle] = useState(null);
  const [emojiPickerBody, setEmojiPickerBody] = useState(null);
  const [loadingOnCheckbox, setLoadingOnCheckbox] = useState(false);
  const [clientPage, setClientPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState(searchClients);
  const [isSearching, setIsSearching] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    if (emojiPickerTitle) {
      updateCreateMessageDetails({
        ...createMessageDetails,
        title: `${createMessageDetails['title']}${emojiObject?.emoji}`,
      });
    } else if (emojiPickerBody) {
      updateCreateMessageDetails({
        ...createMessageDetails,
        message: `${createMessageDetails['message']}${emojiObject?.emoji}`,
      });
    }
  };

  const handleSendMessage = () => {
    if (
      createMessageDetails['title'] !== '' &&
      createMessageDetails['message'] !== '' &&
      (selectedClients['length'] >= 1 || sentAll)
    ) {
      let payload = {};
      if (sentAll) {
        payload['send_to_all'] = true;
      } else {
        payload['send_to_all'] = false;
        const personalClient = except
          ? exceptionClients.map(client => client['id'])
          : selectedClients.map(client => client['id']);
        payload['audiences'] = {
          PersonalClient: personalClient,
        };
      }
      payload['except_audiences'] = except;
      payload['title'] = createMessageDetails['title'];
      payload['message'] = createMessageDetails['message'];
      payload['notification_types'] =
        createMessageDetails['notification_types'];
      setModalOpenend(false);
      sendNotification(payload);
      setSentAll(true);
      sentExcept(false);
      sentOnly(false);
      updateExceptionClients([]);
      updateExceptionList([]);
      updateOnlyList([]);
      updateSelectedClients([]);
      updateCreateMessageDetails(initialState());
    } else {
      setModalOpenend(false);
      notification.error({
        message: 'Alert',
        description: 'All Fields are required!',
      });
    }
  };

  useEffect(() => {
    if (
      prevProps &&
      prevProps.personalRecipientsList !== personalRecipientsList
    ) {
      if (personalRecipientsList && personalRecipientsList['length']) {
        const updatedDataSource = personalRecipientsList.map(recipient => ({
          ...recipient,
          client_name: `${
            recipient && recipient['first_name'] ? recipient['first_name'] : ''
          } ${
            recipient && recipient['last_name'] ? recipient['last_name'] : ''
          }`,
          client_type: { value: 'personal', text: 'Personal' },
        }));
        updateDataSource([...updatedDataSource]);
      }
    }
  }, [prevProps, getPersonalRecipientsList, personalRecipientsList]);

  useEffect(() => {
    getPersonalRecipientsList();
  }, [getPersonalRecipientsList]);

  useEffect(() => {
    if (!allClientsLoad) {
      getPersonalRecipientsList({ page: clientPage });
    }
  }, [clientPage]);

  useEffect(() => {
    if (sentAll) {
      updateSelectedClients([]);
    } else if (only) {
      updateSelectedClients([]);
    } else if (except) {
      updateSelectedClients(dataSource);
      updateExceptionList([]);
    }
    if (!except && !only && !sentAll) {
      updateSelectedClients([]);
    }
  }, [only, sentAll, except]);

  useEffect(() => {
    updateExceptionList(
      exceptionClients.map(
        user => `${user.id}_${user.email}_${user.full_name}_${user.mobile}`
      )
    );
  }, [exceptionClients]);

  useEffect(() => {
    updateOnlyList(
      selectedClients.map(
        user => `${user.id}_${user.email}_${user.full_name}_${user.mobile}`
      )
    );
  }, [selectedClients]);

  useEffect(() => {
    function loader() {
      setLoadingOnCheckbox(false);
    }
    if (loadingOnCheckbox) {
      setTimeout(loader, 1000);
    }
  }, [loadingOnCheckbox]);

  function handleClientSelect(value) {
    let ids = value.map(selected => Number(selected.split('_')[0]));
    updateExceptionClients(dataSource.filter(el => ids.includes(el.id)));
    updateExceptionList(value);
    updateSelectedClients(dataSource.filter(el => !ids.includes(el.id)));
  }

  function handleOnlySelect(value) {
    let ids = value.map(selected => Number(selected.split('_')[0]));
    updateExceptionClients(dataSource.filter(el => !ids.includes(el.id)));
    updateOnlyList(value);
    updateSelectedClients(dataSource.filter(el => ids.includes(el.id)));
  }

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value]);

    return debouncedValue;
  }

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      searchCharacters(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  function searchCharacters(search) {
    getPersonalRecipientsList({ query: search });
    setIsSearching(false);
  }

  useEffect(() => {
    setResults(searchClients);
  }, [searchClients]);

  return (
    <>
      <Card
        className="tab-container"
        onClick={() => {
          if (emojiPickerTitle) {
            setEmojiPickerTitle(false);
          }
          if (emojiPickerBody) {
            setEmojiPickerBody(false);
          }
        }}
      >
        <CardHeading title="1. Craft Push Notification" />
        <FormField
          label={'Title'}
          fieldType={'text-input'}
          required={true}
          placeholder={'Enter Title Here'}
          value={createMessageDetails['title']}
          onChange={({ target }) =>
            updateCreateMessageDetails({
              ...createMessageDetails,
              title: target['value'],
            })
          }
          setEmojiPickerTitle={setEmojiPickerTitle}
          emojiPickerTitle={emojiPickerTitle}
          onEmojiClick={onEmojiClick}
          setEmojiPickerBody={setEmojiPickerBody}
        ></FormField>
        <FormField
          label={'Message Body'}
          fieldType={'text-area'}
          required={true}
          placeholder={'Enter Message Here'}
          value={createMessageDetails['message']}
          onChange={({ target }) =>
            updateCreateMessageDetails({
              ...createMessageDetails,
              message: target['value'],
            })
          }
          setEmojiPickerBody={setEmojiPickerBody}
          emojiPickerBody={emojiPickerBody}
          onEmojiClick={onEmojiClick}
          setEmojiPickerTitle={setEmojiPickerTitle}
        />
        <div>
          <Checkbox
            className="checkbox-c"
            checked={sentAll}
            onChange={() => {
              setSentAll(!sentAll);
              sentOnly(false);
              sentExcept(false);
              setLoadingOnCheckbox(true);
            }}
          >
            All
          </Checkbox>
          <Checkbox
            className="checkbox-c"
            checked={only}
            onChange={() => {
              setSentAll(false);
              sentOnly(!only);
              sentExcept(false);
              setLoadingOnCheckbox(true);
            }}
          >
            Only
          </Checkbox>
        </div>
        {only ? (
          <div className="mt2">
            <h2 className="field-label">Recipent List</h2>
            <Select
              showSearch
              onSearch={value => setSearchTerm(value)}
              value={onlyList}
              mode="multiple"
              style={{ width: '100%' }}
              notFoundContent={isSearching ? <Spin size="small" /> : null}
              placeholder="Select Client"
              onChange={handleOnlySelect}
            >
              {results?.map(user => (
                <Option
                  key={user.id}
                  value={`${user.id}_${user.email}_${user.full_name}_${user.mobile}`}
                >
                  {`${user.full_name} | ${user.email} | ${user.mobile}`}
                </Option>
              ))}
            </Select>
          </div>
        ) : (
          ''
        )}
        <div className="mt2">
          <h2 className="field-label">Recipient List</h2>
          <RecipientsTable
            dataSource={dataSource}
            pagination={false}
            selectedClients={selectedClients}
            updateSelectedClients={updateSelectedClients}
            loading={loadingPersonalRecipientsList || loadingOnCheckbox}
            updateExceptionClients={updateExceptionClients}
            updateDataSource={updateDataSource}
            setClientPage={setClientPage}
            clientPage={clientPage}
          />
        </div>
        <div className="mt2">
          <Checkbox
            className="checkbox-c"
            checked={except}
            onChange={() => {
              setSentAll(false);
              sentOnly(false);
              sentExcept(!except);
              setLoadingOnCheckbox(true);
            }}
          >
            Except
          </Checkbox>
          {except ? (
            <>
              <h2 className="field-label">Exception List</h2>
              <Select
                showSearch
                onSearch={value => setSearchTerm(value)}
                value={exceptionList}
                mode="multiple"
                style={{ width: '100%' }}
                notFoundContent={isSearching ? <Spin size="small" /> : null}
                placeholder="Select Client"
                onChange={handleClientSelect}
              >
                {results?.map(user => (
                  <Option
                    key={user.id}
                    value={`${user.id}_${user.email}_${user.full_name}_${user.mobile}`}
                  >
                    {`${user.full_name} | ${user.email} | ${user.mobile}`}
                  </Option>
                ))}
              </Select>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="action-btn-container">
          <Button
            className="btn-reset"
            type="link"
            onClick={() => updateCreateMessageDetails(initialState())}
          >
            Reset
          </Button>
          <Button
            className="btn"
            type="primary"
            disabled={
              (!sentAll && !only && !except) ||
              createMessageDetails['title'] === '' ||
              createMessageDetails['message'] == ''
            }
            onClick={() => setModalOpenend(1)}
          >
            Submit
          </Button>
        </div>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 1}
          onCancel={() => setModalOpenend(false)}
          onOk={() => handleSendMessage()}
          okText="Sent"
          destroyOnClose
        >
          <p className="main-message">Are you sure?</p>
        </Modal>
      </Card>
    </>
  );
}

CreateNewTab.propTypes = {
  getPersonalRecipientsList: PropTypes.func,
  personalRecipientsList: PropTypes.array,
  loadingPersonalRecipientsList: PropTypes.bool,
  sendNotification: PropTypes.func,
  allClientsLoad: PropTypes.bool,
  searchClients: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    loadingPersonalRecipientsList:
      state.pushNotification.loadingPersonalRecipientsList,
    personalRecipientsList: state.pushNotification.personalRecipientsList,
    allClientsLoad: state.pushNotification.allClientsLoad,
    searchClients: state.pushNotification.searchClients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPersonalRecipientsList: payload =>
      dispatch(getPersonalRecipientsList(payload)),
    sendNotification: payload => dispatch(sendNotification(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CreateNewTab));
