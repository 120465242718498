import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Switch,
  Card,
  Spin,
  InputNumber,
} from 'antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import StyledForm from './styled-components/form-wrapper';
import { formInitialValues } from '../../utils/formsInitialValues';
import { formValidations } from '../../utils/formValidations';
import { getSettings, createSettings } from './reducer';

const SettingsPage = props => {
  const { loading, settings, getSettings, submitting, createSettings } = props;
  const [form, setForm] = useState({});

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    setForm({ ...settings?.system_configuration });
  }, [settings]);
  console.log('form', form);
  return (
    <div className="order-container">
      <div className="page-header">
        <div className="page-title">
          <h4>Settings</h4>
        </div>
      </div>

      <div className="page-content">
        <Formik
          initialValues={{
            ...formInitialValues['userSettingsForm'],
            ...form,
          }}
          enableReinitialize={true}
          validationSchema={formValidations['userSettingsFormValidator']}
          onSubmit={values => createSettings(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <Card className="tab-container">
                <Spin spinning={loading}>
                  <StyledForm onSubmit={handleSubmit}>
                    <div className="mb-1">
                      <Row gutter={20}>
                        <Col span={4}>
                          <label>Email</label>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['admin_email'] && errors['admin_email']
                                ? 'error'
                                : null
                            }
                            help={
                              touched['admin_email'] && errors['admin_email']
                            }
                            style={{ margin: 0 }}
                          >
                            <Input
                              value={values.admin_email}
                              onChange={handleChange}
                              name="admin_email"
                              placeholder="Email "
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-1">
                      <Row gutter={20}>
                        <Col span={4}>
                          <label>GST %</label>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['sales_tax_percentage'] &&
                              errors['sales_tax_percentage']
                                ? 'error'
                                : null
                            }
                            help={
                              touched['sales_tax_percentage'] &&
                              errors['sales_tax_percentage']
                            }
                            style={{ margin: 0 }}
                          >
                            <Input
                              value={values.sales_tax_percentage}
                              onChange={handleChange}
                              name="sales_tax_percentage"
                              placeholder="Enter GST % "
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-1">
                      <Row gutter={20}>
                        <Col span={4}>
                          <label>Force Renew Subscription</label>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['force_renew_subscription_topup'] &&
                              errors['force_renew_subscription_topup']
                                ? 'error'
                                : null
                            }
                            help={
                              touched['force_renew_subscription_topup'] &&
                              errors['force_renew_subscription_topup']
                            }
                            style={{ margin: 0 }}
                          >
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              checked={values.force_renew_subscription_topup}
                              onChange={value =>
                                setFieldValue(
                                  'force_renew_subscription_topup',
                                  value
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-1">
                      <Row gutter={20}>
                        <Col span={4}>
                          <label>OTP SMS body</label>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['otp_sms_body'] && errors['otp_sms_body']
                                ? 'error'
                                : null
                            }
                            help={
                              touched['otp_sms_body'] && errors['otp_sms_body']
                            }
                            style={{ margin: 0 }}
                          >
                            <Input
                              value={values.otp_sms_body}
                              onChange={handleChange}
                              name="otp_sms_body"
                              placeholder="Enter OPT SMS Body"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-1">
                      <Row gutter={20}>
                        <Col span={4}>
                          <label>Pool Distance in miles</label>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['pool_distance_in_miles'] &&
                              errors['pool_distance_in_miles']
                                ? 'error'
                                : null
                            }
                            help={
                              touched['pool_distance_in_miles'] &&
                              errors['pool_distance_in_miles']
                            }
                            style={{ margin: 0 }}
                          >
                            <Input
                              value={values.pool_distance_in_miles}
                              onChange={handleChange}
                              name="pool_distance_in_miles"
                              placeholder="Enter Pool Distance "
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-1">
                      <Row gutter={20}>
                        <Col span={4}>
                          <label>Referral bonus amount in cents</label>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['referral_bonus_amount_in_cents'] &&
                              errors['referral_bonus_amount_in_cents']
                                ? 'error'
                                : null
                            }
                            help={
                              touched['referral_bonus_amount_in_cents'] &&
                              errors['referral_bonus_amount_in_cents']
                            }
                            style={{ margin: 0 }}
                          >
                            {/* <Input
                              value={values.pool_distance_in_miles}
                              onChange={handleChange}
                              name="pool_distance_in_miles"
                              placeholder="Enter Pool Distance "
                            /> */}
                            <InputNumber
                              value={values.referral_bonus_amount_in_cents}
                              onChange={value =>
                                handleChange({
                                  target: {
                                    name: 'referral_bonus_amount_in_cents',
                                    value: value,
                                  },
                                })
                              }
                              name="referral_bonus_amount_in_cents"
                              placeholder="Referral bonus amount in cents"
                              min={0}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-1">
                      <Row gutter={20}>
                        <Col span={4}>
                          <label>Currency</label>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['currency_symbol'] &&
                              errors['currency_symbol']
                                ? 'error'
                                : null
                            }
                            help={
                              touched['currency_symbol'] &&
                              errors['currency_symbol']
                            }
                            style={{ margin: 0 }}
                          >
                            <Input
                              value={values.currency_symbol}
                              onChange={handleChange}
                              name="currency_symbol"
                              placeholder="Enter OPT SMS Body"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <Row gutter={16}>
                      <Col push={10} span={4}>
                        <Button
                          disabled={submitting}
                          onClick={handleSubmit}
                          type="primary"
                          htmlType="submit"
                          block
                        >
                          Save
                        </Button>
                      </Col>
                      <Col push={3} span={3}>
                        <Button
                          type="default"
                          block
                          disabled={submitting}
                          // onClick={onClickBack}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </StyledForm>
                </Spin>
              </Card>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

SettingsPage.propTypes = {
  loading: PropTypes.bool,
  settings: PropTypes.object,
  getSettings: PropTypes.func,
  submitting: PropTypes.bool,
  createSettings: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.settings.loading,
    settings: state.settings.settings,
    submitting: state.settings.submitting,
    error: state.settings.submitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSettings: payload => dispatch(getSettings(payload)),
    createSettings: payload => dispatch(createSettings(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
