import {
  PlusOutlined,
  UserOutlined,
  CloseOutlined,
  EyeFilled,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Drawer,
  Modal,
  Form,
  Select,
  Input,
  message,
  Row,
  Col,
} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from '../../components/DataTable';
import TableSearch from '../../components/TableSearch';
import DraggerUpload from '../../components/DragDropMediaUpload';
import {
  getMedia,
  createMedia,
  updateMedia,
  deleteMedia,
  startEditMedia,
} from './reducers';
import { getImageURI, capitalize } from '../../utils/apisauce';
import { Formik } from 'formik';
import 'react-phone-input-2/lib/style.css';
import './styles.css';
import { DeleteFilledIcon, EditFilledIcon } from '../../components/Icons/Icons';

const userData = {
  image: null,
  images: null,
};

const baseURL = process.env.REACT_APP_BASE_URL;

class MediaLibrary extends React.Component {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: null,
    lastSearch: '',
    isFileGreater: false,
    pageQuery: {
      page: 1,
      per: 20,
    },
    queryString: '',
    categoryTab: 'list',
    isCopied: false,
  };

  tableColumns = () => [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      fixed: 'left',
      render: (_title, record) => (
        <>
          <Avatar
            src={getImageURI(record['image_path'] && record['image_path']?.url)}
            icon={<UserOutlined />}
            size={'large'}
            shape="circle"
          />
        </>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (_title, record) => record['title'],
    },
    {
      title: 'Image type',
      dataIndex: 'image_type',
      key: 'image_type',
      align: 'center',
      render: (_title, record) => record['image_type'],
    },
    {
      title: 'Size (MB)',
      dataIndex: 'size',
      key: 'size',
      align: 'center',
      render: (_title, record) =>
        record['image_metadata'] && record['image_metadata']?.size_in_mb,
    },
    {
      title: 'Dimension',
      dataIndex: 'dimension',
      key: 'dimension',
      render: (_title, record) =>
        record['image_metadata'] &&
        `${record['image_metadata']?.height}x${record['image_metadata']?.width}`,
    },
    {
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      align: 'center',
      render: (text, record) => (
        <div
          className="ant-table-action-cell"
          style={{ justifyContent: 'center' }}
        >
          <EyeFilled
            style={{ fontSize: '20px' }}
            onClick={() => this.onClickRow(record?.id, record)}
          />
          <DeleteFilledIcon
            style={{ fontSize: '20px' }}
            className="action-primary"
            onClick={() => this.onDelete(record)}
          />
        </div>
      ),
    },
  ];

  componentDidUpdate(prevProps, prevState) {
    const { getMedia } = this.props;
    const { pageQuery, queryString, categoryTab } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      if (queryString) {
        getMedia({ ...pageQuery, query: queryString, categoryTab });
      } else {
        getMedia({ ...pageQuery, categoryTab });
      }
    }
  }

  componentDidMount() {
    const { getMedia } = this.props;
    const { pageQuery, categoryTab } = this.state;
    getMedia({ ...pageQuery, categoryTab });
  }

  onSearch = () => {
    const { getMedia } = this.props;
    const { pageQuery, queryString, categoryTab } = this.state;
    const updatedPageQuery = { ...pageQuery, page: 1 };
    this.setState({ pageQuery: updatedPageQuery }, () =>
      getMedia({ query: queryString, per: pageQuery['per'], categoryTab })
    );
  };

  closeModal = () => {
    const { startEditMedia } = this.props;
    startEditMedia(false);
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
    });
  };

  onClickRow = (e, record) => {
    const { startEditMedia } = this.props;
    startEditMedia(true);
    this.setState({
      modalOpened: 1,
      selectedRow: record,
      formData: null,
    });
  };

  onCreate = () => {
    const { startEditMedia } = this.props;
    startEditMedia(true);
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      isFileGreater: false,
      formData: { ...userData },
    });
  };

  onEdit = record => {
    const { startEditMedia } = this.props;
    startEditMedia(true);
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      isFileGreater: false,
      formData: {
        ...record,
      },
    });
  };

  onDelete = record => {
    this.setState({
      modalOpened: 2,
      selectedRow: record,
      formData: null,
    });
  };

  onSubmit = values => {
    const { formData } = this.state;
    const { image_type, title } = values;
    if (formData.images) {
      formData.image = formData.images;
    } else {
      formData.image = null;
    }
    if (formData.id) {
      const { updateMedia } = this.props;
      const { id, image, images } = formData;
      if (!this.state.isFileGreater) {
        updateMedia({
          id,
          image,
          images,
          image_type,
          title,
          categoryTab: this.state.categoryTab,
        });
      } else {
        message.error('File size must be lesser than 3 MB');
      }
    } else {
      const { createMedia } = this.props;
      if (!this.state.isFileGreater) {
        createMedia({
          ...formData,
          image_type,
          title,
          categoryTab: this.state.categoryTab,
        });
      } else {
        message.error('File size must be lesser than 3 MB');
      }
    }
  };

  performDelete = () => {
    const { selectedRow } = this.state;
    const { deleteMedia } = this.props;

    deleteMedia(selectedRow.id);
    this.closeModal();
  };

  setActiveTab = value => {
    this.setState(state => ({ ...state, categoryTab: value }));
    const { getMedia } = this.props;
    const { pageQuery, queryString } = this.state;
    getMedia({
      ...pageQuery,
      query: queryString ? queryString : null,
      categoryTab: value,
    });
  };

  onInputChange = ({ target: { name, value } }) => {
    const { formData } = this.state;

    formData[name] = value;
    this.setState({ formData });
  };

  onShowSizeChange = (current, pageSize) => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = current;
    updatedPageQuery['per'] = pageSize;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleSubmitCheck = value => {
    this.setState(state => ({ ...state, isFileGreater: value }));
  };

  copyText = async text => {
    await navigator.clipboard.writeText(text);
    this.setState({ isCopied: true });
    message.success('Image URL copied');
    setTimeout(() => this.setState({ isCopied: false }), 2000);
  };

  renderFormData = () => {
    const { formData } = this.state;
    if (!formData) {
      return;
    }
    return (
      <>
        <div className="drawer-header">
          <h3>{formData.id ? 'Edit Media' : 'Create New Media'}</h3>
          <div className="header-actions">
            {formData.id && (
              <DeleteFilledIcon
                className="action-primary"
                onClick={() => this.onDelete(formData)}
              />
            )}
            <CloseOutlined onClick={this.closeModal} />
          </div>
        </div>
        <div className="drawer-body">
          <Formik
            initialValues={{
              ...formData,
            }}
            onSubmit={values => this.onSubmit(values)}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <label>Image Type</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['image_type'] && errors['image_type']
                          ? 'error'
                          : null
                      }
                      help={touched['image_type'] && errors['image_type']}
                      style={{ margin: 0 }}
                    >
                      <Select
                        style={{ width: '100%', marginBottom: '10px' }}
                        value={values.image_type}
                        placeholder="Select Image Type"
                        onChange={value =>
                          handleChange({
                            target: { name: 'image_type', value },
                          })
                        }
                      >
                        <Select.Option value={'list'}>List</Select.Option>
                        <Select.Option value={'banner'}>Banner</Select.Option>
                        <Select.Option value={'profile'}>Profile</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <label>Title</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['title'] && errors['title'] ? 'error' : null
                      }
                      help={touched['title'] && errors['title']}
                      style={{ margin: 0 }}
                    >
                      <Input
                        style={{ width: '100%', marginBottom: '10px' }}
                        defaultValue={values?.title}
                        placeholder="Select Image Title"
                        onChange={e =>
                          handleChange({
                            target: {
                              name: 'title',
                              value: e.target.value,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </div>
                  {values.image_type ? (
                    <DraggerUpload
                      value={
                        formData['image_path']
                          ? getImageURI(formData['image_path'].url)
                          : null
                      }
                      onChange={value =>
                        this.onInputChange({
                          target: { name: 'images', value: { data: value } },
                        })
                      }
                      accept=".png, .jpg, .jpeg"
                      aspectRatio={values.image_type === 'banner' ? 21 / 12 : 1}
                      width={100}
                      height={100}
                      handleSubmit={this.handleSubmitCheck}
                      largeSize={values.image_type === 'profile' ? false : true}
                    />
                  ) : (
                    ''
                  )}
                  <Button type="primary" htmlType="submit" block>
                    Upload
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </>
    );
  };
  render() {
    const { selectedRow, modalOpened, pageQuery, queryString, isCopied } =
      this.state;

    const { loading, media_library_images, formOpen } = this.props;
    const { data, total } = media_library_images;
    return (
      <div className="admins-page">
        <div className="page-header">
          <div className="page-title">
            <h4>Media Library</h4>
          </div>
          <div className="page-header-actions">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.onCreate}
            >
              Add Media
            </Button>
          </div>
        </div>
        <div className="page-header">
          <Row>
            <Col>
              <div className="ant-tabs-nav ant-tabs-nav-animated">
                <div
                  className={`ant-tabs-tab${
                    this.state.categoryTab === 'list'
                      ? ' ant-tabs-tab-active '
                      : ''
                  }`}
                  onClick={() => this.setActiveTab('list')}
                >
                  List
                </div>
                <div
                  className={`ant-tabs-tab${
                    this.state.categoryTab === 'banner'
                      ? ' ant-tabs-tab-active '
                      : ''
                  }`}
                  onClick={() => this.setActiveTab('banner')}
                >
                  Banner
                </div>
                <div
                  className={`ant-tabs-tab${
                    this.state.categoryTab === 'profile'
                      ? ' ant-tabs-tab-active '
                      : ''
                  }`}
                  onClick={() => this.setActiveTab('profile')}
                >
                  Profile
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="page-content">
          <TableSearch
            placeholder="Search Slide"
            btnText="Search"
            onBtnClick={this.onSearch}
            value={queryString}
            onInputChange={ev =>
              this.setState({ queryString: ev['target']['value'] })
            }
          />
          <Card>
            <DataTable
              rowKey={record => record.id}
              loading={loading}
              data={data}
              columns={this.tableColumns()}
              onClickRow={() => {}}
              pagination={{
                limit: pageQuery['per'],
                total,
                page: pageQuery['page'],
                data,
              }}
              handleTableChange={this.onShowSizeChange}
            />
          </Card>
          <Drawer
            closable={false}
            placement="right"
            width={450}
            visible={modalOpened === 1 && formOpen === true}
            onClose={this.closeModal}
            destroyOnClose
          >
            {selectedRow ? (
              <>
                <div className="drawer-header">
                  <h3 className="drawer-heading">Media Details</h3>
                  <div className="header-actions">
                    <EditFilledIcon
                      className="action-primary"
                      onClick={() => this.onEdit(selectedRow)}
                    />
                    <CloseOutlined onClick={this.closeModal} />
                  </div>
                </div>
                <div className="drawer-body">
                  <div className="user-avatar">
                    <Avatar
                      src={getImageURI(
                        selectedRow['image_path'] &&
                          selectedRow['image_path'].url
                      )}
                      icon={<UserOutlined />}
                      size={'large'}
                      style={{
                        width: '100%',
                        height: '200px',
                        borderRadius: '18px',
                      }}
                      shape="square"
                    />
                  </div>
                  <div className="user-info-row image-detail">
                    <label>Title</label>
                    <span>{capitalize(selectedRow.title)}</span>
                  </div>
                  <div className="user-info-row image-detail">
                    <label>Size</label>
                    <span>{selectedRow.image_metadata?.size_in_mb} MB</span>
                  </div>
                  <div className="user-info-row image-detail">
                    <label>Dimension</label>
                    <span>
                      {selectedRow.image_metadata?.height}x
                      {selectedRow.image_metadata?.width}
                    </span>
                  </div>
                  <div className="user-info-row image-detail">
                    <label>Image Type</label>
                    <span>{capitalize(selectedRow.image_type)}</span>
                  </div>
                  <div className="user-info-row">
                    <label>Image URL</label>
                    <div
                      style={{
                        backgroundColor: '#eee',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                        borderRadius: '4px',
                        marginTop: '10px',
                      }}
                    >
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={baseURL + selectedRow?.image_path?.url}
                      >
                        {baseURL}
                        {selectedRow?.image_path?.url}
                      </a>
                      <span style={{ alignSelf: 'end', cursor: 'default' }}>
                        {isCopied ? (
                          <span
                            style={{
                              backgroundColor: '#dbdbdb',
                              padding: '1px 15px',
                              borderRadius: '7px',
                              color: '#000',
                            }}
                          >
                            Copied!
                          </span>
                        ) : (
                          <span
                            onClick={() =>
                              this.copyText(
                                baseURL + selectedRow?.image_path?.url
                              )
                            }
                            style={{
                              backgroundColor: '#dbdbdb',
                              padding: '1px 15px',
                              borderRadius: '7px',
                              color: '#000',
                            }}
                          >
                            Copy
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              this.renderFormData()
            )}
          </Drawer>
          <Modal
            className="delete-user-modal"
            visible={modalOpened === 2}
            onCancel={this.closeModal}
            onOk={this.performDelete}
            okText="Yes"
            destroyOnClose
          >
            {selectedRow && (
              <>
                <h1 className="main-message pop-up-heading">Are you sure?</h1>
                <p className="pop-up-body">
                  Do you really want to remove this media? The process cannot be
                  undone.
                </p>
              </>
            )}
          </Modal>
        </div>
      </div>
    );
  }
}
MediaLibrary.propTypes = {
  loading: PropTypes.bool,
  media_library_images: PropTypes.array,
  errors: PropTypes.array,
  formOpen: PropTypes.boolean,
  getMedia: PropTypes.func,
  createMedia: PropTypes.func,
  updateMedia: PropTypes.func,
  deleteMedia: PropTypes.func,
  startEditMedia: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.media.loading,
    media_library_images: state.media.media_library_images,
    errors: state.media.errors,
    formOpen: state.media.formOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMedia: keyword => dispatch(getMedia(keyword)),
    createMedia: data => dispatch(createMedia(data)),
    updateMedia: data => dispatch(updateMedia(data)),
    deleteMedia: id => dispatch(deleteMedia(id)),
    startEditMedia: open => dispatch(startEditMedia(open)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(MediaLibrary));
