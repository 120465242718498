import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { Row, Col, notification, DatePicker, Button, Modal } from 'antd';
import moment from 'moment';
import React, { lazy, Suspense } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  handleVendorChange,
  searchWorkers,
  formatWorkerContent,
  assignJobToWorker,
  reassignJobToWorker,
  unassignJobFromWorker,
  assignWorkerPerm,
  searchVendorsSuccess,
  downloadCsvJobs,
  // getNotes,
  // getNewNotes,
  // updateNotes,
} from './reducers';
import TableSearch from '../../components/TableSearch';
const VendorList = lazy(() => import('./components/vendorList'));
const NotesDrawer = lazy(() => import('./components/notesDrawer'));
const WorkerCard = lazy(() => import('./components/workerCard'));

import './styles.css';
// SOMEONE CHANGE THIS TO FUNCTIONAL COMPONENT WHEN YOU CALL, FETCH ALL AT ONCE,
// THIS IS CAUSING NEEDLESS REPEATED RERENDERS
// useContext to pass down properties required by the jobCard

export const JobContext = React.createContext({
  permanentReassign: false,
  permanentReassignSelectedDay: false,
  permanentlyReassignedJobsId: [],
  handlePermReassignSelectedDay: () => {},
  checkboxOnChange: () => {},
});
class Scheduler extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      collapsedNotes: false,
      pickerOpened: false,
      currentDate: moment(),
      CURRENT_DATE: moment(),
      permanentReassign: false,
      permanentReassignSelectedDay: false,
      permanentlyReassignedJobsId: [],
      permanentReassignSelectedDayString: '',
      reloadShow: false,
      queryString: '',
    };
  }

  // handlePermReassignSelectedDay = (ev, job) => {
  //   const { permanentlyReassignedJobsId, currentDate } = this.state;
  //   let updatedPermanentlyReassignedJobsId = [...permanentlyReassignedJobsId];
  //   const dayString = currentDate.format('dddd').toLowerCase();

  //   if (ev['target']['checked']) {
  //     const idExists = permanentlyReassignedJobsId.find(
  //       id => id === job.repeating_job_id
  //     );
  //     if (!idExists) {
  //       updatedPermanentlyReassignedJobsId = [
  //         ...permanentlyReassignedJobsId,
  //         job.repeating_job_id,
  //       ];
  //     }
  //   } else {
  //     updatedPermanentlyReassignedJobsId = permanentlyReassignedJobsId.filter(
  //       id => id !== job.repeating_job_id
  //     );
  //   }

  //   this.setState({
  //     permanentReassignSelectedDay: ev.target.checked,
  //     permanentReassignSelectedDayString: ev.target.checked ? dayString : '',
  //     permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
  //   });
  // };

  // checkboxOnChange = (ev, job) => {
  //   const { permanentlyReassignedJobsId } = this.state;
  //   let updatedPermanentlyReassignedJobsId = [...permanentlyReassignedJobsId];

  //   if (ev['target']['checked']) {
  //     const idExists = permanentlyReassignedJobsId.find(
  //       id => id === job.repeating_job_id
  //     );
  //     if (!idExists) {
  //       updatedPermanentlyReassignedJobsId = [
  //         ...permanentlyReassignedJobsId,
  //         job.repeating_job_id,
  //       ];
  //     }
  //   } else {
  //     updatedPermanentlyReassignedJobsId = permanentlyReassignedJobsId.filter(
  //       id => id !== job.repeating_job_id
  //     );
  //   }

  //   this.setState({
  //     permanentReassign: ev['target']['checked'],
  //     permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
  //   });
  // };

  componentDidMount() {
    window.addEventListener('scroll', this.loadMore);
  }

  componentDidUpdate(prevProps) {
    if (this.props.vendorSelected !== prevProps.vendorSelected) {
      this.refreshData();
      this.handleReload();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
  }

  fetchWorkers = () => {
    const {
      searchWorkers,
      currentWorkerPage,
      // vendors,
      vendorSelected,
    } = this.props;
    const { currentDate, collapsed, queryString } = this.state;

    const params = {
      size: collapsed ? 4 : 3,
      page: currentWorkerPage + 1,
      date: currentDate.format('YYYY-MM-DD'),
      query: queryString,
    };
    params.vendor_id = vendorSelected;
    searchWorkers(params);
  };

  refreshData = () => {
    const { formatWorkerContent, vendorSelected } = this.props;

    if (vendorSelected !== undefined) {
      this.setState({ reloadShow: true });
    }
    formatWorkerContent();
    setTimeout(this.fetchWorkers, 10);
  };

  loadMore = () => {
    const { loadingWorkers, currentWorkerPage, totalWorkerPages } = this.props;
    // 99 being the height of job card
    if (
      !loadingWorkers &&
      window.scrollY + window.innerHeight * currentWorkerPage >=
        document.body.clientHeight - 99 * currentWorkerPage &&
      currentWorkerPage < totalWorkerPages
    ) {
      this.fetchWorkers();
    }
  };

  handleReload = () => {
    const { formatWorkerContent } = this.props;

    formatWorkerContent();
    this.refreshData();
    setTimeout(this.fetchWorkers, 10);
  };

  handleDatePicker = status => {
    this.setState({
      pickerOpened: status,
    });
  };

  handleDateChange = date => {
    const { currentDate } = this.state;

    if (date === 'prev') {
      date = moment(currentDate.format()).subtract(1, 'days');
    } else if (date === 'next') {
      date = moment(currentDate.format()).add(1, 'days');
    } else {
      date = moment(date);
    }

    this.setState(
      {
        currentDate: date,
      },
      this.refreshData
    );
  };

  handleCollapse = () => {
    this.setState(
      prevState => ({
        collapsed: !prevState.collapsed,
      })
      // this.refreshData
    );
  };

  handleCollapseNotes = () => {
    this.setState(
      prevState => ({
        collapsedNotes: !prevState.collapsedNotes,
      })
      // this.refreshData
    );
  };

  onSearch = () => {
    const { formatWorkerContent, vendorSelected } = this.props;

    if (vendorSelected !== undefined) {
      this.setState({ reloadShow: true });
    }
    formatWorkerContent();
    setTimeout(this.fetchWorkers, 10);
  };

  onDragEnd = result => {
    // very first condition to check before starting expensive operations like destructuring
    const {
      vendors,
      vendorSelected,
      workers,
      unassignJobFromWorker,
      assignJobToWorker,
      reassignJobToWorker,
      assignWorkerPerm,
    } = this.props;
    const { source, destination } = result;

    if (
      !this.state.currentDate.isSameOrAfter(this.state.CURRENT_DATE, 'date')
    ) {
      Modal.warning({
        title: 'Not Allowed',
        content: 'Cannot update historical jobs.',
        icon: <CloseCircleOutlined />,
      });
      return; // disable update if date before
    }
    let vendorSelectedObj = null;
    const [sourceDropId, sourceWorkerId] = source.droppableId.split('.');

    if (destination === null) {
      return;
    } //if placed outside of anounced destination box
    if (destination.droppableId.split('.')[0] === 'worker-container') {
      return;
    }
    if (
      sourceDropId === 'worker-container' &&
      destination.droppableId === 'unassign'
    ) {
      const sourceWorkerIndex = workers.findIndex(
        w => w.id === Number(sourceWorkerId)
      );
      const job = workers[sourceWorkerIndex]?.jobs.find(
        el => el.id === source.index
      );
      if (job.checkin_time || job.checkout_time) {
        return notification.error({
          message: 'This job can not be moved ',
        });
      }

      unassignJobFromWorker({
        body: { job_id: job.id },
        workerIndex: sourceWorkerIndex,
        job_status: job?.job_status,
      });
      return;
    }
    if (vendorSelected && vendors) {
      vendorSelectedObj = vendors.find(item => item['id'] === vendorSelected);
    }

    //   'permanentReassignSelectedDayString',
    //   permanentReassignSelectedDayString
    // );
    // reason why i aqm doinbg it here is because i have access to the full jobs array?, then maybe i should pass it down
    // i need it here too, cauise dropping, okay right now get checkmark to stay
    if (destination && source.droppableId !== destination.droppableId) {
      const sourceId = source.droppableId;
      const destinationId = destination.droppableId;

      if (
        sourceDropId === 'worker-container' &&
        (destinationId !== 'unassign' ||
          destination.droppableId.split('.')[0] !== 'worker-container')
      ) {
        const [workerIndex, serviceHour] = destinationId.split('.');

        const worker = workers.find(w => w.id === Number(sourceWorkerId));
        const sourceWorkerIndex = workers.findIndex(
          w => w.id === Number(sourceWorkerId)
        );
        const job = worker.jobs.find(j => j.id === source.index);
        // console.log('show ',job[0]); return;

        if (workers[workerIndex].vendor_id !== job.vendor_id) {
          return notification.error({
            message: 'Job not belongs to this vendor ',
          });
        }

        if (job.permanentReassignSelectedDay || job.permanentReassign) {
          let assignData = {
            service_date: job.service_date,
            assigned_worker_id: workers[workerIndex].id,
            assigned_time: serviceHour,
            previous_worker_id: job.assigned_worker
              ? job.assigned_worker?.id
              : null,
          };
          assignWorkerPerm(
            +job.id,
            assignData,
            job.permanentReassignSelectedDay
          );
        } else {
          const body = {
            job_id: job.id,
            vendor_id: job.vendor_id,
            assigned_worker_id: workers[workerIndex].id,
            service_date: job.service_date,
            assigned_time: serviceHour,
          };
          if (job.checkin_time || job.checkout_time) {
            return notification.error({
              message: 'This job can not be moved ',
            });
          }
          reassignJobToWorker({
            body,
            destIndex: workerIndex,
            srcIndex: sourceWorkerIndex,
            job_status: job.job_status,
          });
        }
        return;
      }

      let job = vendorSelectedObj.unassigned_jobs.find(
        el => el.id === source.index
      );

      if (!job && sourceId !== 'unassign') {
        const [workerIndex] = sourceId.split('.');
        job = workers[workerIndex].jobs.find(el => el.id === source.index);
      }

      let jobsId = [];
      let permanentReassignSelectedDayString = '';

      if (job) {
        jobsId = job['permanentlyReassignedJobsId'];
        permanentReassignSelectedDayString =
          job['permanentReassignSelectedDayString'];
      }

      if (jobsId?.length) {
        // determine result destination to remove permanent schedule or add permanent schedule
        if (destination && destination.droppableId === 'unassign') {
          // drag back to vendorList if checked, unassign perm repeating jobs
          // if unassiging find job from workers
          // job = workers[workerIndex].jobs.find(el => el.id === source.index);
          // destination: {droppableId: 'unassign', index: 0}
          let assignData = {
            service_date: job.service_date,
            end_date: null, // DONT CHANGE
            assigned_worker_id: null,
            assigned_time: null,
            previous_worker_id: job.assigned_worker
              ? job.assigned_worker?.id
              : null,
            // job_status: 'scheduled',
          };

          if (permanentReassignSelectedDayString.trim() !== '') {
            assignData['day'] = permanentReassignSelectedDayString;
          }

          assignWorkerPerm(
            +job.id,
            assignData,
            job.permanentReassignSelectedDay
          );

          this.setState({
            permanentReassignSelectedDayString: '',
          });
        } else if (
          job &&
          source.droppableId === 'unassign' &&
          jobsId.includes(job.repeating_job_id)
        ) {
          const [workerIndex, serviceHour] = destinationId.split('.');
          let assignData = {
            service_date: job.service_date,
            end_date: null, // DONT CHANGE
            assigned_worker_id: workers[workerIndex].id,
            assigned_time: serviceHour,
            // job_status: 'scheduled',
          };

          if (permanentReassignSelectedDayString.trim() !== '') {
            assignData['day'] = permanentReassignSelectedDayString;
          }

          assignWorkerPerm(
            +job.id,
            assignData,
            job.permanentReassignSelectedDay
          );

          // this.setState({
          //   permanentReassignSelectedDayString: '',
          // });
        } else {
          // transferring from one worker card to another worker card
          const [workerIndexFrom] = sourceId.split('.');
          if (workers[workerIndexFrom]?.jobs) {
            const [workerIndexTo, serviceHourTo] = destinationId.split('.');
            job = workers[workerIndexFrom].jobs.find(
              el => el.id === source.index
            );
            let assignData = {
              service_date: job.service_date,
              end_date: null, // DONT CHANGE
              assigned_worker_id: workers[workerIndexTo].id,
              assigned_time: serviceHourTo,
              previous_worker_id: job.assigned_worker
                ? job.assigned_worker?.id
                : null,
              // job_status: 'scheduled',
            };

            if (permanentReassignSelectedDayString.trim() !== '') {
              assignData['day'] = permanentReassignSelectedDayString;
            }

            assignWorkerPerm(
              +job.id,
              assignData,
              job.permanentReassignSelectedDay
            );
          } else if (sourceId === 'unassign') {
            const [workerIndex, serviceHour] = destinationId.split('.');
            const job = vendorSelectedObj.unassigned_jobs.find(
              el => el.id === source.index
            );
            if (workers[workerIndex].vendor_id !== job.vendor_id) {
              return notification.error({
                message: 'Job not belongs to this vendor ',
              });
            }

            let assignData = {
              service_date: job.service_date,
              end_date: null, // DONT CHANGE
              assigned_worker_id: workers[workerIndex].id,
              assigned_time: serviceHour,
              previous_worker_id: job.assigned_worker
                ? job.assigned_worker?.id
                : null,
              // job_status: 'scheduled',
            };

            if (permanentReassignSelectedDayString.trim() !== '') {
              assignData['day'] = permanentReassignSelectedDayString;
            }

            assignWorkerPerm(
              +job.id,
              assignData,
              job.permanentReassignSelectedDay
            );
          }
        }
      } else {
        if (sourceId === 'unassign') {
          const [workerIndex, serviceHour] = destinationId.split('.');
          const job = vendorSelectedObj.unassigned_jobs.find(
            el => el.id === source.index
          );
          if (workers[workerIndex].vendor_id !== job.vendor_id) {
            return notification.error({
              message: 'Job not belongs to this vendor ',
            });
          }
          if (job.permanentReassignSelectedDay || job.permanentReassign) {
            let assignData = {
              service_date: job.service_date,
              assigned_worker_id: workers[workerIndex].id,
              assigned_time: serviceHour,
              previous_worker_id: job.assigned_worker
                ? job.assigned_worker?.id
                : null,
            };
            assignWorkerPerm(
              +job.id,
              assignData,
              job.permanentReassignSelectedDay
            );
          } else {
            const body = {
              job_id: job.id,
              vendor_id: job.vendor_id,
              assigned_worker_id: workers[workerIndex].id,
              service_date: job.service_date,
              assigned_time: serviceHour,
            };
            assignJobToWorker({
              body,
              workerIndex,
              job_status: job.job_status,
            });
          }
        } else if (destinationId === 'unassign') {
          const [workerIndex] = sourceId.split('.');
          const job = workers[workerIndex].jobs.find(
            el => el.id === source.index
          );
          if (job.checkin_time || job.checkout_time) {
            return notification.error({
              message: 'This job can not be moved ',
            });
          }

          unassignJobFromWorker({
            body: { job_id: job.id },
            workerIndex,
            job_status: job?.job_status,
          });
        } else {
          const [srcIndex] = sourceId.split('.');
          const [destIndex, serviceHour] = destinationId.split('.');

          const job = workers[srcIndex].jobs.find(el => el.id === source.index);
          if (job.job_status === 'cancelled' || job.job_status === 'skipped') {
            return notification.error({
              message: 'This job can not be moved ',
            });
          }
          if (workers[srcIndex].vendor_id !== job.vendor_id) {
            return notification.error({
              message: 'Job not belongs to this vendor ',
            });
          }
          if (job.checkin_time || job.checkout_time) {
            return notification.error({
              message: 'This job can not be moved ',
            });
          }
          const body = {
            job_id: job.id,
            vendor_id: job.vendor_id,
            assigned_worker_id: workers[destIndex].id,
            service_date: job.service_date,
            assigned_time: serviceHour,
          };
          reassignJobToWorker({
            body,
            destIndex,
            srcIndex,
            job_status: job?.job_status,
          });
        }
      }
    } else {
      if (vendors) {
        let updatedVendors = [...vendors];
        let updatedVendorSelected = { ...vendorSelectedObj };
        if (destination) {
          let updatedVendorJobs = [...updatedVendorSelected['unassigned_jobs']];
          const deletedJob = updatedVendorJobs.splice(source['index'], 1);
          updatedVendorJobs.splice(destination['index'], 0, deletedJob[0]);
          updatedVendorSelected['unassigned_jobs'] = updatedVendorJobs;
          updatedVendors = updatedVendors.map(vendor => {
            if (vendor['id'] === vendorSelected['id']) {
              return { ...vendor, ...vendorSelected };
            } else {
              return vendor;
            }
          });
          this.props.searchVendorsSuccess({ vendors: updatedVendors });
        }
      }
    }
  };

  downloadCsv = () => {
    this.props.downloadCsvJobs();
  };

  render() {
    const {
      collapsed,
      collapsedNotes,
      pickerOpened,
      currentDate,
      // permanentlyReassignedJobsId,
      CURRENT_DATE,
      // permanentReassign,
      // permanentReassignSelectedDay,
      queryString,
    } = this.state;
    const { loadingWorkers, workers, vendors, vendorSelected, totalWorkers } =
      this.props;

    let vendorSelectedObj = null;

    if (vendorSelected && vendors) {
      vendorSelectedObj = vendors.find(item => item['id'] === vendorSelected);
    }

    return (
      <div className="schedule-page">
        <div className="page-header">
          <div className="page-title">
            <h4>Scheduler</h4>
            <Row className="page-date-selector">
              <Col span={7}>
                <Button
                  type="link"
                  className="page-btn"
                  icon={<LeftOutlined />}
                  onClick={() => this.handleDateChange('prev')}
                />
              </Col>
              <Col
                span={10}
                onClick={() => this.handleDatePicker(true)}
                style={{ textAlign: 'center' }}
              >
                <DatePicker
                  bordered={false}
                  allowClear={false}
                  suffixIcon={null}
                  showToday={true}
                  format="YYYY-MM-DD"
                  open={pickerOpened}
                  onOpenChange={this.handleDatePicker}
                  value={currentDate}
                  onChange={this.handleDateChange}
                />
              </Col>
              <Col span={7} style={{ textAlign: 'end' }}>
                <Button
                  type="link"
                  className="page-btn"
                  icon={<RightOutlined />}
                  onClick={() => this.handleDateChange('next')}
                />
              </Col>
            </Row>
          </div>
          <div className="color-legand">
            <Button
              type="link"
              className="page-btn"
              icon={<DownloadOutlined />}
              onClick={() => this.downloadCsv()}
            >
              Download CSV
            </Button>
            <div className="color-box">
              <div className="color-block paused-job">.</div>
              <p>Paused Job</p>
            </div>
            <div className="color-box">
              <div className="color-block draft-job">.</div>
              <p>Draft Job</p>
            </div>
            <div className="color-box">
              <div className="color-block job-status-cancelled">.</div>
              <p>Canceled Job</p>
            </div>
            <div className="color-box">
              <div className="color-block job-counter-title--completed">.</div>
              <p>Completed Job</p>
            </div>
          </div>
        </div>

        <div className="page-content">
          <DragDropContext isDrag onDragEnd={this.onDragEnd}>
            {/* <JobContext.Provider
              value={{
                permanentReassign,
                permanentReassignSelectedDay,
                checkboxOnChange: this.checkboxOnChange,
                handlePermReassignSelectedDay: this
                  .handlePermReassignSelectedDay,
              }}
            > */}
            <Suspense
              fallback={<LoadingOutlined className="fallback-loader" />}
            >
              <VendorList
                allowUpdate={currentDate.isSame(CURRENT_DATE, 'date')}
                // reassignedJobIds={permanentlyReassignedJobsId}
                setSchedulerState={this.setState.bind(this)}
                collapsed={collapsed}
                currentDate={currentDate}
                handleCollapse={this.handleCollapse}
                handleReload={this.handleReload}
                reloadShow={this.state.reloadShow}
                // assignedJobsLength={assignedJobsLength}
              />
            </Suspense>
            <Suspense
              fallback={<LoadingOutlined className="fallback-loader" />}
            >
              <NotesDrawer
                collapsed={collapsedNotes}
                handleCollapse={this.handleCollapseNotes}
              />
            </Suspense>
            {/* </JobContext.Provider> */}
            <div className="schedule-content">
              <div className="main-content">
                <TableSearch
                  placeholder="Search on skill and name"
                  btnText="Filter"
                  onBtnClick={this.onSearch}
                  value={queryString}
                  onInputChange={ev =>
                    this.setState({ queryString: ev['target']['value'] })
                  }
                />
                <div className="schedule-content-header">
                  <div className="schedule-content-header-top">
                    <h3>Worker Schedule</h3>
                    {vendorSelectedObj && (
                      <>
                        <span className="header-dash">-</span>
                        <h4>{vendorSelectedObj.vendor_name}</h4>
                      </>
                    )}
                  </div>
                  <div className="schedule-content-header-bottom">
                    <p>{totalWorkers} Data employees found</p>
                  </div>
                </div>
                <div className="schedule-content-body">
                  {loadingWorkers ? (
                    <div className="loading-overlay">
                      <LoadingOutlined />
                    </div>
                  ) : (
                    workers.map((el, i) => (
                      <Suspense
                        fallback={
                          <LoadingOutlined className="fallback-loader" />
                        }
                      >
                        <WorkerCard
                          key={el.id}
                          allowUpdate={currentDate.isSame(CURRENT_DATE, 'date')}
                          // reassignedJobIds={permanentlyReassignedJobsId}
                          currentDate={currentDate}
                          setSchedulerState={this.setState.bind(this)}
                          index={i}
                          worker={el}
                        />
                      </Suspense>
                    ))
                  )}
                </div>
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

Scheduler.propTypes = {
  loadingWorkers: PropTypes.bool,
  workers: PropTypes.array,
  vendors: PropTypes.array,
  vendorSelected: PropTypes.number,
  currentWorkerPage: PropTypes.number,
  totalWorkerPages: PropTypes.number,
  totalWorkers: PropTypes.number,
  searchVendorsSuccess: PropTypes.func,
  unassignJobFromWorker: PropTypes.func,
  assignJobToWorker: PropTypes.func,
  reassignJobToWorker: PropTypes.func,
  assignWorkerPerm: PropTypes.func,
  searchWorkers: PropTypes.func,
  formatWorkerContent: PropTypes.func,
  downloadCsvJobs: PropTypes.func,
  // getNotes: PropTypes.func,
  // getNewNotes: PropTypes.func,
  // loadingNotes: PropTypes.bool,
  // updateNotes: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loadingWorkers: state.scheduler.loadingWorkers,
    workers: state.scheduler.workers,
    vendors: state.scheduler.vendors,
    vendorSelected: state.scheduler.vendorSelected,
    currentWorkerPage: state.scheduler.currentWorkerPage,
    totalWorkerPages: state.scheduler.totalWorkerPages,
    totalWorkers: state.scheduler.totalWorkers,
    // notes: state.scheduler.notes?.admin_note,
    // newNotes: state.scheduler.newNotes?.admin_note,
    // loadingNotes: state.scheduler.loadingNotes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formatWorkerContent: () => dispatch(formatWorkerContent()),
    handleVendorChange: v => dispatch(handleVendorChange(v)),
    searchWorkers: params => dispatch(searchWorkers(params)),
    assignJobToWorker: payload => dispatch(assignJobToWorker(payload)),
    reassignJobToWorker: payload => dispatch(reassignJobToWorker(payload)),
    unassignJobFromWorker: payload => dispatch(unassignJobFromWorker(payload)),
    assignWorkerPerm: (id, data, permanentReassignSelectedDay) =>
      dispatch(assignWorkerPerm({ id, data, permanentReassignSelectedDay })),
    searchVendorsSuccess: payload => dispatch(searchVendorsSuccess(payload)),
    downloadCsvJobs: () => dispatch(downloadCsvJobs()),
    // getNotes: () => dispatch(getNotes()),
    // getNewNotes: () => dispatch(getNewNotes()),
    // updateNotes: payload => dispatch(updateNotes(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
