import { createSlice } from '@reduxjs/toolkit';
import {
  checkToken,
  checkVendorToken,
  checkImpVendorToken,
} from '../../utils/apisauce';
import { apiError } from '../../utils/notification';
import { notification } from 'antd';

const slice = createSlice({
  name: 'login',
  initialState: {
    loading: false,
    username: '',
    password: '',
    error: '',
    profile: null,
    responseAuth: {},
    verificationPage: false,
    authenticated: checkToken(),
    number: '',
  },
  reducers: {
    otpCheck: state => {
      state;
    },
    otpCheckSuccess: (state, { payload }) => {
      state.loading = false;
      const vendorToken = checkVendorToken();
      const impVendorToken = checkImpVendorToken();
      if (vendorToken) localStorage.removeItem('vendor_access_token');
      if (impVendorToken) {
        window.location.replace('/admin/vendors');
      }
      localStorage.setItem(
        'access_token',
        state.responseAuth?.meta?.auth?.access_token
      );
      localStorage.getItem('routes') && localStorage.removeItem('routes');
      state.authenticated = checkToken();
      if (payload?.data) {
        notification.success({
          message: 'Verified.',
          description: payload?.data?.message,
        });
      }
    },
    otpCheckFailed: (state, { payload }) => {
      state.loading = false;
      state.authenticated = false;
      state.error = payload;
      apiError(payload);
    },
    login: (state, { payload }) => {
      state.loading = true;
      state.username = payload.username;
      state.password = payload.password;
      state.verificationPage = false;
    },
    loginSuccess: (state, { payload }) => {
      state.loading = false;
      state.verificationPage = true;
      state.number = payload?.admin_user?.mobile;
      notification.success({
        message: 'OTP',
        description: payload?.meta?.otp_details?.message,
      });
      state.responseAuth = payload;
    },
    loginFailed: (state, { payload }) => {
      state.loading = false;
      state.authenticated = false;
      state.verificationPage = false;
      state.error = payload;
      state.modalOpened = false;
      apiError(payload);
    },
    getProfile: state => {
      state.loading = true;
    },
    getProfileSuccess: (state, { payload }) => {
      state.loading = false;
      state.authenticated = true;
      state.profile = payload;
    },
    getProfileFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
  },
});

export const {
  otpCheck,
  otpCheckSuccess,
  otpCheckFailed,
  login,
  loginSuccess,
  loginFailed,
  getProfile,
  getProfileSuccess,
  getProfileFailed,
} = slice.actions;
export default slice.reducer;
