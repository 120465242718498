import {
  // CloseOutlined,
  // DeleteFilled,
  // EditFilled,
  LeftOutlined,
  // PlusOutlined,
  // ProfileOutlined,
  // UserOutlined,
} from '@ant-design/icons';
import {
  // Avatar,
  // Button,
  Card,
  // Drawer,
  // Input,
  // Modal,
  // Select,
  // TimePicker,
  // Checkbox,
  // Form,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { dig } from 'digdata';
// import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import PropTypes from 'prop-types';

import DataTable from '../../components/DataTable';
// import { getImageURI } from '../../utils/apisauce';
import { getTransaction } from './reducers';
import './styles.css';
// import AvatarUpload from '../../components/AvatarUpload';
// import { Formik } from 'formik';
// import { formInitialValues } from '../../utils/formsInitialValues';
// import { formValidations } from '../../utils/formValidations';

// to set 00.00.00 to remove error from trying to format null which is not a time value on DatePicker
const defaultTime = () => {
  return moment().startOf('day').format('HH:mm');
};

class AccountTransactionPage extends React.Component {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: null,
    showPassword: false,
    pageQuery: {
      page: 1,
      per: 50,
      query: null,
    },
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getTransaction,
    } = this.props;
    getTransaction(id);
  }

  componentDidUpdate(prevProps) {
    const { submitting, error } = this.props;

    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  onClickBack = () => {
    const {
      history: { push },
    } = this.props;

    push('/admin/company_accounts');
  };

  // closeModal = () => {
  //   this.setState({
  //     modalOpened: false,
  //     selectedRow: null,
  //     formData: null,
  //   });
  // };

  // onClickRow = (e, record) => {
  //   this.setState({
  //     modalOpened: 1,
  //     selectedRow: record,
  //     formData: null,
  //   });
  // };

  onCreate = () => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: {
        picture: null,
        first_name: '',
        last_name: '',
        nric: '',
        mobile: '',
        email: '',
        country: 'singapore',
        postal_code: '',
        role: 'worker',
        status: 'active',
        start_time: defaultTime(),
        end_time: defaultTime(),
        password: '',
      },
    });
  };

  // onEdit = record => {
  //   this.setState({
  //     modalOpened: 1,
  //     selectedRow: null,
  //     formData: {
  //       id: record.id,
  //       first_name: record.first_name,
  //       last_name: record.last_name,
  //       nric: record.nric,
  //       mobile: record.mobile,
  //       email: record.email,
  //       country: record.country,
  //       postal_code: record.postal_code,
  //       status: record.status,
  //       role: record.role,
  //       start_time: record.start_time,
  //       end_time: record.end_time,
  //       picture_path: record.picture_path,
  //       password: '',
  //     },
  //   });
  // };

  // onDelete = record => {
  //   this.setState({
  //     modalOpened: 2,
  //     selectedRow: record,
  //     formData: null,
  //   });
  // };

  // onSubmit = values => {
  //   const {
  //     match: {
  //       params: { id },
  //     },
  //     updateUser,
  //     createUser,
  //   } = this.props;

  //   const { formData } = this.state;

  //   if (formData.id) {
  //     updateUser({
  //       vendor_id: id,
  //       vendor_user: { ...formData, ...values },
  //     });
  //   } else {
  //     createUser({
  //       vendor_id: id,
  //       vendor_user: {
  //         ...formData,
  //         ...values,
  //         picture: formData['picture'],
  //       },
  //     });
  //   }
  //   // this.closeModal();
  // };

  // performDelete = () => {
  //   const { selectedRow } = this.state;
  //   const {
  //     match: {
  //       params: { id },
  //     },
  //     deleteUser,
  //   } = this.props;

  //   deleteUser(id, selectedRow.id);
  //   this.closeModal();
  // };

  // onInputChange = ({ target: { name, value } }) => {
  //   const formData = { ...this.state.formData };

  //   formData[name] = value;
  //   this.setState({ formData });
  // };

  tableColumns = () => [
    {
      title: 'Transaction ID',
      key: 'transaction_id',
      dataIndex: 'trx_id',
    },
    {
      title: 'Created At',
      key: 'created_at',
      render: (text, record) =>
        record['created_at']
          ? `${new Date(record['created_at']).toLocaleString()}`
          : '',
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'formatted_amount',
    },
  ];

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };

  render() {
    const {
      transactions,
      loading,
      location: {
        state: { accountTitle },
      },
    } = this.props;
    const {
      // modalOpened,
      // selectedRow,
      // formData,
      pageQuery,
      // showPassword,
    } = this.state;

    return (
      <div className="vendor-profile-page">
        <div className="page-header">
          <div className="page-title">
            <LeftOutlined onClick={this.onClickBack} />
            <h4>{accountTitle} ACCOUNT</h4>
          </div>
        </div>
        <div className="page-content">
          <Card title="Transactions">
            <DataTable
              rowKey={record => record['id']}
              loading={loading}
              data={transactions}
              columns={this.tableColumns()}
              // onClickRow={this.onClickRow}
              pagination={{
                limit: pageQuery['per'],
                total: transactions['length'],
                page: pageQuery['page'],
                data: transactions,
                onChange: this.onPageChange,
              }}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        </div>
      </div>
    );
  }
}

AccountTransactionPage.propTypes = {
  loading: PropTypes.bool,
  transactions: PropTypes.object,
  getTransaction: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  location: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.companyAccountTransaction.loading,
    transactions: state.companyAccountTransaction.transactions,
    submitting: state.companyAccountTransaction.submitting,
    error: state.companyAccountTransaction.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTransaction: id => dispatch(getTransaction(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(AccountTransactionPage)));
