import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { dig } from 'digdata';
import { useSelector } from 'react-redux';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Avatar,
  Drawer,
  Select,
  Input,
  Button,
  Checkbox,
  Form,
  Tag,
  TreeSelect,
} from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Formik } from 'formik';
import AvatarUpload from '../../../components/AvatarUpload';
import { getImageURI } from '../../../utils/apisauce';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';
import { localStore } from '../reducers';
import SideBarMenuList from '../../../routes';
import {
  DeleteFilledIcon,
  EditFilledIcon,
} from '../../../components/Icons/Icons';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

let treeData = [
  {
    title: 'All',
    value: 'all',
    key: 'all',
    children: [],
  },
];

CreateDrawer.propTypes = {
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  showDeleteModal: PropTypes.func,
  showEditModal: PropTypes.func,
  visible: PropTypes.bool,
  selected: PropTypes.object,
  formData: PropTypes.object,
};

function CreateDrawer({
  visible,
  onChange,
  onUpdate,
  onCreate,
  onClose,
  showEditModal,
  showDeleteModal,
  selected,
  formData,
}) {
  const { submitting } = useSelector(store => localStore(store));
  const [showPassword, setShowPassword] = useState(false);
  const updatedData = { ...formData };

  if (updatedData && updatedData['role_permission']) {
    updatedData['role_name'] = updatedData['role_permission']['name'];

    if (updatedData['role_permission']['permissions']) {
      updatedData['selected_tabs'] = Object.keys(
        updatedData['role_permission']['permissions']
      );
    }
  }

  const onInputChange = ({ target: { name, value } }) => {
    formData[name] = value;
    onChange({ ...formData });
  };

  const makePayload = values => {
    let payload = {
      admin_user: {
        first_name: values['first_name'],
        last_name: values['last_name'],
        email: values['email'],
        mobile: `+${values['mobile']}`,
        password: values['password'],
        picture: formData['picture'],
        role: formData['role'],
        status: values['status'],
      },
    };

    if (formData.id) {
      payload['admin_user']['id'] = formData['id'];
    }

    if (values['selected_tabs'] && values['selected_tabs']['length']) {
      let permissions = {};
      values['selected_tabs'].map(selectedTab => {
        permissions[selectedTab] = {
          actions: ['view'],
        };
        return null;
      });
      payload['role_permission'] = {
        name: values['role_name'],
        permissions,
      };
    }

    return payload;
  };

  const handleOnSubmit = values => {
    if (formData.id) {
      onUpdate(makePayload(values));
    } else {
      onCreate(makePayload(values));
    }
    // onClose();
  };

  let permittedTabs = [];

  if (
    selected &&
    selected['role_permission'] &&
    selected['role_permission']['permissions']
  ) {
    const tabsList = Object.keys(selected['role_permission']['permissions']);
    permittedTabs = tabsList.map(tab => tab.replace(/_/g, ' '));
  }

  const FilteredSideBarMenuList = SideBarMenuList.filter(el => el['menu']);

  if (FilteredSideBarMenuList && FilteredSideBarMenuList.length) {
    let childList = [];
    FilteredSideBarMenuList.map(item => {
      const value = `${item['menu']['name']
        .replace(/ /g, '_')
        .toLocaleLowerCase()}_tab`;
      const tab_name = item['menu']['name'];
      childList.push({
        title: tab_name,
        value: value,
        key: value,
      });
      return null;
    });
    treeData[0]['children'] = childList;
  }

  return (
    <Drawer
      closable={false}
      placement="right"
      width={450}
      visible={visible}
      onClose={onClose}
    >
      {selected ? (
        <Fragment>
          <div className="drawer-header">
            <h3>Admin Detail</h3>
            <div className="header-actions">
              <EditFilledIcon
                className="action-primary"
                onClick={() => showEditModal(selected)}
              />
              <CloseOutlined onClick={onClose} />
            </div>
          </div>
          <div className="drawer-body">
            <div className="user-avatar">
              <Avatar
                src={getImageURI(dig(selected, 'picture_path.url'))}
                icon={<UserOutlined />}
                size={100}
              />
              <div className="username">
                {selected.first_name}&nbsp;{selected.last_name}
              </div>
              <div className="user-status">
                <span className={`dot ${selected.status}`} />
                <span>{selected.status}</span>
              </div>
            </div>
            <div className="user-info-row">
              <label>Employee ID</label>
              <span>{selected.id}</span>
            </div>
            <div className="user-info-row">
              <label>Phone Number</label>
              <span>{selected.mobile}</span>
            </div>
            <div className="user-info-row">
              <label>Email Address</label>
              <span>{selected.email}</span>
            </div>
            <div className="user-info-row">
              <label>Tab Permissions</label>
              <div className="permission-tabs-container">
                {permittedTabs.map((permittedTab, i) => (
                  <Tag key={i} className="permission-tab">
                    {permittedTab}
                  </Tag>
                ))}
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        formData && (
          <Fragment>
            <div className="drawer-header">
              <h3>{formData.id ? 'Edit Admin' : 'Create New Admin'}</h3>
              <div className="header-actions">
                {formData.id && (
                  <DeleteFilledIcon
                    className="action-primary"
                    onClick={() => showDeleteModal(formData)}
                  />
                )}
                <CloseOutlined onClick={onClose} />
              </div>
            </div>
            <div className="drawer-body">
              <Formik
                initialValues={{
                  ...formInitialValues['createAdminForm'],
                  ...updatedData,
                }}
                validationSchema={
                  updatedData.id
                    ? formValidations['UpdateAdminFormValidation']
                    : formValidations['CreateAdminFormValidation']
                }
                onSubmit={values => handleOnSubmit(values)}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => {
                  const tProps = {
                    treeData,
                    value: values.selected_tabs,
                    onChange: value => {
                      handleChange({
                        target: { name: 'selected_tabs', value },
                      });
                    },
                    treeCheckable: true,
                    showCheckedStrategy: SHOW_PARENT,
                    placeholder: 'Select Tab(s)',
                    style: {
                      width: '100%',
                    },
                  };

                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="user-avatar">
                        <AvatarUpload
                          value={
                            formData['picture_path']
                              ? getImageURI(dig(formData, 'picture_path.url'))
                              : null
                          }
                          onChange={value =>
                            onInputChange({
                              target: {
                                name: 'picture',
                                value: { data: value },
                              },
                            })
                          }
                          accept=".png, .jpg, .jpeg"
                        />
                      </div>
                      {formData.id && (
                        <div className="form-group">
                          <label>Employee ID</label>
                          <span>{formData.id}</span>
                        </div>
                      )}
                      <div className="form-group">
                        <label>First Name</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['first_name'] && errors['first_name']
                              ? 'error'
                              : null
                          }
                          help={touched['first_name'] && errors['first_name']}
                          style={{ margin: 0 }}
                        >
                          <Input
                            name="first_name"
                            value={values.first_name}
                            placeholder="Enter first name"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>Last Name</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['last_name'] && errors['last_name']
                              ? 'error'
                              : null
                          }
                          help={touched['last_name'] && errors['last_name']}
                          style={{ margin: 0 }}
                        >
                          <Input
                            name="last_name"
                            value={values.last_name}
                            placeholder="Enter last name"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>Status</label>
                        <Select
                          style={{ width: '100%' }}
                          value={formData.status}
                          onChange={value =>
                            onInputChange({
                              target: { name: 'status', value },
                            })
                          }
                        >
                          <Option value="active">Active</Option>
                          <Option value="inactive">Inactive</Option>
                          <Option value="suspended">Suspended</Option>
                        </Select>
                      </div>
                      <div className="form-group">
                        <label>Phone Number</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['mobile'] && errors['mobile']
                              ? 'error'
                              : null
                          }
                          help={touched['mobile'] && errors['mobile']}
                          style={{ margin: 0 }}
                        >
                          <PhoneInput
                            defaultCountry={'sg'}
                            inputStyle={{ width: '100%' }}
                            value={values.mobile}
                            onChange={value =>
                              handleChange({
                                target: { name: 'mobile', value },
                              })
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['email'] && errors['email'] ? 'error' : null
                          }
                          help={touched['email'] && errors['email']}
                          style={{ margin: 0 }}
                        >
                          <Input
                            type="email"
                            name="email"
                            value={values.email}
                            placeholder="Enter email"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                      {formData.id && (
                        <Checkbox
                          style={{ margin: '1em 0' }}
                          onChange={() => setShowPassword(!showPassword)}
                        >
                          Change Password
                        </Checkbox>
                      )}
                      {(formData.id && showPassword) || !formData.id ? (
                        <div className="form-group">
                          <label>New Password</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['password'] && errors['password']
                                ? 'error'
                                : null
                            }
                            help={touched['password'] && errors['password']}
                            style={{ margin: 0 }}
                          >
                            <Input.Password
                              name="password"
                              value={values.password}
                              placeholder="Set New Password"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                      ) : null}
                      <h3 className="role-perm-heading">Role Permission</h3>
                      <div className="form-group">
                        <label>Role Name</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['role_name'] && errors['role_name']
                              ? 'error'
                              : null
                          }
                          help={touched['role_name'] && errors['role_name']}
                          style={{ margin: 0 }}
                        >
                          <Input
                            name="role_name"
                            value={values.role_name}
                            placeholder="Enter role name"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>Accessible Tab(s)</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['selected_tabs'] && errors['selected_tabs']
                              ? 'error'
                              : null
                          }
                          help={
                            touched['selected_tabs'] && errors['selected_tabs']
                          }
                          style={{ margin: 0 }}
                        >
                          <TreeSelect {...tProps} />
                          {/* <Select
                            style={{ width: '100%' }}
                            value={values.selected_tabs}
                            mode="multiple"
                            placeholder="Select Tab(s)"
                            onChange={value => {
                              handleChange({
                                target: { name: 'selected_tabs', value },
                              });
                            }}
                          >
                            {SideBarMenuList.filter(el => el['menu']).map(
                              el => {
                                const value = `${el['menu'][
                                  'name'
                                ].toLocaleLowerCase()}_tab`;
                                const tab_name = el['menu']['name'];
                                return (
                                  <Option key={value} value={value}>
                                    {tab_name}
                                  </Option>
                                );
                              }
                            )}
                          </Select> */}
                        </Form.Item>
                      </div>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={submitting}
                        loading={submitting}
                      >
                        Save
                      </Button>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </Fragment>
        )
      )}
    </Drawer>
  );
}

export default React.memo(CreateDrawer);
