import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getSettings,
  getSettingsSuccess,
  getSettingsFailed,
  createSettings,
  createSettingsSuccess,
  createSettignsFailed,
} from './reducer';

function* getSettingsSaga() {
  let action;
  let url = 'api/v1/admin/system_configurations';
  try {
    const response = yield call(request, 'GET', url);
    action = getSettingsSuccess(response);
  } catch (error) {
    action = getSettingsFailed(error.message);
  }
  yield put(action);
}

function* createSettingsSaga({ payload }) {
  let action;
  let url = '/api/v1/admin/system_configurations';
  try {
    const response = yield call(request, 'POST', url, {
      system_configuration: payload,
    });
    action = createSettingsSuccess(response);
  } catch (error) {
    action = createSettignsFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getSettings, getSettingsSaga);
  yield takeLatest(createSettings, createSettingsSaga);
}
