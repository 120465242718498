import { LeftOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import DataTable from '../../../components/DataTable';
import { getTransaction } from './reducers';
import './styles.css';
import { capitalize } from '../../../utils/apisauce';

class VendorTransactions extends React.Component {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: null,
    showPassword: false,
    pageQuery: {
      page: 1,
      per: 50,
      query: null,
    },
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getTransaction,
    } = this.props;
    getTransaction(id);
  }

  onClickBack = () => {
    const {
      history: { push },
    } = this.props;

    push('/admin/vendors');
  };

  tableColumns = () => [
    {
      title: <span className="table-column-title">Transaction ID</span>,
      key: 'trx_id',
      dataIndex: 'trx_id',
      render: (text, record) => record['trx_id'],
    },
    {
      title: <span className="table-column-title">Created At</span>,
      key: 'created_at',
      render: (text, record) =>
        record['created_at']
          ? `${new Date(record['created_at']).toLocaleString()}`
          : '',
    },
    {
      title: <span className="table-column-title">Charged ID (stripe)</span>,
      key: 'stripe_charge_id',
      dataIndex: 'stripe_charge_id',
      render: (text, record) => record['stripe_charge_id'],
    },
    {
      title: <span className="table-column-title">Invoice ID (stripe)</span>,
      key: 'stripe_invoice_id',
      dataIndex: 'stripe_invoice_id',
      width: 150,
      render: (text, record) => record['stripe_invoice_id'],
    },
    {
      title: <span className="table-column-title">Transfer To</span>,
      key: 'receiver',
      width: 150,
      render: (text, record) =>
        record?.transfer?.to_account?.accountable?.full_name
          ? capitalize(record.transfer.to_account.accountable.full_name)
          : '-',
    },
    {
      title: <span className="table-column-title">Order Type</span>,
      key: 'order_type',
      dataIndex: 'order_type',
      render: (text, record) =>
        record?.order?.order_type ? capitalize(record?.order?.order_type) : '-',
    },
    {
      title: <span className="table-column-title">Order ID</span>,
      key: 'orderId',
      render: (text, record) => {
        return record['order'] ? record['order'].id : '-';
      },
    },
    {
      title: <span className="table-column-title">Amount</span>,
      key: 'formatted_amount',
      dataIndex: 'formatted_amount',
      render: (text, record) => record['formatted_amount'],
    },
    {
      title: <span className="table-column-title">Running Balance</span>,
      key: 'formatted_running_balance',
      dataIndex: 'formatted_running_balance',
      render: (text, record) => record['formatted_running_balance'],
    },
  ];

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };

  render() {
    const { transactions, loading } = this.props;

    const {
      // modalOpened,
      // selectedRow,
      // formData,
      pageQuery,
      // showPassword,
    } = this.state;
    const clientName = this.props.history.location.state.clientName;

    return (
      <div className="vendor-profile-page">
        <div className="page-header">
          <div className="page-title">
            <LeftOutlined onClick={this.onClickBack} />
            <h4>{capitalize(clientName)}</h4>
          </div>
        </div>
        <div className="page-content">
          <Card title="Transactions">
            <DataTable
              rowKey={record => record['id']}
              loading={loading}
              data={transactions}
              columns={this.tableColumns()}
              // onClickRow={this.onClickRow}
              pagination={{
                limit: pageQuery['per'],
                total: transactions['length'],
                page: pageQuery['page'],
                data: transactions,
                onChange: this.onPageChange,
              }}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        </div>
      </div>
    );
  }
}

VendorTransactions.propTypes = {
  loading: PropTypes.bool,
  transactions: PropTypes.object,
  getTransaction: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    loading: state.vendorTrans.loading,
    transactions: state.vendorTrans.transactions,
    submitting: state.vendorTrans.submitting,
    error: state.vendorTrans.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTransaction: id => dispatch(getTransaction(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(VendorTransactions)));
