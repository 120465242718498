import { ProfileOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { dig } from 'digdata';
// import { getImageURI } from "../../../utils/apisauce";
import BusinessAddress from './businessAddress';
import BusinessUsers from './businessUsers';
import { getClient } from '../reducers';
import PropTypes from 'prop-types';

class BusinessProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'user',
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getClient,
    } = this.props;

    getClient(id);
  }

  onClickTab = tab => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const { activeTab } = this.state;
    const { client } = this.props;

    return (
      <>
        <div className="extra-header">
          <div className="client-info">
            <div className="user-avatar">
              <div className="user-avatar-picture">
                <Avatar
                  // src={getImageURI(
                  //   dig(client["personal_users"][0], "picture_path.url")
                  // )}
                  icon={<ProfileOutlined />}
                  size={80}
                />
              </div>
              <div className="user-avatar-info">
                <div className="user-name">{client.business_name}</div>
                <div className="client-id">Client ID: {client.id}</div>
              </div>
            </div>
            <div className="user-info-block">
              <label>Registration Number</label>
              <span>{client.uen}</span>
            </div>
            <div className="user-info-block">
              <label>Phone Number</label>
              <span>{client.phone}</span>
            </div>
            <div className="user-info-block">
              <label>Website</label>
              <span>{client.website}</span>
            </div>
            <div className="user-info-block">
              <label>Total User</label>
              <span>
                {client['business_users'] ? client['business_users'].length : 0}
              </span>
            </div>
          </div>

          <div className="ant-tabs-nav ant-tabs-nav-animated">
            <div
              className={`ant-tabs-tab${
                activeTab === 'user' ? ' ant-tabs-tab-active ' : ''
              }`}
              onClick={() => this.onClickTab('user')}
            >
              User List
            </div>
            <div
              className={`ant-tabs-tab${
                activeTab === 'address' ? ' ant-tabs-tab-active ' : ''
              }`}
              onClick={() => this.onClickTab('address')}
            >
              Address Book
            </div>
          </div>
        </div>
        {activeTab === 'user' ? <BusinessUsers /> : <BusinessAddress />}
      </>
    );
  }
}

BusinessProfile.propTypes = {
  client: PropTypes.object,
  match: PropTypes.object,
  getClient: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    client: state.client.client,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClient: id => dispatch(getClient({ type: 'business', id })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(BusinessProfile)));
