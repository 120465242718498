import React from 'react';
import { CloseOutlined, DeleteFilled, UserOutlined } from '@ant-design/icons';
import { Button, Input, Form, Radio, Card, Avatar } from 'antd';
import PhoneInput from 'react-phone-input-2';
import { dig } from 'digdata';
import PropTypes from 'prop-types';
import AvatarUpload from '../../../components/AvatarUpload';
import { getImageURI } from '../../../utils/apisauce';
import { Formik } from 'formik';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';

function CreateVendor(props) {
  const {
    formData,
    onDelete,
    closeModal,
    onInputChange,
    onSubmit,
    loading,
    disabled,
    libraryImage,
  } = props;

  return (
    <>
      <div className="drawer-header">
        <h3>{formData.id ? 'Edit Vendor' : 'Add Vendor'}</h3>
        <div className="header-actions">
          {formData.id && (
            <DeleteFilled
              className="action-primary"
              onClick={() => onDelete(formData)}
            />
          )}
          <CloseOutlined onClick={closeModal} />
        </div>
      </div>
      <div className="drawer-body">
        <Formik
          initialValues={{
            ...formInitialValues['createVendorForm'],
            ...formData,
          }}
          validationSchema={formValidations['CreateVendorFormValidation']}
          onSubmit={values => onSubmit(values)}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Radio.Group
                  defaultValue={values.image_type}
                  onChange={handleChange}
                  name="image_type"
                  buttonStyle="solid"
                  style={{ marginBottom: '10px' }}
                >
                  <Radio.Button name="image_type" value={'attached'}>
                    Upload Image
                  </Radio.Button>
                  <Radio.Button name="image_type" value={'library'}>
                    Choose From Library
                  </Radio.Button>
                </Radio.Group>
                {values.image_type === 'attached' ? (
                  <div className="user-avatar">
                    <AvatarUpload
                      value={
                        formData['picture_path']
                          ? getImageURI(dig(formData, 'picture_path.url'))
                          : null
                      }
                      icon={<UserOutlined />}
                      onChange={value =>
                        onInputChange({
                          target: {
                            name: 'picture',
                            value: { data: value },
                          },
                        })
                      }
                      accept=".png, .jpg, .jpeg"
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      height: '250px',
                      overflowY: 'scroll',
                      textAlign: 'center',
                    }}
                  >
                    <Radio.Group
                      onChange={handleChange}
                      size="large"
                      defaultValue={formData['picture_path']?.library_image_id}
                      name="mediaImageId"
                    >
                      {libraryImage?.map(item => (
                        <Radio
                          key={item?.id}
                          className="radioImage"
                          name="mediaImageId"
                          value={item?.id}
                        >
                          <Card>
                            <Avatar
                              src={getImageURI(item?.image_path?.url)}
                              size={100}
                            />
                          </Card>
                        </Radio>
                      ))}
                    </Radio.Group>
                  </div>
                )}
                {formData.id && (
                  <div className="form-group">
                    <label>Vendor ID</label>
                    <span>{formData.id}</span>
                  </div>
                )}
                <div className="form-group">
                  <label>Vendor Name</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['vendor_name'] && errors['vendor_name']
                        ? 'error'
                        : null
                    }
                    help={touched['vendor_name'] && errors['vendor_name']}
                    style={{ margin: 0 }}
                  >
                    <Input
                      name="vendor_name"
                      value={values.vendor_name}
                      placeholder="Enter vendor name"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Registration Number</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['company_reg'] && errors['company_reg']
                        ? 'error'
                        : null
                    }
                    help={touched['company_reg'] && errors['company_reg']}
                    style={{ margin: 0 }}
                  >
                    <Input
                      name="company_reg"
                      value={values.company_reg}
                      placeholder="Enter registration number"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Website</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['website'] && errors['website'] ? 'error' : null
                    }
                    help={touched['website'] && errors['website']}
                    style={{ margin: 0 }}
                  >
                    <Input
                      name="website"
                      value={values.website}
                      placeholder="Enter website"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Contact Number</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['contact'] && errors['contact'] ? 'error' : null
                    }
                    help={touched['contact'] && errors['contact']}
                    style={{ margin: 0 }}
                  >
                    <PhoneInput
                      defaultCountry={'sg'}
                      inputStyle={{ width: '100%' }}
                      value={values.contact}
                      onChange={value =>
                        handleChange({
                          target: { name: 'contact', value },
                        })
                      }
                    />
                  </Form.Item>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  disabled={disabled}
                >
                  Save
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

CreateVendor.propTypes = {
  formData: PropTypes.object,
  onDelete: PropTypes.func,
  closeModal: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default React.memo(CreateVendor);
