import { LeftOutlined } from '@ant-design/icons';
import { Card, Table, Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { capitalize } from '../../../utils/apisauce';
import DataTable from '../../../components/DataTable';
import { getTransaction } from './reducers';
import './styles.css';

class GroupedTrans extends React.Component {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: null,
    showPassword: false,
    pageQuery: {
      page: 1,
      per: 50,
      query: null,
    },
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getTransaction,
    } = this.props;
    getTransaction(id);
  }

  onClickBack = () => {
    const {
      history: { push },
    } = this.props;

    push('/admin/company_accounts');
  };

  tableColumns = () => [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      fixed: 'left',
    },
    {
      title: 'Created At',
      key: 'created_at',
      render: (text, record) =>
        record['created_at']
          ? `${new Date(record['created_at']).toLocaleString()}`
          : '',
    },
    {
      title: <span className="table-column-title">Placement ID </span>,
      key: 'p_id',
      render: (text, record) =>
        record['placement'] ? `${record['placement'].id}` : '',
    },
    {
      title: <span className="table-column-title">Placement Date </span>,
      key: 'p_date',
      render: (text, record) =>
        record['placement']
          ? `${new Date(record['placement'].created_at).toLocaleString()}`
          : '',
    },
    {
      title: 'Buyer',
      key: 'buyer_name',
      render: (text, record) =>
        record['buyer']?.full_name
          ? `${capitalize(record['buyer'].full_name)}`
          : '',
    },
    {
      title: 'Order Type',
      key: 'order_type',
      render: (text, record) =>
        record['order_type'] ? `${capitalize(record['order_type'])}` : '',
    },
    {
      title: 'Net Amount',
      key: 'formatted_net_amount',
      dataIndex: 'formatted_net_amount',
    },
    {
      title: 'Amount',
      key: 'formatted_amount',
      dataIndex: 'formatted_amount',
    },
    {
      title: 'GST Amoumt',
      key: 'tax_amount',
      dataIndex: 'formatted_tax_amount',
    },
    {
      title: 'Commission Amount',
      key: 'commission_detail',
      fixed: 'right',
      render: (text, record) =>
        record['commission_detail']?.formatted_total_amount
          ? `${record['commission_detail'].formatted_total_amount}`
          : '-',
    },
  ];

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };

  expandedRowRender = record => {
    const columns = [
      {
        title: 'Transaction ID',
        key: 'transaction_id',
        dataIndex: 'trx_id',
      },
      {
        title: 'Created At',
        key: 'created_at',

        render: (text, record) =>
          record['created_at']
            ? `${new Date(record['created_at']).toLocaleString()}`
            : '',
      },
      {
        title: 'Description',
        key: 'desc',

        dataIndex: 'description',
        render: description => (
          <Tooltip placement="topLeft" title={description}>
            {capitalize(description).substring(0, 20)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
              ...Read more
            </span>
          </Tooltip>
        ),
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'formatted_amount',
      },
    ];

    return (
      <Table
        size="small"
        scroll={{ x: 'max-content' }}
        columns={columns}
        dataSource={record.accounting_transactions}
        pagination={false}
      />
    );
  };

  render() {
    const {
      orders,
      loading,
      location: {
        state: { accountTitle },
      },
    } = this.props;
    const {
      // modalOpened,
      // selectedRow,
      // formData,
      pageQuery,
      // showPassword,
    } = this.state;

    return (
      <div className="vendor-profile-page">
        <div className="page-header">
          <div className="page-title">
            <LeftOutlined onClick={this.onClickBack} />
            <h4>{accountTitle} ACCOUNT</h4>
          </div>
        </div>
        <div className="page-content">
          <Card title="Transactions">
            <DataTable
              rowKey={record => record['id']}
              loading={loading}
              data={orders}
              columns={this.tableColumns()}
              // onClickRow={this.onClickRow}
              pagination={{
                limit: pageQuery['per'],
                total: orders['length'],
                page: pageQuery['page'],
                data: orders,
                onChange: this.onPageChange,
              }}
              handleTableChange={this.handleTableChange}
              expandedRowRender={this.expandedRowRender}
            />
          </Card>
        </div>
      </div>
    );
  }
}

GroupedTrans.propTypes = {
  loading: PropTypes.bool,
  orders: PropTypes.object,
  getTransaction: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  location: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.groupedCompanyTrans.loading,
    orders: state.groupedCompanyTrans.orders,
    submitting: state.groupedCompanyTrans.submitting,
    error: state.groupedCompanyTrans.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTransaction: id => dispatch(getTransaction(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(GroupedTrans)));
