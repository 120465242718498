import { Card, Tooltip, Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DataTable from '../../../components/DataTable';
import { withRouter } from 'react-router-dom';
import { getOrders } from '../reducers';
import { capitalize } from '../../../utils/apisauce';
import { InfoCircleOutlinedIcon } from '../../../components/Icons/Icons';

const ColumnLink = ({ onClick, text }) =>
  typeof text === 'string' && text?.toLowerCase() === 'detail' ? (
    <Tooltip title="detail">
      <InfoCircleOutlinedIcon onClick={e => e.stopPropagation() || onClick()} />
    </Tooltip>
  ) : (
    <Button type="link" onClick={onClick}>
      {text}
    </Button>
  );

ColumnLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageQuery: {
        page: 1,
        per: 20,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { id },
      },
      getOrders,
    } = this.props;

    const { pageQuery } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      getOrders({ ...pageQuery, id: id });
    }
  }

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;

    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };

  tableColumns = showjobDetailModal => [
    {
      title: <span className="table-column-title">ID</span>,
      key: 'id',
      fixed: 'left',
      dataIndex: 'id',
      align: 'center',
      render: (text, record) => {
        return record['id'];
      },
    },
    {
      title: <span className="table-column-title">Created Date</span>,
      key: 'created_at',
      render: (text, record) =>
        record['created_at'] ? (
          <Tooltip
            title={`${new Date(record['created_at']).toLocaleString()}`}
            placement="top"
          >
            {capitalize(
              `${new Date(record['created_at']).toLocaleString()}`
            ).substring(0, 10)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>...</span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: <span className="table-column-title">Created By</span>,
      key: 'created_by',
      render: (text, record) =>
        record['buyer']
          ? capitalize(
              record['buyer'].first_name + ' ' + record['buyer'].last_name
            )
          : '-',
    },
    {
      title: <span className="table-column-title">Service Date</span>,
      key: 'service_date',
      render: (text, record) =>
        record['service_date'] === null ? '-' : record['service_date'],
    },
    {
      title: <span className="table-column-title">Preferred Time</span>,
      key: 'preferred_time',
      render: (text, record) =>
        record['preferred_time'] === null ? '-' : record['preferred_time'],
    },
    {
      title: <span className="table-column-title">Start Date</span>,
      key: 'start_subscription_at',
      render: (text, record) =>
        record['start_subscription_at']
          ? new Date(record['start_subscription_at']).toLocaleString()
          : '-',
    },
    {
      title: <span className="table-column-title">Title</span>,
      key: 'title',
      dataIndex: 'title',
      render: (text, record) => capitalize(record['title']) || '-',
    },
    {
      title: <span className="table-column-title">Description</span>,
      key: 'description',
      dataIndex: 'discription',
      render: (text, record) =>
        record['description'] ? (
          <Tooltip placement="topLeft" title={record['description']}>
            {capitalize(record['description']).substring(0, 20)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
              ...Read more
            </span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: <span className="table-column-title">Order Type</span>,
      key: 'order_type',
      width: 120,
      render: (text, record) =>
        record['order_type'] ? capitalize(record['order_type']) : '-',
    },
    {
      title: <span className="table-column-title">Client Type</span>,
      key: 'buyer_type',
      width: 120,
      render: (text, record) =>
        record ? capitalize(record['buyer_type']) : '-',
    },

    {
      title: <span className="table-column-title">Job ID</span>,
      key: 'jobId',
      width: 80,
      align: 'center',
      render: function jobDetail(text, record) {
        return (
          <ColumnLink
            onClick={
              record['job_id'] || record['repeating_job_id']
                ? () => showjobDetailModal(record)
                : null
            }
            text={
              record['job_id']
                ? record['job_id']
                : record['repeating_job_id']
                ? record['repeating_job_id']
                : '-'
            }
          />
        );
      },
    },
    {
      title: <span className="table-column-title">Amount</span>,
      key: 'amount',
      width: 80,
      align: 'center',
      render: (text, record) => (
        <Tooltip
          title={`${record?.amount > 0 ? 'debit' : 'credit'}`}
          placement="top"
          className={`${record?.amount > 0 ? 'debit' : 'credit'}`}
        >
          {record['formatted_amount']
            ? `${
                record?.amount > 0
                  ? `${capitalize(record['formatted_amount'])}`
                  : `(${capitalize(
                      record['formatted_amount'].replace('-', '')
                    )})`
              }`
            : '-'}
        </Tooltip>
      ),
    },
    {
      title: <span className="table-column-title">Adjustment Amount</span>,
      key: 'formatted_adjustment_amount',
      width: 150,
      render: (text, record) => record['formatted_adjustment_amount'],
    },
    {
      title: (
        <span className="table-column-title">
          <Tooltip title="GST Amount">GST</Tooltip>
        </span>
      ),
      render: (text, record) => record['formatted_tax_amount'],
    },
    {
      title: (
        <span className="table-column-title">
          <Tooltip title="Net Amount">Net</Tooltip>
        </span>
      ),
      key: 'net_amount',

      render: (text, record) => record['formatted_net_amount'],
    },

    {
      title: <span className="table-column-title">Status</span>,
      key: 'orderStatus',
      fixed: 'right',
      align: 'center',
      render: (text, record) =>
        record ? capitalize(record['display_status']) : '-',
    },
  ];

  render() {
    const { loading, orders, showjobDetailModal } = this.props;
    const { pageQuery } = this.state;

    const { service_orders } = orders;

    return (
      <>
        <div className="page-sub-header">
          <h4>Order List</h4>
        </div>
        <Card>
          <DataTable
            rowKey={record => record['id']}
            loading={loading}
            data={service_orders}
            pagination={{
              limit: pageQuery.per,
              total: service_orders?.length,
              page: pageQuery.page,
              data: service_orders,
              onChange: this.onPageChange,
            }}
            handleTableChange={this.handleTableChange}
            columns={this.tableColumns(showjobDetailModal)}
          />
        </Card>
      </>
    );
  }
}

OrderDetails.propTypes = {
  loading: PropTypes.bool,
  orders: PropTypes.object,
  getOrders: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
  id: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    loading: state.orderDetail.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrders: payload => dispatch(getOrders(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(OrderDetails)));
