import './styles.css';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Button, Card } from 'antd';
import { PlusOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { dig } from 'digdata';
import TableSearch from '../../components/TableSearch';
const DeleteModal = lazy(() => import('./components/delete-modal'));
const CreateDrawer = lazy(() => import('./components/create-drawer'));
const DataTable = lazy(() => import('../../components/DataTable'));
import {
  localStore,
  getMedia,
  getAdmins,
  createAdmin,
  updateAdmin,
  deleteAdmin,
} from './reducers';
import { getImageURI, capitalize, useWindowSize } from '../../utils/apisauce';
import {
  DeleteFilledIcon,
  EditFilledIcon,
  EyeFilledIcon,
} from '../../components/Icons/Icons';
const MobileTable = lazy(() => import('./components/MobTable'));

const defaultUser = {
  picture: null,
  first_name: '',
  last_name: '',
  email: '',
  mobile: '',
  role: 'admin',
  status: 'active',
  password: '',
  image_type: 'attached',
};

function tableColumns(showEdit, showDelete, selectRow) {
  return [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      fixed: 'left',
      width: 80,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Name',
      key: 'name',
      width: 240,
      render: function adminDisplay(_, record) {
        return (
          <span>
            <Avatar
              src={getImageURI(dig(record, 'picture_path.url'))}
              icon={<UserOutlined />}
            />
            &nbsp; {capitalize(record.first_name)}{' '}
            {capitalize(record.last_name)}
          </span>
        );
      },
    },
    {
      title: 'Phone',
      // dataIndex: 'mobile',
      key: 'mobile',
      width: 240,
      render: record =>
        record?.mobile ? '+' + record.mobile.replace(/\+/g, '') : '-',
      // return mobile;
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 420,
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      render: function adminStatus(_, record) {
        const colorVal = {
          active: '#6ABB98',
          inactive: '#F1B13B',
          suspended: '#DF5E67',
        };
        return (
          <span
            style={{
              color: colorVal[record.status],
              textTransform: 'capitalize',
            }}
          >
            {record.status}
          </span>
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      width: 160,
      align: 'center',
      render: function adminActions(text, record) {
        return (
          <div
            className="ant-table-action-cell"
            style={{ justifyContent: 'center' }}
          >
            <EyeFilledIcon
              onClick={e => e.stopPropagation() || selectRow(record)}
            />
            <EditFilledIcon
              onClick={e => e.stopPropagation() || showEdit(record)}
            />
            <DeleteFilledIcon
              onClick={e => e.stopPropagation() || showDelete(record)}
            />
          </div>
        );
      },
    },
  ];
}

function AdminPage() {
  const dispatch = useDispatch();
  const { admins, meta, loading, submitting, error, media_library_images } =
    useSelector(store => localStore(store));
  const [modalState, setModalState] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState(null);
  const [dataSource, updateDataSource] = useState([]);
  const [queryString, updateQueryString] = useState('');
  const windowSize = useWindowSize();

  const onTableSearch = () =>
    getAdminsHandler({ query: queryString, per: meta['per_page'] });

  const getAdminsHandler = pageQuery => dispatch(getAdmins(pageQuery));

  useEffect(() => {
    dispatch(
      getAdmins({
        page: 1,
        per: 20,
        query: null,
      })
    );
    dispatch(getMedia());
  }, [dispatch]);

  useEffect(() => {
    updateDataSource(
      admins.map(admin => ({
        ...admin,
        full_name: `${admin['first_name']} ${admin['last_name']}`,
      }))
    );
  }, [admins]);

  useEffect(() => {
    if (!submitting && !error) {
      closeModal();
    }
  }, [submitting, error]);

  const closeModal = () => {
    setModalState(0);
    setSelectedRow(null);
    setFormData(null);
  };

  const selectRow = record => {
    setModalState(1);
    setSelectedRow(record);
    setFormData(null);
  };

  const showCreateModal = () => {
    setModalState(1);
    setSelectedRow(null);
    setFormData(defaultUser);
  };

  const showEditModal = record => {
    setModalState(1);
    setSelectedRow(null);
    setFormData({ ...record, password: '' });
  };

  const showDeleteModal = record => {
    setModalState(2);
    setSelectedRow(record);
    setFormData(null);
  };

  const onDeleteAdmin = () => {
    dispatch(deleteAdmin(selectedRow.id));
    closeModal();
  };

  const onCreateAdmin = admin => {
    dispatch(createAdmin(admin));
  };

  const onUpdateAdmin = admin => {
    dispatch(updateAdmin(admin));
    closeModal();
  };

  const handleTableChange = pagination => {
    getAdminsHandler({
      page: pagination['current'],
      per: pagination['pageSize'],
      query: queryString ? queryString : null,
    });
  };

  const dataColumns = tableColumns(showEditModal, showDeleteModal, selectRow);

  return (
    <div className="admins-page">
      <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
        <DeleteModal
          onClose={closeModal}
          onDelete={onDeleteAdmin}
          visible={modalState === 2}
          selected={selectedRow}
        />
      </Suspense>
      <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
        <CreateDrawer
          onUpdate={onUpdateAdmin}
          onCreate={onCreateAdmin}
          onClose={closeModal}
          onChange={setFormData}
          showDeleteModal={showDeleteModal}
          showEditModal={showEditModal}
          visible={modalState === 1}
          selected={selectedRow}
          formData={formData}
          libraryImage={media_library_images?.data}
        />
      </Suspense>
      <div className="page-header">
        <div className="page-title">
          <h4>Admin</h4>
        </div>
        <div className="page-header-actions">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={showCreateModal}
          >
            Add New Admin
          </Button>
        </div>
      </div>
      <div className="page-content mob">
        <TableSearch
          placeholder="Search Admin"
          btnText="Search"
          onBtnClick={onTableSearch}
          value={queryString}
          onInputChange={ev => updateQueryString(ev['target']['value'])}
        />
        {windowSize ? (
          <Card>
            <Suspense
              fallback={<LoadingOutlined className="fallback-loader" />}
            >
              <MobileTable
                records={dataSource}
                selectRow={selectRow}
                showEdit={showEditModal}
                showDelete={showDeleteModal}
              />
            </Suspense>
          </Card>
        ) : (
          <Card>
            <Suspense
              fallback={<LoadingOutlined className="fallback-loader" />}
            >
              <DataTable
                rowKey={record => record['id'] || 'id'}
                loading={loading}
                data={dataSource}
                columns={dataColumns}
                // onClickRow={selectRow}
                pagination={{
                  limit: meta ? meta['per_page'] : 20,
                  total: meta ? meta['total_count'] : 0,
                  page: meta ? meta['page'] : 1,
                  data: dataSource,
                  // onChange: onPageChange,
                }}
                handleTableChange={handleTableChange}
              />
            </Suspense>
          </Card>
        )}
      </div>
    </div>
  );
}

export default React.memo(AdminPage);
