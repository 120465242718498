import React, { lazy, Suspense, useEffect, useState } from 'react';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
const DataTable = lazy(() => import('../../components/DataTable'));
import TableSearch from '../../components/TableSearch';
import {
  getSubscription,
  createSubscription,
  updateSubscription,
  archiveSubscription,
  unArchiveSubscription,
  deleteSubscription,
  getSubscriptionById,
  updateUI,
  setCreatedFalse,
  subscriptionbulkDelete,
  subscriptionbulkArchive,
  subscriptionbulkUnarchive,
} from './reducers';
import 'react-phone-input-2/lib/style.css';
import TableColumns from './components/subscription-table';
const DeleteService = lazy(() => import('./components/delete-service'));
const ArchiveSubscription = lazy(() =>
  import('./components/archive-subscription')
);
const UnarchiveSubscription = lazy(() =>
  import('./components/unarchive-subscription')
);

const handlerEdit = (selectedRow, history, getSubscriptionById) => {
  getSubscriptionById(null);
  history.push({ pathname: `/admin/list/subscription/form/${selectedRow.id}` });
};

const handlerDelete = (uiState, selectedRow, deleteSubscription) => {
  deleteSubscription({
    ...uiState,
    selectedRow: selectedRow,
    modalOpened: 2,
  });
};

const handlerCreate = (history, getSubscriptionById) => {
  getSubscriptionById(null);
  history.push({ pathname: `/admin/list/subscription/form/create` });
};

export function SubscriptionPage({
  loading,
  subscription,
  getSubscription,
  getSubscriptionById,
  // updateSubscription,
  archiveSubscription,
  unArchiveSubscription,
  deleteSubscription,
  uiState,
  updateUI,
  setCreatedFalse,
  subscriptionbulkDelete,
  subscriptionbulkArchive,
  subscriptionbulkUnarchive,
}) {
  const history = useHistory();
  const [queryString, updateQueryString] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { limit, total, page, data } = subscription;
  const [filterTab, setFilterTab] = useState('active');

  const onSearch = () => {
    getSubscription({ query: queryString, per: limit });
  };

  const handleTableChange = pagination => {
    getSubscription({
      page: pagination['current'],
      per: pagination['pageSize'],
      query: queryString ? queryString : '',
      filterTab,
    });
  };

  const handlerArchive = selectedRow => {
    archiveSubscription(selectedRow);
  };
  const handlerUnarchive = selectedRow => {
    unArchiveSubscription(selectedRow);
  };

  useEffect(() => {
    setCreatedFalse();
  }, [setCreatedFalse]);
  useEffect(() => {
    getSubscription({
      page: 1,
      per: 20,
      query: null,
      filterTab,
    });
  }, [getSubscription]);

  const onSelectRecord = selectedRows => {
    setSelectedRows(selectedRows);
  };
  const handleRowSelect = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
    onSelectRecord(selectedRowKeys);
  };
  const bulkDeleteHandler = () => {
    subscriptionbulkDelete(selectedRows);
    setSelectedRows([]);
  };

  const bulkArchiveHandler = (
    uiState,
    selectedRow,
    subscriptionbulkArchive
  ) => {
    subscriptionbulkArchive({
      ...uiState,
      selectedRow: selectedRow,
      modalOpened: 3,
    });
  };
  const bulkUnarchiveHandler = (
    uiState,
    selectedRows,
    subscriptionbulkUnarchive
  ) => {
    subscriptionbulkUnarchive({
      ...uiState,
      selectedRow: selectedRows,
      modalOpened: 4,
    });
  };

  const setActiveTab = value => {
    setFilterTab(value);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    getSubscription({
      page: page ? page : 1,
      per: limit ? limit : 20,
      filterTab: value,
    });
  };

  return (
    <div className="products-page">
      <div className="page-header">
        <div className="page-title">
          <h4>Subscriptions</h4>
        </div>
        <div className="page-header-actions">
          {/* TODO: need to create a create form*/}
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handlerCreate(history, getSubscriptionById)}
          >
            Add New Subscription
          </Button>
        </div>
      </div>
      <div className="page-content">
        <TableSearch
          placeholder="Search Service"
          btnText="Search"
          onBtnClick={onSearch}
          value={queryString}
          onInputChange={ev => updateQueryString(ev['target']['value'])}
        />
        <div className="page-header">
          <Row>
            <Col>
              <div className="ant-tabs-nav ant-tabs-nav-animated">
                <div
                  className={`ant-tabs-tab${
                    filterTab === 'active' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setActiveTab('active')}
                >
                  Active
                </div>
                <div
                  className={`ant-tabs-tab${
                    filterTab === 'archive' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setActiveTab('archive')}
                >
                  Archived
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Card>
          <div style={{ marginBottom: 16 }}>
            <Button
              className={`${
                selectedRows.length > 0 ? 'admin-products-page-btn' : ''
              }`}
              disabled={selectedRows.length === 0 ? true : false}
              onClick={() => bulkDeleteHandler(selectedRows)}
              style={{ marginRight: '5px' }}
            >
              Delete
            </Button>
            {filterTab === 'active' ? (
              <Button
                type="primary"
                disabled={selectedRows.length === 0 ? true : false}
                onClick={() =>
                  bulkArchiveHandler(uiState, selectedRows, updateUI)
                }
              >
                Archive
              </Button>
            ) : (
              <Button
                type="primary"
                disabled={selectedRows.length === 0 ? true : false}
                onClick={() =>
                  bulkUnarchiveHandler(uiState, selectedRows, updateUI)
                }
              >
                Unachive
              </Button>
            )}
            <span style={{ marginLeft: 8 }}>
              {selectedRows.length !== 0
                ? `Selected ${selectedRows.length} items`
                : ''}
            </span>
          </div>
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <DataTable
              rowSelectionFlag={true}
              onSelectRecord={onSelectRecord}
              rowKey={record => record['id']}
              loading={loading}
              services
              data={subscription.products}
              columns={TableColumns(
                record => handlerEdit(record, history, getSubscriptionById),
                record => handlerDelete(uiState, record, updateUI),
                record => handlerArchive(record),
                record => handlerUnarchive(record)
              )}
              // onClickRow={this.onClickRow}
              pagination={{
                limit,
                total,
                page,
                data,
                // onChange: onPageChange,
              }}
              rowSelection={{ selectedRowKeys, onChange: handleRowSelect }}
              handleTableChange={handleTableChange}
            />
          </Suspense>
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <DeleteService
              uiState={uiState}
              updateUI={updateUI}
              deleteSubscription={deleteSubscription}
            />
          </Suspense>
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <ArchiveSubscription
              uiState={uiState}
              updateUI={updateUI}
              subscriptionbulkArchive={subscriptionbulkArchive}
              setSelectedRows={setSelectedRows}
            />
          </Suspense>
          <Suspense fallback={<LoadingOutlined className="fallback-loader" />}>
            <UnarchiveSubscription
              uiState={uiState}
              updateUI={updateUI}
              subscriptionbulkUnarchive={subscriptionbulkUnarchive}
              setSelectedRows={setSelectedRows}
            />
          </Suspense>
        </Card>
      </div>
    </div>
  );
}

SubscriptionPage.propTypes = {
  loading: PropTypes.bool,
  subscription: PropTypes.object,
  uiState: PropTypes.object,
  getSubscription: PropTypes.func,
  createSubscription: PropTypes.func,
  updateSubscription: PropTypes.func,
  archiveSubscription: PropTypes.func,
  unArchiveSubscription: PropTypes.func,
  deleteSubscription: PropTypes.func,
  subscriptionbulkArchive: PropTypes.func,
  subscriptionbulkUnarchive: PropTypes.func,
  getSubscriptionById: PropTypes.func,
  updateUI: PropTypes.func,
  setCreatedFalse: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.subscription.loading,
    subscription: state.subscription.subscription,
    uiState: state.subscription.uiState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSubscription: keyword => dispatch(getSubscription(keyword)),
    createSubscription: data => dispatch(createSubscription(data)),
    updateSubscription: data => dispatch(updateSubscription(data)),
    archiveSubscription: data => dispatch(archiveSubscription(data)),
    unArchiveSubscription: data => dispatch(unArchiveSubscription(data)),
    deleteSubscription: id => dispatch(deleteSubscription(id)),
    getSubscriptionById: id => dispatch(getSubscriptionById(id)),
    updateUI: payload => dispatch(updateUI(payload)),
    setCreatedFalse: data => dispatch(setCreatedFalse(data)),
    subscriptionbulkDelete: data => dispatch(subscriptionbulkDelete(data)),
    subscriptionbulkArchive: data => dispatch(subscriptionbulkArchive(data)),
    subscriptionbulkUnarchive: data =>
      dispatch(subscriptionbulkUnarchive(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
